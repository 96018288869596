import React, { useEffect, useMemo } from 'react';

// helpers
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import { Text, Tabs, TabModel } from '@ui';
import ApprovalsTab from 'modules/AccountManagement/AccountManagementRules/ApprovalsTab';
import ApprovalRulesTab from 'modules/AccountManagement/AccountManagementRules/ApprovalRulesTab';
import ApprovalGroupsTab from 'modules/AccountManagement/AccountManagementRules/ApprovalGroupsTab';
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';

const AccountManagementRules = () => {
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const { t } = useTranslation('account_management');

  const [
    hasApprovalGroupsViewAccess,
    hasApprovalRulesViewAccess,
    hasApprovalWorkflowViewAccess,
  ] = useUserAccess([
    'AccountManagement_Rules_ApprovalGroups_View',
    'AccountManagement_Rules_ApprovalRules_View',
    'AccountManagement_Rules_Workflow_View',
  ]);

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      content: <Text>{t('account_management_rules.description')}</Text>,
    }));

    return () => clearContext();
  }, [setPageLayoutOptions, clearContext]);

  const tabs = useMemo<TabModel[]>(
    () => [
      {
        key: 'approval-groups',
        label: t('approval_groups.title'),
        children: <ApprovalGroupsTab />,
        hidden: !hasApprovalGroupsViewAccess,
      },
      {
        key: 'approval-rules',
        label: t('approval_rules.title'),
        children: <ApprovalRulesTab />,
        hidden: !hasApprovalRulesViewAccess,
      },
      {
        key: 'pending-approvals',
        label: (
          <>
            {t('pending_approvals.title')}
            <RenderBadgeByRoutePath
              path={RoutePaths.AccountManagement_AccountManagementRules}
            />
          </>
        ),
        children: <ApprovalsTab />,
        hidden: !hasApprovalWorkflowViewAccess,
      },
    ],
    [
      hasApprovalGroupsViewAccess,
      hasApprovalRulesViewAccess,
      hasApprovalWorkflowViewAccess,
    ],
  );

  return <Tabs items={tabs} renderOnChange />;
};

export default AccountManagementRules;
