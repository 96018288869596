import React from 'react';

// helpers
import { styled } from 'styled-components';

// components
import SignUpFormContent from './SignUpFormContent';
import { SignUpContextProvider } from './SignUpContext';

const SignUpFormNew = () => {
  return (
    <SignUpContextProvider>
      <Root>
        <SignUpFormContent />
      </Root>
    </SignUpContextProvider>
  );
};

const Root = styled.div`
  max-width: 65%;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.hdScreen}) {
    width: 80%;
    max-width: 80%;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    width: 100%;
    max-width: 100%;
  }
`;

export default SignUpFormNew;
