import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService from 'services/error-handler/service';
import { colorsTheme } from 'resources/theme/styled/colors';
import { intakeFormAPI } from 'api/crm/intakeFormAPI';
import { useEntityIntakeFormContext } from '../../EntityIntakeFormContext';
import { entityTypeStepValidationSchema } from 'validations/intake-form/intake-form-steps';

// components
import InnerForm from './InnerForm';
import { AutocompleteOption, Form, Modal, SectionIntro } from '@ui';

export interface FormValuesModel {
  countryOfIncorporation: string | null;
  entityTypeCategory: string | null;
  availableEntityTypeCategories: { id: string; label: string }[];
  countryOfIncorporationInitialOption?: AutocompleteOption;
  initialEntityTypeCategory?: { id: string; label: string };
}

const EntityType = () => {
  const { t } = useTranslation(['onboarding', 'common']);
  const { intakeForm, setIntakeForm } = useEntityIntakeFormContext();

  const initialValues = useMemo<FormValuesModel>(() => {
    if (!intakeForm) {
      return {
        entityTypeCategory: '',
        countryOfIncorporation: '',
        availableEntityTypeCategories: [],
      };
    } else {
      let initialEntityTypeCategory = undefined;
      let countryOfIncorporationInitialOption: AutocompleteOption | undefined =
        undefined;

      if (intakeForm.metadata.keyRelationshipTemplateInstance) {
        initialEntityTypeCategory = {
          id: intakeForm.metadata.keyRelationshipTemplateInstance.id,
          label: intakeForm.metadata.keyRelationshipTemplateInstance.name,
        };
      }

      if (
        intakeForm.fields?.entityCountry &&
        intakeForm.metadata?.countries[intakeForm?.fields?.entityCountry]
      ) {
        countryOfIncorporationInitialOption = {
          id: intakeForm.fields.entityCountry,
          label:
            intakeForm.metadata.countries[intakeForm?.fields?.entityCountry],
        };
      }

      return {
        entityTypeCategory: intakeForm?.fields?.entityTypeCategoryId || '',
        countryOfIncorporation: intakeForm?.fields?.entityCountry || '',
        countryOfIncorporationInitialOption,
        initialEntityTypeCategory,
        availableEntityTypeCategories: [],
      };
    }
  }, [intakeForm]);

  const handleSubmit = async (values: FormValuesModel) => {
    async function submitChangesToIntakeForm(
      id: string,
      values: FormValuesModel,
    ) {
      const response = await intakeFormAPI.setIntakeFormFields(id, {
        entityCountry: values.countryOfIncorporation || '',
        entityTypeCategoryId: values.entityTypeCategory || '',
      });
      setIntakeForm(response);
    }

    if (intakeForm) {
      if (intakeForm.completionStatuses.entityType) {
        Modal.confirm({
          width: 600,
          icon: null,
          closable: true,
          maskClosable: true,
          title: t('intake_form.entity_type.edit_confirmation_modal.title'),
          content: t(
            'intake_form.entity_type.edit_confirmation_modal.description',
          ),
          okText: t('confirm', { ns: 'common' }),
          cancelText: t('cancel', { ns: 'common' }),
          onOk: async () => {
            try {
              await submitChangesToIntakeForm(intakeForm._id, values);
            } catch (error: any) {
              ErrorHandlerService.handleError(error);
            }
          },
        });
      } else {
        await submitChangesToIntakeForm(intakeForm._id, values);
      }
    }
  };

  return (
    <>
      <SectionIntro
        hideDivider
        titleColor={colorsTheme.colorWhite}
        title={t('intake_form.entity_type.title')}
      />
      <Form
        onSubmit={handleSubmit}
        renderForm={<InnerForm />}
        initialValues={initialValues}
        validationSchema={entityTypeStepValidationSchema}
      />
    </>
  );
};

export default EntityType;
