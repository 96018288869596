import React from 'react';

// helpers
import { styled } from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '.';
import { useFormikContext } from 'formik';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Col, IconSVG, Row, Text } from '@ui';
import { ReactComponent as BlankCheckIcon } from 'resources/icons/remix-icons/checkbox-blank-line.svg';
import { ReactComponent as FilledCheckIcon } from 'resources/icons/remix-icons/checkbox-line.svg';

export interface IProps {
  loading?: boolean;
}

const Option = ({
  text,
  isActive,
  onSelect,
}: {
  text: string;
  isActive: boolean;
  onSelect: () => void;
}) => {
  return (
    <StyledOptionRoot $isActive={isActive} onClick={onSelect}>
      <Row wrap={false} align="middle">
        <Col flex="50px">
          <CenterAlignWrapper>
            <IconSVG
              size="middle"
              color={colorsTheme.colorWhite}
              component={isActive ? FilledCheckIcon : BlankCheckIcon}
            />
          </CenterAlignWrapper>
        </Col>
        <Col flex="auto">
          <Text
            variant="h4"
            color={isActive ? colorsTheme.colorWhite : undefined}
          >
            <EllipsisTooltip title={text}>{text}</EllipsisTooltip>
          </Text>
        </Col>
      </Row>
    </StyledOptionRoot>
  );
};

const AvailableEntityTypes = ({ loading }: IProps) => {
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const handleEntityTypeCategorySelect = (newValue: string) => {
    setFieldValue('entityTypeCategory', newValue);
  };

  return (
    <LoadingWrapper loading={!!loading}>
      <Row gutter={[16, 16]}>
        {values.availableEntityTypeCategories.map((e) => (
          <Col span={24} key={e.id}>
            <Option
              text={e.label}
              onSelect={() => handleEntityTypeCategorySelect(e.id)}
              isActive={values.entityTypeCategory === e.id}
            />
          </Col>
        ))}
      </Row>
    </LoadingWrapper>
  );
};

const StyledOptionRoot = styled.div<{ $isActive: boolean }>`
  padding: ${({ theme }) => theme.paddingXl};
  cursor: pointer;
  border: ${({ theme }) => `2px solid ${theme.colorDarkL3}`};
  transition: ${({ theme }) => theme.defaultTransition};
  &:hover {
    border-color: ${({ theme }) => theme.colorWhite};
  }
  ${({ $isActive, theme }) =>
    $isActive ? `border-color: ${theme.colorWhite};` : ''}
`;

const CenterAlignWrapper = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
`;

export default AvailableEntityTypes;
