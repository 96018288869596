import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { GraphNode } from '../../../../../../../Charts/GraphChart';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ContactModel } from 'typings/application/contact';
import { IApplication } from 'typings/application/applications';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';

// components
import { IconSVG, TabModel, Tabs } from '@ui';
import ManageKYC from 'components/Additional/CRM/ManageKYC';
import LoadingWrapper from '../../../../../../../WrapperComponents/LoadingWrapper';
import Identification from './Tabs/Identification';
import SourceOfWealth from './Tabs/SourceOfWealth';
import SharedIconWithText from 'components/Additional/SharedIconWithText';
import ApplicantInformation from './Tabs/ApplicantInformation';
import ApplicationDocumentation from './Tabs/ApplicationDocumentation';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

interface IProps {
  graphNode: GraphNode;
  application: IApplication;
  allInformationFilled: boolean;
  updateNodeStatuses: () => void;
  onRemoveKYC: () => void;

  isViewOnly?: boolean;
  disablePep?: boolean;
  canSendMessage?: boolean;
  onAction?: (actionType: ActionKeys | null) => void;
}

type TabKeys =
  | 'applicantInformation'
  | 'identification'
  | 'sourceOfWealth'
  | 'applicationDocumentation';

const Individual = ({
  application,
  isViewOnly,
  canSendMessage,
  graphNode,
  allInformationFilled,
  onAction,
  updateNodeStatuses,
  onRemoveKYC,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>(
    'applicantInformation',
  );
  const [stepStatuses, setStepStatuses] = useState<Partial<
    Record<TabKeys, boolean>
  > | null>(null);

  const { response: onboardingStatusResponse, loading: onboardingLoading } =
    useFetch(
      () =>
        onboardingAPI.fetchStatusForOnboardingItem(
          graphNode.id,
          application._id,
        ),
      [graphNode, application],
    );

  const shouldFillSourceOfWealth = useMemo(() => {
    const contact = graphNode.model as ContactModel;
    return graphNode.hasOwnershipRelationships || contact.isPEP;
  }, [graphNode]);

  useEffect(() => {
    if (onboardingStatusResponse) {
      const { informationFilled } = onboardingStatusResponse;

      const statuses = shouldFillSourceOfWealth
        ? {
            applicantInformation: informationFilled.generalInformation,
            identification: informationFilled.identification,
            sourceOfWealth: informationFilled.employmentHistory,
            applicationDocumentation:
              informationFilled.applicationDocumentation,
          }
        : {
            applicantInformation: informationFilled.generalInformation,
            identification: informationFilled.identification,
            applicationDocumentation:
              informationFilled.applicationDocumentation,
          };

      setStepStatuses(statuses);
      setCurrentStep('applicantInformation');
    }
  }, [onboardingStatusResponse, shouldFillSourceOfWealth]);

  const handleStepSave = (savedStep: TabKeys, isCompleted: boolean) => {
    if (stepStatuses) {
      const stepStatusesCopy = { ...stepStatuses, [savedStep]: isCompleted };
      const nextNotCompletedStep = Object.keys(stepStatusesCopy).find(
        (key) => !stepStatusesCopy[key as TabKeys],
      );

      if (nextNotCompletedStep) {
        setStepStatuses(stepStatusesCopy);
        allInformationFilled && updateNodeStatuses();
        if (isCompleted) {
          setCurrentStep(nextNotCompletedStep as TabKeys);
        }
      } else {
        setStepStatuses(stepStatusesCopy);
        !allInformationFilled && updateNodeStatuses();
      }
    }
  };

  const handleOnAction = (
    currentStep: TabKeys,
    isCompleted: boolean,
    actionType: ActionKeys | null,
  ) => {
    switch (actionType) {
      case 'save': {
        handleStepSave(currentStep, isCompleted);
        break;
      }

      case 'save-back':
      case 'save-exit': {
        onAction && onAction(actionType);
        break;
      }
    }
  };

  const renderIcon = (key: TabKeys) => {
    if (!stepStatuses) {
      return;
    }

    return stepStatuses[key] ? (
      <StyledIconSVG component={CheckIcon} color={colorsTheme.colorPrimary} />
    ) : (
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
    );
  };

  const tabs = useMemo<TabModel[]>(() => {
    if (!onboardingStatusResponse) {
      return [];
    }

    return [
      {
        key: 'applicantInformation',
        label: (
          <>
            {t('applicant_information.section_title')}{' '}
            {renderIcon('applicantInformation')}
          </>
        ),
        children: (
          <ApplicantInformation
            onboardingStatus={onboardingStatusResponse}
            applicationId={application._id}
            isViewOnly={isViewOnly || graphNode.sharedData}
            onAction={(isCompleted: boolean, actionType: ActionKeys | null) =>
              handleOnAction('applicantInformation', isCompleted, actionType)
            }
          />
        ),
      },

      {
        key: 'identification',
        label: (
          <>
            {t('identification.title')} {renderIcon('identification')}
          </>
        ),
        children: (
          <Identification
            onboardingStatus={onboardingStatusResponse}
            applicationId={application._id}
            isViewOnly={isViewOnly || graphNode.sharedData}
            onAction={(isCompleted: boolean, actionType: ActionKeys | null) =>
              handleOnAction('identification', isCompleted, actionType)
            }
          />
        ),
      },

      {
        key: 'sourceOfWealth',
        hidden: !shouldFillSourceOfWealth,
        label: (
          <>
            {t('source_of_wealth.title')} {renderIcon('sourceOfWealth')}
          </>
        ),
        children: (
          <SourceOfWealth
            onboardingStatus={onboardingStatusResponse}
            applicationId={application._id}
            isViewOnly={
              isViewOnly ||
              (graphNode.sharedData &&
                !onboardingStatusResponse.isSharedSOWEditable)
            }
            onAction={(isCompleted: boolean, actionType: ActionKeys | null) =>
              handleOnAction('sourceOfWealth', isCompleted, actionType)
            }
          />
        ),
      },

      {
        key: 'applicationDocumentation',
        label: (
          <>
            {t('application_documentation.title')}{' '}
            {renderIcon('applicationDocumentation')}
          </>
        ),
        children: (
          <ApplicationDocumentation
            canSendMessage={canSendMessage}
            onboardingStatus={onboardingStatusResponse}
            isViewOnly={isViewOnly}
            onAction={(isCompleted: boolean, actionType: ActionKeys | null) =>
              handleOnAction(
                'applicationDocumentation',
                isCompleted,
                actionType,
              )
            }
          />
        ),
      },
    ];
  }, [
    onboardingStatusResponse,
    stepStatuses,
    canSendMessage,
    isViewOnly,
    graphNode.sharedData,
    shouldFillSourceOfWealth,
  ]);

  return (
    <LoadingWrapper loading={onboardingLoading}>
      {graphNode.sharedData && (
        <SharedIconWithText text={t('application_documentation.shared_data')} />
      )}

      <ManageKYC
        onUpdate={onRemoveKYC}
        data={{
          nodeId: graphNode.id,
          onboardingProcessId: graphNode.onboardingProcess
            ? graphNode.onboardingProcess._id
            : null,
          isOnboardingProcessRemovable:
            graphNode.metadata.isOnboardingProcessRemovable,
          isOnboardingProcessCreatable:
            graphNode.metadata.isOnboardingProcessCreatable,
        }}
      />
      <Tabs
        useChangeView
        items={tabs}
        activeKey={currentStep}
        defaultActiveKey={currentStep}
        onChange={setCurrentStep}
      />
    </LoadingWrapper>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default Individual;
