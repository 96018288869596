import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { userAPI } from 'api/profile/userAPI';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { Message, IconSVG } from '@ui';
import { ReactComponent as EditIcon } from 'resources/icons/remix-icons/edit-line.svg';
import {
  default as TemplateChangeEmailModal,
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Auth/EditEmailModal';

interface IProps {
  startVerificationProcessForNewEmail: (newEmail: string) => Promise<void>;
}

const ChangeEmail = ({ startVerificationProcessForNewEmail }: IProps) => {
  const { t } = useTranslation('auth');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return { email: '' };
  }, []);

  const onSubmit = async (values: FormValuesModel) => {
    await userAPI.changeUnverifiedEmail({ email: values.email });
    await startVerificationProcessForNewEmail(values.email);
    Message.success(
      t('open_an_account_new.verify_email.change_email.success', {
        email: values.email,
      }),
    );
    setIsVisible(false);
  };

  return (
    <>
      <StyledIconSVG
        component={EditIcon}
        color={colorsTheme.colorWhite}
        onClick={() => setIsVisible(true)}
      />
      <TemplateChangeEmailModal
        isVisible={isVisible}
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        closeCallback={() => setIsVisible(false)}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default ChangeEmail;
