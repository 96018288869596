import React, { useMemo } from 'react';

// helpers
import { useFormikContext } from 'formik';
import { RequiredPropsForModalDialogModel } from '@ui';
import { FormValuesModel as WireTransfersGridFormValuesModel } from 'components/Forms/TemplateForms/Transfers/WireTransferGridForm';

// components
import SubmitTransactionsBulkModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Transfers/SubmitTransactionsBulkModal';

interface IProps extends RequiredPropsForModalDialogModel {
  closeCallback: (wasSubmitted?: boolean, values?: FormValuesModel) => void;
}

const SubmitTransactionsModal = ({ isVisible, closeCallback }: IProps) => {
  const { values } = useFormikContext<WireTransfersGridFormValuesModel>();

  const initialValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    return {
      step: 'confirm-action',
      completed: 0,
      result: null,
      transactions: values.transactions.slice(),
    };
  }, [isVisible, values]);

  return (
    <SubmitTransactionsBulkModal
      onSubmit={console.log}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialValues}
    />
  );
};

export default SubmitTransactionsModal;
