import APIConfig from '../../config/api';
import { ACCOUNT_RECOVERY_STATUS } from '../../enums/profile/accountRecovery';
import { NewAccountRecoveryRequestModel } from '../../apiAdapters/accountRecoveryRequest/accountRecoveryRequestAdapter';
import { APIService, AUTHORIZATION_HEADER } from '../axiosInstance';
import { TwoFactorVerificationOptionModel } from '../../redux/reducers/auth';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  ActivationEmailEnum,
  TwoFactorVerificationTypes,
} from '../../enums/profile/auth';
import {
  AssertionForLoginModel,
  GetChallengeForLoginResponseModel,
  ChallengeForRegistrationModel,
  SecurityKeyModel,
} from '../../typings/profile/fido';

export interface ILoginBody {
  username: string;
  password: string;
}

export interface IActivateUserBody extends ILoginBody {
  tempToken: string;
}

export interface ISessionInfo {
  geolocation?: ISessionInfoGeolocation;
}

export interface ISessionInfoGeolocation {
  accuracy: number;
  altitude?: number;
  altitudeAccuracy?: number;
  latitude: number;
  longitude: number;
}

export interface AccountRecoveryResponseModel {
  status: ACCOUNT_RECOVERY_STATUS;
}

interface IRequestResetUsername {
  username: string;
}

interface IVerifyOtp {
  username: string;
  otp: string;
}

interface IVerifySmsOtp {
  otp: string;
}

interface IChangePassword {
  username: string;
  token: string;
  selfieSrc: string;
  password: string;
}

interface NewSecurityKeyModel {
  id: string;
  rawId: string;
  type: string;
  name: string;
  response: {
    clientDataJSON: string;
    attestationObject: string;
  };
}

interface UpdateKeyDataModel {
  name: string;
}

interface LoginResponseModel {
  jwt: string;
  code?: '2FA_required';
  options?: TwoFactorVerificationOptionModel[];
  refreshToken?: string;
  serverTime?: number;
}

export interface SigninSubmitUsernameResponseModel {
  status: ActivationEmailEnum;
  swaggerVersion: string;
}

export interface VerifyResetPasswordOtpResponseModel {
  jwt: string;
}

export interface LoginBody {
  username: string;
  password: string;
}

export interface ActivateUserBody extends LoginBody {
  otpCode: string;
}

export interface LoginUsernameBody {
  username: string;
}

export enum AccountRecoveryStatus {
  WrongData = 'wrong_data',
  Veryfing = 'verifying',
  Ok = 'ok',
}

export type SubmitForgotUsernameRequestBody = {
  otp: string;
  name: string;
  email: string;
  dateOfBirth: string;
  clientGroupName: string;
};

export type ChangeUnverifiedEmailRequestBody = {
  email: string;
};

interface FetchAccountRecoveryStatus {
  status: AccountRecoveryStatus;
}

const userAPI = {
  checkUsername: (body: LoginUsernameBody) => {
    return APIService.post<SigninSubmitUsernameResponseModel>(
      `${APIConfig.authPublicApi}/sign-in-username`,
      body,
      {
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then((response) => response?.data);
  },

  login: (body: ILoginBody) => {
    return APIService.post<LoginResponseModel>(
      `${APIConfig.authPublicApi}/sign-in`,
      body,
      {
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then((response) => response.data);
  },

  activateUser: (body: IActivateUserBody) => {
    return APIService.post(
      `${APIConfig.authPublicApi}/sign-in-password`,
      body,
      {
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then((response) => response.data);
  },

  signOut: () => {
    return APIService.post(`${APIConfig.authApi}/sign-out`, undefined, {
      skipRefresh: true,
    }).then((response) => response.data);
  },

  loginStepTwo: (tokenJWT: string, otp: string) => {
    return APIService.post(
      `${APIConfig.authPublicApi}/sign-in/2fa`,
      { otp },
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${tokenJWT}` },
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then((response) => response.data);
  },

  fetchAccountRecoveryStatus: (tokenJWT: string) => {
    return APIService.get<FetchAccountRecoveryStatus>(
      `${APIConfig.authPublicApi}/recovery-requests/status`,
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${tokenJWT}` },
        addFingerprintToHeaders: true,
      },
    ).then((response) => response.data);
  },

  requestAccountRecovery: (
    accountRecoveryData: NewAccountRecoveryRequestModel,
    tokenJWT: string,
  ) => {
    return APIService.post(
      `${APIConfig.authPublicApi}/recovery-requests`,
      { ...accountRecoveryData },
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${tokenJWT}` },
        addFingerprintToHeaders: true,
      },
    ).then((response) => response.data);
  },

  refreshTokens: (jwt: string, refresh: string) => {
    return APIService.patch<{
      jwt: string;
      refreshToken: string;
      swaggerVersion: string;
      serverTime: number;
    }>(`${APIConfig.authPublicApi}/refresh`, undefined, {
      headers: { [AUTHORIZATION_HEADER]: `Bearer ${jwt}`, refresh },
      addFingerprintToHeaders: true,
      skipTimestampTracking: true,
    }).then((response) => response.data);
  },

  setUserTags: (tags: string[]) => {
    return APIService.post(`${APIConfig.authApi}/users/tags`, { tags }).then(
      (response) => response.data,
    );
  },

  removeUserTags: (tags: string[]) => {
    return APIService.delete(`${APIConfig.authApi}/users/tags`, {
      data: { tags },
    }).then((response) => response.data);
  },

  requestResetUsername: (body: IRequestResetUsername) => {
    return APIService.post(`${APIConfig.authPublicApi}/password/reset`, body, {
      addFingerprintToHeaders: true,
      addGeolocationDataToHeaders: true,
    }).then(({ data }) => data);
  },

  verifyOtp: (body: IVerifyOtp) => {
    return APIService.post<VerifyResetPasswordOtpResponseModel>(
      `${APIConfig.authPublicApi}/otp/verify/token`,
      body,
      {
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then((response) => response.data);
  },

  verifySmsOtp: (jwt: string, body: IVerifySmsOtp) => {
    return APIService.post<VerifyResetPasswordOtpResponseModel>(
      `${APIConfig.authPublicApi}/otp/verify/sms`,
      body,
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${jwt}` },
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then(({ data }) => data);
  },

  resetPassword: (jwt: string, body: IChangePassword) => {
    return APIService.patch(
      `${APIConfig.authPublicApi}/password/change`,
      body,
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${jwt}` },
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then(({ data }) => data);
  },

  getChallengeForRegistrationNewKey: () => {
    return APIService.post<ChallengeForRegistrationModel>(
      `${APIConfig.authApi}/2fa/fido/register-challenge`,
    ).then(({ data }) => data);
  },

  registerSecurityKey: (newKey: NewSecurityKeyModel) => {
    return APIService.post(
      `${APIConfig.authApi}/2fa/fido/register`,
      newKey,
    ).then(({ data }) => data);
  },

  getChallengeForLogin: (token: string) => {
    return APIService.post<GetChallengeForLoginResponseModel>(
      `${APIConfig.authPublicApi}/fido/login-challenge`,
      null,
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${token}` },
        addFingerprintToHeaders: true,
      },
    ).then((response) => response.data);
  },

  loginWithFido: (assertion: AssertionForLoginModel, tempToken: string) => {
    return APIService.post(`${APIConfig.authPublicApi}/fido/login`, assertion, {
      headers: { [AUTHORIZATION_HEADER]: `Bearer ${tempToken}` },
      addFingerprintToHeaders: true,
    }).then((response) => response.data);
  },

  fetchSecurityKeys: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<SecurityKeyModel>>(
      `${APIConfig.authApi}/users/2FA/fido/keys`,
      { params },
    ).then(({ data }) => data);
  },

  getChallengeForRemovingKey: (credId: string) => {
    return APIService.post<GetChallengeForLoginResponseModel>(
      `${APIConfig.authPublicApi}/fido/login-challenge`,
      { credId },
    ).then((response) => response.data);
  },

  removeFidoKey: (keyId: string, assertion: AssertionForLoginModel) => {
    return APIService.post(
      `${APIConfig.authApi}/users/2FA/fido/${keyId}/remove`,
      assertion,
    ).then((response) => response.data);
  },

  getChallengeForDisablingFIDO: () => {
    return APIService.post<GetChallengeForLoginResponseModel>(
      `${APIConfig.authPublicApi}/fido/login-challenge`,
    ).then((response) => response.data);
  },

  disableSecurityKey2FA: (assertion: AssertionForLoginModel) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/2FA/fido/disable`,
      assertion,
    ).then((response) => response.data);
  },

  changeDefaultAuthenticator: (defaultTFAType: TwoFactorVerificationTypes) => {
    return APIService.patch(`${APIConfig.authApi}/users/2FA-type`, {
      defaultTFAType,
    }).then((response) => response.data);
  },

  updateSecurityKeyById: (keyId: string, updatedData: UpdateKeyDataModel) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/2FA/fido/${keyId}`,
      updatedData,
    ).then((response) => response.data);
  },

  sendOTPForUsernameRequest: (email: string) => {
    return APIService.post(`${APIConfig.authPublicApi}/verifications/email`, {
      email,
    }).then(({ data }) => data);
  },

  submitForgetUsernameRequest: (data: SubmitForgotUsernameRequestBody) => {
    return APIService.post(
      `${APIConfig.authPublicApi}/forgot-username`,
      data,
    ).then(({ data }) => data);
  },

  changeUnverifiedEmail: (data: ChangeUnverifiedEmailRequestBody) => {
    return APIService.post(
      `${APIConfig.authApi}/users/onboarding/primary-email/change-unverified`,
      data,
    );
  },
};

export { userAPI };
