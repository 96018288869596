import React, { useMemo } from 'react';

//helpers
import { ContactModel } from '../../../../../../../../../typings/application/contact';

// components
import ApplicantInformationShortForm, {
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/ApplicantInformationShortForm';

interface IProps {
  contact: ContactModel;
}

const ApplicantInformation = ({ contact }: IProps) => {
  const initialFormValues = useMemo<FormValuesModel>(() => {
    let phoneNumbers = [];
    if (contact.phoneNumbers.length) {
      phoneNumbers = contact.phoneNumbers.map((e) => ({
        type: e.type,
        value: e.number,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));

      const hasPrimary = phoneNumbers.some((e) => e.primary.status);
      if (!hasPrimary) {
        phoneNumbers[0].primary.status = true;
      }
    } else {
      phoneNumbers = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    let emails = [];
    if (contact.emails.length) {
      emails = contact.emails.map((e) => {
        return {
          type: e.type,
          value: e.address,
          primary: {
            status: e.isPrimary,
            canEdit: !e.isPrimary,
          },
        };
      });

      const hasPrimary = emails.some((e) => e.primary.status);
      if (!hasPrimary) {
        emails[0].primary.status = true;
      }
    } else {
      emails = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    return {
      isSave: false,
      submitActionType: null,
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      emails,
      phoneNumbers,
      canVerifyPrimaryEmail: false,
      canVerifyPrimaryPhoneNumber: false,
    };
  }, [contact]);

  const handleSubmit = async () => {
    // TODO: decide if submit will be needed here
  };

  return (
    <ApplicantInformationShortForm
      showOnlySaveButton
      disabled
      initialFormValues={initialFormValues}
      onSubmit={handleSubmit}
    />
  );
};

export default ApplicantInformation;
