import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { UpdateEmailValidationSchema } from 'validations/profile/auth';

// components
import InnerForm from './InnerForm';
import { Form, RequiredPropsForFormModel } from '@ui';
import { ModalDialog, RequiredPropsForModalDialogModel } from '@ui';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

export interface FormValuesModel {
  email: string;
}

const EditEmailModal = ({
  isVisible,
  initialValues,
  onSubmit,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('auth');
  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={UpdateEmailValidationSchema}
      renderForm={
        <ModalDialog
          width={600}
          title={t('open_an_account_new.verify_email.change_email.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{
            text: t('open_an_account_new.verify_email.change_email.submit'),
          }}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default EditEmailModal;
