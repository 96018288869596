import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { IClientGroupUser } from '../../typings/approvalManagement/accountManagement';
import { userManagementAdapter } from '../../apiAdapters/accountManagement/userManagementAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

const userManagementAPI = {
  fetchUsers: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IClientGroupUser>>(
      `${APIConfig.crmApi}/client-groups/users`,
      {
        params,
      },
    ).then(({ data }) => userManagementAdapter.fetchUsers(data));
  },

  enableUser: (contactId: string) => {
    return APIService.patch(
      `${APIConfig.crmApi}/client-groups/users/${contactId}/enable`,
    ).then(({ data }) => data);
  },

  deactivateUser: (contactId: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/client-groups/users/${contactId}`,
    ).then(({ data }) => data);
  },
};

export { userManagementAPI };
