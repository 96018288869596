import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import { FormField, FormInput } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation('auth');

  return (
    <FormField
      label={t('open_an_account_new.verify_email.change_email.phone')}
      name="email"
      component={FormInput}
    />
  );
};

export default InnerForm;
