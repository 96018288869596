import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { personalDetailsAPI } from 'api/profile/personalDetailsAPI';
import { validatePhoneNumbers } from 'helpers/crmHelpers';
import { fetchPersonalDetails } from 'redux/actions/personalDetails';
import { useDispatch, useSelector } from 'react-redux';
import { personalDetailsAPIAdapter } from 'apiAdapters/profile/personalDetailsAPIAdapter';
import { FormikHelpers, FormikProps } from 'formik';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';
import { ApplicantInformationIndividualShortValidationSchema } from 'validations/onboarding-new/applicant-information';

// components
import { Form, Button, SectionIntro, Col, Row, Message } from '@ui';
import SubmitButton from 'components/Buttons/SubmitButton';
import ContactGeneralForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ContactGeneralForm';

interface IProps {
  isEditable: boolean;
  onDiscard: () => void;
}

const ButtonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

const ApplicantInformation = ({ onDiscard, isEditable }: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['onboarding', 'common']);
  const { contact } = useSelector<StateModel, PersonalDetailsStateModel>(
    (state) => state.personalDetails,
  );

  const primaryEmail = useSelector<
    StateModel,
    { email: string; isVerified: boolean } | null
  >((state) =>
    state.auth.profileData?.email
      ? {
          email: state.auth.profileData?.email,
          isVerified: state.auth.profileData.emailConfirmed,
        }
      : null,
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!contact) {
      return null;
    }

    let phoneNumbers = [];
    if (contact.phoneNumbers.length) {
      phoneNumbers = contact.phoneNumbers.map((e) => ({
        type: e.type,
        value: e.number,
        isVerified: !!e?.verification?.isVerified,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));
    } else {
      phoneNumbers = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    let emails = [];
    if (contact.emails.length) {
      emails = contact.emails.map((e) => {
        return {
          type: e.type,
          value: e.address,
          isVerified:
            e.address === primaryEmail?.email ? primaryEmail.isVerified : false,
          primary: {
            status: e.isPrimary,
            canEdit: !e.isPrimary,
          },
        };
      });
    } else {
      emails = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    return {
      isSave: false,
      submitActionType: null,
      firstName: contact.firstName || '',
      middleName: contact.middleName || '',
      lastName: contact.lastName || '',
      emails,
      phoneNumbers,
      isContactBaseInfoFormDisabled: false,
      canVerifyPrimaryEmail: true,
      canVerifyPrimaryPhoneNumber: true,
    };
  }, [contact, primaryEmail]);

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errors = await validatePhoneNumbers(
      values.phoneNumbers.map((e) => e.value),
    );
    if (errors) {
      values.phoneNumbers.forEach((phone, index) => {
        if (errors[phone.value]) {
          helpers.setFieldError(
            `phoneNumbers.${index}.value`,
            errors[phone.value].errorMessage,
          );
        }
      });
    } else {
      await personalDetailsAPI.updatePersonalDetails(
        personalDetailsAPIAdapter.updatePersonalDetails(values),
      );
      Message.success(t('personal_details.edit.success_message'));
      dispatch(fetchPersonalDetails());
    }
  };

  const onDiscardClick = (form: FormikProps<FormValuesModel>) => {
    if (initialFormValues) {
      form.setValues(initialFormValues);
      onDiscard();
    }
  };

  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving
      disabled={!isEditable}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      validationSchema={ApplicantInformationIndividualShortValidationSchema}
      renderForm={(form) => (
        <>
          <SectionIntro
            title={t('applicant_information.general.title')}
            description={t('applicant_information.description_contact')}
          />
          <ContactGeneralForm />
          {isEditable && (
            <Row justify="end" gutter={[16, 16]}>
              <Col {...ButtonGridSizes}>
                <StyledButton
                  danger
                  size="large"
                  type="bordered"
                  onClick={() => onDiscardClick(form)}
                >
                  {t('discard_changes', { ns: 'common' })}
                </StyledButton>
              </Col>
              <Col {...ButtonGridSizes}>
                <StyledSubmitButton>
                  {t('save_changes', { ns: 'common' })}
                </StyledSubmitButton>
              </Col>
            </Row>
          )}
        </>
      )}
    />
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`;

export default ApplicantInformation;
