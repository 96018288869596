import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService from 'services/error-handler/service';
import { StateModel } from 'redux/reducers';
import { colorsTheme } from 'resources/theme/styled/colors';
import { fetchPersonalDetails } from 'redux/actions/personalDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChangeRequestStatuses,
  personalDetailsAPI,
} from 'api/profile/personalDetailsAPI';
import {
  StateModel as PersonalDetailsStateModel,
  StepStatus,
} from 'redux/reducers/personalDetails';

// components
import {
  Text,
  Button,
  Divider,
  IconSVG,
  Col,
  Row,
  Message,
  Modal,
  Tabs,
} from '@ui';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import Verification from './Tabs/Verification';
import SourceOfWealth from './Tabs/SourceOfWealth';
import Identification from './Tabs/Identification';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import ApplicantInformation from './Tabs/ApplicantInformation';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

const ButtonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

const KYCContactForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['onboarding', 'common', 'profile']);
  const { changeRequest, contact, stepStatuses, canSubmitPersonalDetails } =
    useSelector<StateModel, PersonalDetailsStateModel>(
      (state) => state.personalDetails,
    );
  const [currentStep, setCurrentStep] =
    useState<keyof typeof stepStatuses>('general');

  const tabs = useMemo<
    {
      key: keyof typeof stepStatuses;
      label: React.ReactNode;
      children: React.ReactNode;
    }[]
  >(() => {
    if (!contact) {
      return [];
    }

    const renderIcon = (key: keyof typeof stepStatuses) => {
      if (!stepStatuses || stepStatuses[key] == null) {
        return;
      }

      return stepStatuses[key] == StepStatus.Completed ? (
        <StyledIconSVG component={CheckIcon} color={colorsTheme.colorPrimary} />
      ) : (
        <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
      );
    };

    return [
      {
        key: 'general',
        label: (
          <>
            {t('applicant_information.general.title')} {renderIcon('general')}
          </>
        ),
        children: <ApplicantInformation />,
      },
      {
        key: 'identification',
        label: (
          <>
            {t('identification.title')} {renderIcon('identification')}
          </>
        ),
        children: <Identification />,
      },
      {
        key: 'sow',
        label: (
          <>
            {t('source_of_wealth.title')} {renderIcon('sow')}
          </>
        ),
        children: <SourceOfWealth />,
      },
      {
        key: 'applicationDocumentation',
        label: (
          <>
            {t('application_documentation.title')}{' '}
            {renderIcon('applicationDocumentation')}
          </>
        ),
        children: <Verification />,
      },
    ];
  }, [contact, stepStatuses]);

  const handleDiscardChanges = async () => {
    if (changeRequest) {
      await personalDetailsAPI.stopVerificationProcessAndDiscardChanges(
        changeRequest._id,
      );
      Message.success(
        t('personal_details.edit.discard_changes', { ns: 'profile' }),
      );
      dispatch(fetchPersonalDetails());
    }
  };

  const handleSubmitForReview = () => {
    Modal.confirm({
      title: t('personal_details.kyc_form.submit_for_review_modal.title'),
      width: 600,
      icon: null,
      closable: true,
      maskClosable: true,
      content: (
        <Text variant="body1" gutterBottom>
          {t('personal_details.kyc_form.submit_for_review_modal.description')}
        </Text>
      ),
      okText: t('submit', { ns: 'submit' }),
      cancelText: t('cancel', { ns: 'common' }),
      async onOk() {
        if (changeRequest) {
          try {
            await personalDetailsAPI.submitChangeRequest(changeRequest._id);
            Message.success(
              t(
                'personal_details.kyc_form.submit_for_review_modal.success_message',
                { ns: 'profile' },
              ),
            );
            dispatch(fetchPersonalDetails());
          } catch (e: any) {
            ErrorHandlerService.handleError(e);
          }
        }
      },
    });
  };

  return (
    <>
      {changeRequest?.verification.review &&
        changeRequest?.verification.review.comment && (
          <DivAlignCenter>
            <StyledIconSVGWithRightGutter
              component={InfoIcon}
              color={colorsTheme.colorWarning}
            />
            <Text color={colorsTheme.colorWarning}>
              <EllipsisTooltip
                maxTextContainerWidth="500px"
                title={changeRequest?.verification.review?.comment}
              >
                {changeRequest.verification.review.comment}
              </EllipsisTooltip>
            </Text>
          </DivAlignCenter>
        )}

      {changeRequest &&
        changeRequest.status === ChangeRequestStatuses.InProgress &&
        !canSubmitPersonalDetails && (
          <DivAlignCenter>
            <StyledIconSVGWithRightGutter
              component={InfoIcon}
              color={colorsTheme.colorWarning}
            />
            <Text color={colorsTheme.colorWarning}>
              <EllipsisTooltip
                maxTextContainerWidth="500px"
                title={t(
                  'personal_details.kyc_form.update_personal_details_feature_is_disabled',
                )}
              >
                {t(
                  'personal_details.kyc_form.update_personal_details_feature_is_disabled',
                )}
              </EllipsisTooltip>
            </Text>
          </DivAlignCenter>
        )}

      <Tabs
        useChangeView
        items={tabs}
        activeKey={currentStep}
        defaultActiveKey={currentStep}
        onChange={setCurrentStep}
      />

      {changeRequest &&
      changeRequest.status === ChangeRequestStatuses.InProgress ? (
        <>
          <Divider />
          <Row justify="end" gutter={[16, 16]}>
            <Col {...ButtonGridSizes}>
              <StyledButton
                danger
                type="bordered"
                size="large"
                onClick={handleDiscardChanges}
              >
                {t('discard_changes', { ns: 'common' })}
              </StyledButton>
            </Col>
            <Col {...ButtonGridSizes}>
              {canSubmitPersonalDetails ? (
                <StyledButton size="large" onClick={handleSubmitForReview}>
                  {t('submit_for_review', { ns: 'common' })}
                </StyledButton>
              ) : (
                <InfoTooltip
                  placement="left"
                  tooltipContent={t(
                    'personal_details.kyc_form.update_personal_details_feature_is_disabled',
                  )}
                >
                  <div>
                    <StyledButton disabled size="large">
                      {t('submit_for_review', { ns: 'common' })}
                    </StyledButton>
                  </div>
                </InfoTooltip>
              )}
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

const StyledIconSVGWithRightGutter = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default KYCContactForm;
