import React, { useCallback, useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useField } from 'formik';
import { colorsTheme } from 'resources/theme/styled/colors';

// constants
import {
  ONE_LOWER_LETTER,
  ONE_NUMBER,
  ONE_SPECIAL_CHARACTER,
  ONE_UPPER_LETTER,
  PASSWORD_MIN_LENGTH,
} from 'constants/regularExpressions';

// components
import {
  FormInputPassword,
  Text,
  IconSVG,
  InnerComponentModel,
  Row,
  Col,
  Popover,
} from '@ui';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

type IProps = InnerComponentModel;

const passwordValidationRules = {
  oneLetter: {
    message: 'password_rules.one_letter',
    test: (value: string) => ONE_LOWER_LETTER.test(value),
  },
  oneNumber: {
    message: 'password_rules.one_number',
    test: (value: string) => ONE_NUMBER.test(value),
  },
  oneCapitalLetter: {
    message: 'password_rules.one_capital_letter',
    test: (value: string) => ONE_UPPER_LETTER.test(value),
  },
  specialCharacter: {
    message: 'password_rules.special_character',
    test: (value: string) => ONE_SPECIAL_CHARACTER.test(value),
  },
  minLength: {
    message: 'password_rules.min_length',
    test: (value: string) => String(value).trim().length >= PASSWORD_MIN_LENGTH,
  },
};

const SetupPasswordField = ({
  name,
  onChange,
  size,
  disabled,
  value,
  placeholder,
  ...rest
}: IProps) => {
  const { t } = useTranslation('auth');

  const [isInputFocused, focusInput] = useState(false);

  const initialStatuses = {
    oneLetter: { status: false },
    oneCapitalLetter: { status: false },
    oneNumber: { status: false },
    specialCharacter: { status: false },
    minLength: { status: false },
  };

  const [, meta] = useField(name);
  const [validationStatuses, setValidationStatuses] = useState(initialStatuses);

  const onBlur = () => {
    focusInput(false);
  };

  const onFocus = () => {
    focusInput(true);
  };

  // Validate password
  useEffect(() => {
    const newValidationStatuses = { ...initialStatuses };

    // Should validate only in the case:
    // 1) Value is not empty
    // 2) This field was validated using form
    if (meta.value) {
      Object.keys(passwordValidationRules).map((key) => {
        const isValid = passwordValidationRules[
          key as keyof typeof passwordValidationRules
        ].test(meta.value);
        newValidationStatuses[
          key as keyof typeof passwordValidationRules
        ].status = isValid;
      });
    }

    setValidationStatuses(newValidationStatuses as any);
  }, [meta.value, meta.error]);

  const renderPasswordRequirements = useCallback(() => {
    return (
      <>
        {Object.keys(passwordValidationRules).map((key) => {
          const { status } =
            validationStatuses[key as keyof typeof passwordValidationRules];

          return (
            <Row key={key}>
              <Col span={24}>
                <StyledText
                  variant="body1"
                  color={
                    !status
                      ? colorsTheme.colorAdditionalL1
                      : colorsTheme.colorLightL1
                  }
                >
                  {t(
                    passwordValidationRules[
                      key as keyof typeof passwordValidationRules
                    ].message,
                  )}
                  {status && (
                    <StyledIconSVG
                      component={CheckIcon}
                      color={colorsTheme.colorPrimary}
                    />
                  )}
                </StyledText>
              </Col>
            </Row>
          );
        })}
      </>
    );
  }, [validationStatuses]);

  // Popover should be shown in the case when input is not valid (from form)
  return (
    <Popover
      trigger="focus"
      open={isInputFocused}
      placement="right"
      content={
        <>
          <Text variant="body1" weight="semi-bold">
            {t('password_rules.password_rules_title')}
          </Text>
          {renderPasswordRequirements()}
        </>
      }
    >
      <FormInputPassword
        {...rest}
        name={name}
        size={size}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
      />
    </Popover>
  );
};

const StyledText = styled(Text)`
  display: flex;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default SetupPasswordField;
