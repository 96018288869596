import React from 'react';
import { ConfigProviderProps } from '.';
import { ConfigProvider as AntdConfigProvider } from 'antd';

const ConfigProvider: React.FC<ConfigProviderProps> = ({
  children,
  ...rest
}) => {
  return <AntdConfigProvider {...rest}>{children}</AntdConfigProvider>;
};

export default ConfigProvider;
