import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { MessageModel } from 'typings/messaging/messages';
import { formatDateToString } from 'helpers/dateHelpers';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT_UTC,
} from 'constants/global';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { Text, IconSVG, Popover } from '@ui';
import { ReactComponent as EyeIcon } from 'resources/icons/remix-icons/eye-line.svg';
import { ReactComponent as AttachmentIcon } from 'resources/icons/remix-icons/attachment-line.svg';

interface IProps {
  message: MessageModel;
}

const MessageTimeBar = memo(({ message }: IProps) => {
  const { t } = useTranslation('profile');
  const hasAttachment = !!message.attachments.length;

  return (
    <DivAlignCenter>
      {message.auditTrail.length ? (
        <Popover
          placement="bottom"
          content={
            <>
              {message.auditTrail.map((e) => (
                <Text key={e.userId}>
                  {t('messages.view_message.message_read_by', {
                    user: e.userFullName,
                    date: formatDateToString(
                      e.createdAt,
                      DEFAULT_DATE_TIME_FORMAT_UTC,
                      true,
                    ),
                  })}
                </Text>
              ))}
            </>
          }
        >
          <StyledIconSVG component={EyeIcon} size="extra-small" />
        </Popover>
      ) : null}
      {hasAttachment && (
        <IconSVG component={AttachmentIcon} size="extra-small" />
      )}
      <StyledText variant="body1" color={colorsTheme.colorWhite}>
        {formatDateToString(message.createdAt, DEFAULT_DATE_TIME_FORMAT)}
      </StyledText>
    </DivAlignCenter>
  );
});

const StyledText = styled(Text)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default MessageTimeBar;
