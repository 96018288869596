import React, { useCallback, useEffect, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '.';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { keyRelationshipTemplatesAPI } from 'api/crm/keyRelationshipTemplatesAPI';

// components
import SubmitButton from 'components/Buttons/SubmitButton';
import CountriesAutocomplete, {
  IProps as CountriesAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/CRM/CountriesAutocomplete';
import AvailableEntityTypes, {
  IProps as AvailableEntityTypesProps,
} from './AvailableEntityTypes';
import { Col, FormField, Message, Row } from '@ui';

const InnerForm = () => {
  const { t } = useTranslation(['onboarding', 'common']);
  const { values, setValues, setFieldValue } =
    useFormikContext<FormValuesModel>();
  const [loadingEntityTypes, setLoadingEntityTypes] = useState(false);

  useEffect(() => {
    async function fetchAvailableEntityTypes(
      country: string,
      initialEntityTypeCategory: { id: string; label: string } | undefined,
    ) {
      setLoadingEntityTypes(true);
      const response =
        await keyRelationshipTemplatesAPI.fetchKeyRelationshipTemplates({
          page: 1,
          limit: -1,
          isVisible: true,
          visibleFor: OnboardingEntryTypes.Organization,
          countryCode: country,
        });

      let options: { id: string; label: string }[] = [];

      if (response && response.data.length) {
        options = response.data.map((e) => ({
          id: e._id,
          label: e.name,
        }));
      }

      // This condition check will happen only in initial load
      if (initialEntityTypeCategory) {
        const initialOptionExistsInResponse = options.find(
          (e) => e.id === initialEntityTypeCategory.id,
        );

        if (!initialOptionExistsInResponse) {
          options.push(initialEntityTypeCategory);
        }
      }

      const selectedTypeExistInNewArray = values.entityTypeCategory
        ? options.find((e) => e.id === values.entityTypeCategory)
        : false;

      if (values.entityTypeCategory && !selectedTypeExistInNewArray) {
        setFieldValue('entityTypeCategory', null);
        Message.info(
          t(
            'intake_form.entity_type.entity_type_is_no_longer_available_warning',
          ),
        );
      }

      setFieldValue('availableEntityTypeCategories', options);
      setLoadingEntityTypes(false);
    }

    if (values.countryOfIncorporation) {
      fetchAvailableEntityTypes(
        values.countryOfIncorporation,
        values.initialEntityTypeCategory,
      );

      values.initialEntityTypeCategory &&
        setFieldValue('initialEntityTypeCategory', undefined);
    }
  }, [values.countryOfIncorporation]);

  const handleCountryFieldClear = useCallback(() => {
    setValues({
      ...values,
      entityTypeCategory: null,
      availableEntityTypeCategories: [],
    });
  }, [values, setValues]);

  return (
    <>
      <Row wrap={true} justify="start">
        <Col span={14} xxl={16} xl={16} lg={18} md={24} sm={24} xs={24}>
          <FormField<CountriesAutocompleteProps>
            label={t('intake_form.entity_type.country_of_incorporation')}
            name="countryOfIncorporation"
            component={CountriesAutocomplete}
            additionalProps={{
              initialValue: values.countryOfIncorporationInitialOption,
              onClear: handleCountryFieldClear,
            }}
          />
        </Col>
        {values.countryOfIncorporation ? (
          <Col span={14} xxl={16} xl={16} lg={18} md={24} sm={24} xs={24}>
            <FormField<AvailableEntityTypesProps>
              label={t('intake_form.entity_type.entity_type')}
              name="entityTypeCategory"
              component={AvailableEntityTypes}
              additionalProps={{ loading: loadingEntityTypes }}
            />
          </Col>
        ) : null}
      </Row>
      <SubmitButton>{t('confirm', { ns: 'common' })}</SubmitButton>
    </>
  );
};

export default InnerForm;
