import React, { useCallback } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import {
  MAX_EMAILS_COUNT,
  MAX_PHONE_NUMBERS_COUNT,
} from 'constants/onboarding';

// components
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import PhoneNumberForm, {
  FormValuesModel as PhoneNumberFormValuesModel,
} from '../PhoneNumberForm';
import EmailAddressForm, {
  FormValuesModel as EmailAddressFormValuesModel,
} from '../EmailAddressForm';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { SectionIntro, FormField, FormInput, AddButton, Col, Row } from '@ui';

export type FormValuesModel = {
  firstName: string;
  middleName: string;
  lastName: string;
  emails: EmailAddressFormValuesModel[];
  phoneNumbers: PhoneNumberFormValuesModel[];

  isContactBaseInfoFormDisabled?: boolean;
  displayTooltipsForPhoneAndEmailSections?: boolean;

  canVerifyPrimaryEmail: boolean;
  canVerifyPrimaryPhoneNumber: boolean;
};

const ContactGeneralForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const renderEmails = useCallback(
    (arrayHelper: FieldArrayRenderProps) => {
      const newEmail: EmailAddressFormValuesModel = {
        type: null,
        value: '',
        primary: {
          status: false,
          canEdit: true,
        },
      };

      return (
        <>
          {values.emails.map((email, i) => (
            <EmailAddressForm
              key={i}
              fieldName={`emails.${i}`}
              removeCallback={() => arrayHelper.remove(i)}
              canVerify={
                email.primary?.status &&
                values.canVerifyPrimaryEmail &&
                !email.isVerified
              }
            />
          ))}

          {values.emails.length < MAX_EMAILS_COUNT && (
            <HideIfDisabledForm hideIfSubmitting>
              <AddButton onClick={() => arrayHelper.push(newEmail)}>
                {t('applicant_information.add_email_button')}
              </AddButton>
            </HideIfDisabledForm>
          )}
        </>
      );
    },
    [values],
  );

  const renderPhoneNumbers = useCallback(
    (arrayHelper: FieldArrayRenderProps) => {
      const newPhoneNumber: PhoneNumberFormValuesModel = {
        type: null,
        value: '',
        primary: {
          status: false,
          canEdit: true,
        },
      };

      return (
        <>
          {values.phoneNumbers.map((phone, i) => (
            <PhoneNumberForm
              key={i}
              fieldName={`phoneNumbers.${i}`}
              removeCallback={() => arrayHelper.remove(i)}
              canVerify={
                phone.primary.status &&
                values.canVerifyPrimaryPhoneNumber &&
                !phone.isVerified
              }
            />
          ))}

          {values.phoneNumbers.length < MAX_PHONE_NUMBERS_COUNT && (
            <HideIfDisabledForm hideIfSubmitting>
              <AddButton onClick={() => arrayHelper.push(newPhoneNumber)}>
                {t('applicant_information.add_phone_number_button')}
              </AddButton>
            </HideIfDisabledForm>
          )}
        </>
      );
    },
    [values],
  );

  return (
    <Row gutter={[16, 0]}>
      <Col span={24}>
        <FormField
          label={t('applicant_information.form_fields.individual.first_name')}
          name="firstName"
          disabled={values.isContactBaseInfoFormDisabled}
          component={FormInput}
        />
      </Col>

      <Col span={24}>
        <FormField
          label={t('applicant_information.form_fields.individual.middle_name')}
          name="middleName"
          disabled={values.isContactBaseInfoFormDisabled}
          component={FormInput}
        />
      </Col>

      <Col span={24}>
        <FormField
          label={t('applicant_information.form_fields.individual.last_name')}
          name="lastName"
          disabled={values.isContactBaseInfoFormDisabled}
          component={FormInput}
        />
      </Col>

      <Col span={24}>
        <SectionIntro
          gutterTop
          titleVariant="h4"
          title={
            values.displayTooltipsForPhoneAndEmailSections ? (
              <DivAlignCenter>
                {t('applicant_information.email_address_section.title')}
                <StyledInfoTooltip
                  tooltipContent={t(
                    'applicant_information.cannot_update_primary_email_address_tooltip',
                  )}
                />
              </DivAlignCenter>
            ) : (
              t('applicant_information.email_address_section.title')
            )
          }
        />
        <FieldArray name="emails" render={renderEmails} />
      </Col>

      <Col span={24}>
        <SectionIntro
          gutterTop
          titleVariant="h4"
          title={
            values.displayTooltipsForPhoneAndEmailSections ? (
              <DivAlignCenter>
                {t('applicant_information.phone_number_section.title')}
                <StyledInfoTooltip
                  tooltipContent={t(
                    'applicant_information.cannot_update_primary_phone_number_tooltip',
                  )}
                />
              </DivAlignCenter>
            ) : (
              t('applicant_information.phone_number_section.title')
            )
          }
        />
        <FieldArray name="phoneNumbers" render={renderPhoneNumbers} />
      </Col>
    </Row>
  );
};

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

export default ContactGeneralForm;
