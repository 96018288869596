import React, { useEffect } from 'react';

// helpers
import { useEntityIntakeFormContext } from '../EntityIntakeFormContext';

// components
import StepsCard from 'components/Additional/StepsCard';
import EntityType from './EntityType';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';

const InnerContent = () => {
  const {
    steps,
    activeStepKey,
    intakeForm,
    loadingIntakeForm,
    selectStep,
    fetchAndStoreIntakeForm,
  } = useEntityIntakeFormContext();

  useEffect(() => {
    fetchAndStoreIntakeForm();
  }, []);

  return (
    <LoadingWrapper loading={loadingIntakeForm}>
      <StepsCard steps={steps} onSelect={selectStep} activeStep={activeStepKey}>
        {intakeForm ? <EntityType /> : null}
      </StepsCard>
    </LoadingWrapper>
  );
};

export default InnerContent;
