import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { MessageModel } from 'typings/messaging/messages';

// components
import { Text, Divider } from '@ui';
import DocumentLink from '../../../Typography/DocumentLink';

interface IProps {
  message: MessageModel;
  isActive?: boolean;
}

const MessageBody = memo(({ message, isActive }: IProps) => {
  const hasAttachment = !!message.attachments.length;

  const renderAttachments = () => {
    return message.attachments?.map((attachment) => (
      <DocumentLink
        key={attachment.documentId}
        documentId={attachment.documentId}
        fileId={attachment.fileId}
        fileName={attachment.name}
        text={attachment.name}
      />
    ));
  };

  return (
    <>
      <Text variant="body1">{message.body.content}</Text>
      {hasAttachment && isActive && (
        <>
          <StyledDivider dashed />
          {renderAttachments()}
        </>
      )}
    </>
  );
});

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.paddingMd} 0px`};
`;

export default MessageBody;
