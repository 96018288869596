import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormikHelpers } from 'formik';
import { useSignUpContext } from '../../SignUpContext';
import { VerifyPhoneNumberValidationSchema } from 'validations/profile/auth';

// components
import InnerForm from './InnerForm';
import { Form, SectionIntro } from '@ui';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';

export interface FormValuesModel {
  otp: string;
}

const PhoneVerificationStep = () => {
  const { t } = useTranslation(['auth', 'server_errors']);
  const { submitPhoneVerificationStep } = useSignUpContext();

  const initialValues = useMemo<FormValuesModel>(() => {
    return {
      otp: '',
    };
  }, []);

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1005006': {
        formikHelpers.setFieldError(
          'otp',
          t('1005006', { ns: 'server_errors' }),
        );
        break;
      }

      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <>
      <SectionIntro
        hideDivider
        titleColor={colorsTheme.colorWhite}
        title={t('open_an_account_new.verify_phone.title')}
      />
      <Form<FormValuesModel>
        onSubmit={submitPhoneVerificationStep}
        renderForm={<InnerForm />}
        initialValues={initialValues}
        validationSchema={VerifyPhoneNumberValidationSchema}
        onSubmitError={handleOnSubmitError}
      />
    </>
  );
};

export default PhoneVerificationStep;
