import { signOut } from './auth';
import { getBadges } from './app';
import { IApplication } from 'typings/application/applications';
import { decodeJWTToken } from 'helpers/commonHelpers';
import { applicationsAPI } from 'api/application/applicationsAPI';
import { setLayoutVariant } from './settings';
import { getMessagesStatus } from './notifications';
import { initUserPermissions } from './userAccess';
import { ApplicationStatuses, OnboardingStatuses } from 'enums/onboarding/crm';
import {
  setAuthenticationTokens,
  setActiveApplication as setActiveApplicationInLocalStorage,
  getActiveApplication as getActiveApplicationFromLocalStorage,
  getAccessToken,
} from 'helpers/localStorageHelpers';

// Actions
export enum ActionType {
  START_LOADING_APPLICATIONS = 'applications/START_LOADING_APPLICATIONS',
  SET_APPLICATIONS = 'applications/SET_APPLICATIONS',
  START_LOADING_ACTIVE_APPLICATION = 'applications/START_LOADING_ACTIVE_APPLICATION',
  SET_ACTIVE_APPLICATION = 'applications/SET_ACTIVE_APPLICATION',
  SET_ACTIVE_APPLICATION_STATUS = 'applications/SET_ACTIVE_APPLICATION_STATUS',
  SET_ACTIVE_APPLICATION_PHONE_NUMBER = 'applications/SET_ACTIVE_APPLICATION_PHONE_NUMBER',
  SET_ONBOARDING_ACCOUNT_MANAGEMENT_DATA = 'applications/SET_ONBOARDING_ACCOUNT_MANAGEMENT_DATA',
  CLEAN_UP = 'applications/CLEAN_UP',
}

// Action creators
export const setApplications =
  (applications: IApplication[] | null) => async (dispatch: any) => {
    dispatch({
      type: ActionType.SET_APPLICATIONS,
      payload: applications,
      key: 'applications',
    });
  };

export const cleanUpApplicationsReducer = () => ({
  type: ActionType.CLEAN_UP,
  payload: null,
});

export const setActiveApplication =
  (application: IApplication | null) => async (dispatch: any) => {
    dispatch({
      type: ActionType.SET_ACTIVE_APPLICATION,
      payload: application,
      key: 'activeApplication',
    });
  };

export const setActiveApplicationStatus =
  (status: ApplicationStatuses) => async (dispatch: any) => {
    dispatch({
      type: ActionType.SET_ACTIVE_APPLICATION_STATUS,
      payload: status,
      key: 'activeApplicationStatus',
    });
  };

export const setActiveApplicationPhoneNumber =
  (phone: string) => async (dispatch: any) => {
    dispatch({
      type: ActionType.SET_ACTIVE_APPLICATION_PHONE_NUMBER,
      payload: phone,
    });
  };

export const selectApplication =
  (
    application: IApplication | string,
    shouldShowLoader = false,
    updateNotificationStatuses = true,
    onCompletionCallback?: () => void,
  ) =>
  async (dispatch: any) => {
    if (shouldShowLoader) {
      dispatch(setActiveApplication(null));
      dispatch({
        type: ActionType.START_LOADING_APPLICATIONS,
        key: 'applications',
      });
    }

    const applicationId =
      typeof application === 'string' ? application : application._id;

    await dispatch(setLayoutVariant(null));

    const { token, permissions } = await applicationsAPI.selectApplication({
      applicationId,
    });
    setAuthenticationTokens(token.jwt, token.refreshToken);
    await dispatch(initUserPermissions(permissions));

    if (updateNotificationStatuses) {
      await dispatch(getBadges());
      await dispatch(getMessagesStatus());
    }
    let newApplication: IApplication | null = null;
    if (typeof application == 'string') {
      newApplication = await applicationsAPI.fetchActiveApplication();
    } else {
      newApplication = application;
    }

    dispatch(setActiveApplicationStatus(newApplication.activeScopeStatus));
    dispatch(setActiveApplication(newApplication));
    dispatch(
      setLayoutVariant(
        newApplication.onboardingProcess &&
          newApplication.onboardingProcess.status !==
            OnboardingStatuses.Approved
          ? 'OnboardingClientGroup'
          : 'ApprovedClientGroup',
      ),
    );

    setActiveApplicationInLocalStorage(applicationId);
    onCompletionCallback && onCompletionCallback();
  };

export const initApplications =
  (applications: IApplication[]) => async (dispatch: any) => {
    dispatch({
      type: ActionType.START_LOADING_APPLICATIONS,
      key: 'applications',
    });

    const applicationIdFromLocalStorage =
      getActiveApplicationFromLocalStorage();

    if (applicationIdFromLocalStorage) {
      const selectedApplication = applications.find(
        (e) => e._id === applicationIdFromLocalStorage,
      );

      if (selectedApplication) {
        const accessToken = getAccessToken();
        const decodedToken = decodeJWTToken(accessToken);
        const clientIdFromAccessToken = decodedToken?.metadata?.clientId;
        const { clientGroup } = selectedApplication;

        if (clientIdFromAccessToken != clientGroup.numericId) {
          await dispatch(selectApplication(selectedApplication, false, false));
        } else {
          dispatch(
            setActiveApplicationStatus(selectedApplication.activeScopeStatus),
          );
          dispatch(setActiveApplication(selectedApplication));
          dispatch(
            setLayoutVariant(
              selectedApplication.onboardingProcess &&
                selectedApplication.onboardingProcess.status !==
                  OnboardingStatuses.Approved
                ? 'OnboardingClientGroup'
                : 'ApprovedClientGroup',
            ),
          );
        }
      } else {
        dispatch(setActiveApplication(null));
        setActiveApplicationInLocalStorage('');
        dispatch(signOut());
      }
    } else {
      if (applications.length === 1) {
        await dispatch(selectApplication(applications[0], false, false));
      } else {
        dispatch(setLayoutVariant('NoClientGroup'));
      }
    }

    await dispatch(getBadges());
    await dispatch(getMessagesStatus());

    dispatch(setApplications(applications));
  };

export const updateActiveApplication =
  (isRefresh?: boolean, shouldReload = true) =>
  async (dispatch: any, getState: any) => {
    const activeApplication: IApplication | null =
      getState().applications.activeApplication;

    // If refresh, remove old active application
    if (isRefresh) {
      dispatch(setActiveApplication(null));
    }

    if (activeApplication) {
      try {
        if (shouldReload) {
          dispatch({
            type: ActionType.START_LOADING_ACTIVE_APPLICATION,
            key: 'activeApplication',
          });
        }

        const activeApplicationData =
          await applicationsAPI.fetchActiveApplication();

        if (activeApplicationData) {
          dispatch(setActiveApplication(activeApplicationData));
          dispatch(
            setActiveApplicationStatus(activeApplicationData.activeScopeStatus),
          );
        }
      } catch {
        setActiveApplicationInLocalStorage('');
        dispatch(setActiveApplication(null));
      }
    }
  };
