import React, { memo, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTimer from 'hooks/useTimer';
import useTranslation from 'hooks/useTranslation';
import { StyledComponentProps } from 'typings/common';

// components
import { Text, Button, Message } from '@ui';

interface IProps extends StyledComponentProps {
  timeout?: number;
  successMessage: string;
  shouldStartTimer?: boolean;
  startTimerTrigger?: any;
  startTimerFromDate?: Date;
  alignTimerTextCenter?: boolean;
  resendOtpCallback: () => Promise<void>;
  renderResendButton?: (
    resendOtpCallback: () => void,
    isSendingOtp: boolean,
  ) => React.ReactNode;
}

const ResendOtp = memo(
  ({
    timeout,
    successMessage,
    shouldStartTimer = true,
    startTimerFromDate,
    startTimerTrigger,
    resendOtpCallback,
    renderResendButton,
    alignTimerTextCenter = true,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('common');
    const { secondsLeft, startTimer } = useTimer(undefined, timeout);
    const [isSendingOTP, setSendingOTP] = useState(false);

    const resendOTP = async () => {
      setSendingOTP(true);
      try {
        startTimer();
        await resendOtpCallback();
        Message.success(successMessage);
      } finally {
        setSendingOTP(false);
      }
    };

    const resendContent = useMemo(() => {
      if (secondsLeft > 0) {
        return (
          <StyledText $alignCenter={alignTimerTextCenter}>
            {t('otp.expiration_description', { seconds: secondsLeft })}
          </StyledText>
        );
      } else if (secondsLeft === 0) {
        return renderResendButton ? (
          renderResendButton(resendOTP, isSendingOTP)
        ) : (
          <StyledButton
            {...rest}
            type="bordered"
            size="large"
            onClick={resendOTP}
            loading={isSendingOTP}
          >
            {t('otp.resend_otp_button')}
          </StyledButton>
        );
      }
    }, [isSendingOTP, secondsLeft, alignTimerTextCenter, renderResendButton]);

    useEffect(() => {
      if (shouldStartTimer) {
        startTimer(startTimerFromDate);
      }
    }, [startTimerTrigger, shouldStartTimer, startTimerFromDate]);

    return <>{resendContent}</>;
  },
);

const StyledButton = styled(Button)`
  width: 100%;
  margin: ${({ theme }) => theme.marginSm} auto 0 auto;
`;

const StyledText = styled(Text)<{ $alignCenter: boolean }>`
  text-align: ${({ $alignCenter }) => ($alignCenter ? 'center' : 'left')};
`;

export default ResendOtp;
