import React, { useMemo } from 'react';

// helpers
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';

// components
import OverdraftTab from 'modules/AccountManagement/LimitConfiguration/OverdraftTab';
import FeeScheduleTab from 'modules/AccountManagement/LimitConfiguration/FeeScheduleTab';
import { Tabs, TabModel } from '@ui';

const LimitConfiguration = () => {
  const { t } = useTranslation('account_management');

  const [hasFeeScheduleTabAccess, hasOverdraftTabAccess] = useUserAccess([
    'AccountManagement_LimitConfiguration_FeeSchedule_View',
    'AccountManagement_LimitConfiguration_Overdraft_View',
  ]);

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        key: 'fee-schedule',
        label: t('fee_schedule.title'),
        children: <FeeScheduleTab />,
        hidden: !hasFeeScheduleTabAccess,
      },
      {
        key: 'overdraft',
        label: t('overdraft.title'),
        children: <OverdraftTab />,
        hidden: !hasOverdraftTabAccess,
      },
    ];
  }, [hasFeeScheduleTabAccess, hasOverdraftTabAccess]);

  return <Tabs items={tabs} />;
};

export default LimitConfiguration;
