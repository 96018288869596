import React from 'react';

// helpers
import { StateModel } from '../redux/reducers';
import { RoutePaths } from 'routes/routes';
import { useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from '../redux/reducers/auth';
import {
  USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL,
  USER_TAG_IS_SKIPPED_VERIFICATION_SMS,
} from 'constants/userTags';

// components
import { Navigate, useLocation } from 'react-router-dom';

const withVerifyStatusCheck = () => (Component: any) => {
  const WithVerifyStatusCheck = () => {
    const location = useLocation();
    const { verificationStatuses, profileData, signUpFlow } = useSelector<
      StateModel,
      AuthStateModel
    >((store) => store.auth);

    const shouldNavigateToVerificationPage =
      (!verificationStatuses.isEmailVerified &&
        !profileData?.tags.includes(USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL)) ||
      (!verificationStatuses.isPhoneVerified &&
        !profileData?.tags.includes(USER_TAG_IS_SKIPPED_VERIFICATION_SMS));

    const pathToVerifyPage = signUpFlow?.useNewSignupFlow
      ? RoutePaths.StartClientIntakeForm
      : RoutePaths.VerificationPage;

    if (location.pathname === pathToVerifyPage) {
      return shouldNavigateToVerificationPage ? (
        <Component />
      ) : (
        <Navigate to={RoutePaths.Root} />
      );
    } else if (shouldNavigateToVerificationPage) {
      return <Navigate to={pathToVerifyPage} />;
    }

    return <Component />;
  };

  return WithVerifyStatusCheck;
};

export default withVerifyStatusCheck;
