import React from 'react';

// components
import SignUpForm from 'modules/Auth/SignUpForm';

const SignUpPage = () => {
  return <SignUpForm />;
};

export default SignUpPage;
