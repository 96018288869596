import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';

// components
import SubmitButton from 'components/Buttons/SubmitButton';
import FormErrorTooltip from 'components/Forms/FormErrorTooltip';
import { ReactComponent as BlankCheckIcon } from 'resources/icons/remix-icons/checkbox-blank-line.svg';
import { ReactComponent as FilledCheckIcon } from 'resources/icons/remix-icons/checkbox-line.svg';
import { Col, IconSVG, Row, SectionIntro, Text } from '@ui';

const Option = ({
  text,
  isActive,
  onSelect,
}: {
  text: string;
  isActive: boolean;
  onSelect: () => void;
}) => {
  return (
    <StyledOptionRoot $isActive={isActive} onClick={onSelect}>
      <Row wrap={false}>
        <Col flex="50px">
          <CenterAlignWrapper>
            <IconSVG
              size="middle"
              color={colorsTheme.colorWhite}
              component={isActive ? FilledCheckIcon : BlankCheckIcon}
            />
          </CenterAlignWrapper>
        </Col>
        <Col flex="auto">
          <OptionTextWrapper>
            <Text
              variant="h4"
              color={isActive ? colorsTheme.colorWhite : undefined}
            >
              {text}
            </Text>
          </OptionTextWrapper>
        </Col>
      </Row>
    </StyledOptionRoot>
  );
};

const InnerForm = () => {
  const { t } = useTranslation(['auth', 'common']);
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  return (
    <>
      <GutterBottom>
        <SectionIntro
          hideDivider
          titleColor={colorsTheme.colorWhite}
          title={t('open_an_account_new.account_type.title')}
          description={t('open_an_account_new.account_type.description')}
        />
      </GutterBottom>
      <GutterBottom>
        <FormErrorTooltip<FormValuesModel> errorKey="accountType" />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Option
              isActive={values.accountType === OnboardingEntryTypes.Contact}
              text={t(
                'open_an_account_new.account_type.application_types.individual',
              )}
              onSelect={() =>
                setFieldValue('accountType', OnboardingEntryTypes.Contact)
              }
            />
          </Col>
          <Col span={24}>
            <Option
              isActive={
                values.accountType === OnboardingEntryTypes.Organization
              }
              text={t(
                'open_an_account_new.account_type.application_types.organization',
              )}
              onSelect={() =>
                setFieldValue('accountType', OnboardingEntryTypes.Organization)
              }
            />
          </Col>
        </Row>
      </GutterBottom>
      <SubmitButton>{t('continue', { ns: 'common' })}</SubmitButton>
    </>
  );
};

const GutterBottom = styled.div`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const OptionTextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const CenterAlignWrapper = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
`;

const StyledOptionRoot = styled.div<{ $isActive: boolean }>`
  padding: ${({ theme }) => theme.paddingXl};
  cursor: pointer;
  border: ${({ theme }) => `2px solid ${theme.colorDarkL3}`};
  transition: ${({ theme }) => theme.defaultTransition};
  &:hover {
    border-color: ${({ theme }) => theme.colorWhite};
  }
  ${({ $isActive, theme }) =>
    $isActive ? `border-color: ${theme.colorWhite};` : ''}
`;

export default InnerForm;
