import { createGlobalStyle } from 'styled-components';

// TODO: use values from theme
export const GlobalStyle = createGlobalStyle<{ theme?: any }>`

  :root {
    --antd-font-family: 'EurostileLT', sans-serif;
  }

  html {
    font-size: 14px;
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #c6cccf;
    }

    a {
      color: #22a35c;
    }

    a:hover, a:active{
      filter: brightness(85%); /* Darken the color by 15% */
      color: #22a35c;
    }
  }

  body {
    font-family: var(--antd-font-family);
    background-color: ${({ theme }) => theme.colorDark};
    letter-spacing: .8px;
  }
`;
