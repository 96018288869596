import APIConfig from 'config/api';
import { APIService } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from 'typings/common';

interface FetchCountriesParamsModel extends FetchParamsModel {
  search?: string;
  riskRating?: string;
}

export type CountryFromQuery = {
  id: string;
  name: string;
  alpha2Code: string;
};

const countriesAPI = {
  fetchCountries: (params: FetchCountriesParamsModel) => {
    return APIService.get<FetchResponseModel<CountryFromQuery>>(
      `${APIConfig.amlApi}/countries`,
      { params },
    ).then(({ data }) => data);
  },
};

export { countriesAPI };
