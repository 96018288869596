import React, { useEffect, useState } from 'react';

// helpers
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { darkTheme } from 'resources/theme/styled';
import { StateModel } from 'redux/reducers';
import { useSignUpContext } from 'modules/Auth/SignUpFormNew/SignUpContext';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { setPrimaryPhoneNumber, signOut } from 'redux/actions/auth';
import { setActiveApplicationPhoneNumber } from 'redux/actions/applications';
import {
  profileAPI,
  UpdatePhoneNumberResponseModel,
} from 'api/profile/profileAPI';
import {
  getAttemptsOfResendingSmsOTP,
  setAttemptsOfResendingSmsOTP,
} from 'helpers/localStorageHelpers';

// components
import OTPField from 'components/Forms/FormComponents/OTPField';
import ResendOtp from 'components/Additional/ResendOtp';
import SubmitButton from 'components/Buttons/SubmitButton';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ChangePhoneNumber from './ChangePhoneNumber';
import { AsyncButton, Button, Col, FormField, Row, Text } from '@ui';

const InnerForm = () => {
  const dispatch = useDispatch();
  const { skipPhoneVerification } = useSignUpContext();
  const { t } = useTranslation('auth');
  const { contactData } = useSelector<StateModel, AuthStateModel>(
    (store) => store.auth,
  );
  const [amountOfResendAttempts, setAmountOfResendAttempts] = useState(
    Number(getAttemptsOfResendingSmsOTP()),
  );
  const [timerConfig, setTimerConfig] = useState<{
    isInitialized: boolean;
    timerTillDate: Date | null;
  }>({
    isInitialized: false,
    timerTillDate: null,
  });

  const startVerificationProcess = async (
    phone: string,
  ): Promise<UpdatePhoneNumberResponseModel> => {
    const response = await profileAPI.resendPhoneNumberOTP(phone);
    setTimerConfig({
      isInitialized: true,
      timerTillDate: new Date(response.verification.verificationExpiresAt),
    });
    return response;
  };

  useEffect(() => {
    if (amountOfResendAttempts >= 2) {
      setTimerConfig({ isInitialized: true, timerTillDate: null });
    } else {
      const otpSendDate =
        contactData?.phoneNumber.verification?.verificationExpiresAt;
      if (!otpSendDate) {
        startVerificationProcess(contactData?.phoneNumber?.number as string);
      } else {
        const difference = moment(otpSendDate).diff(moment(), 'seconds');
        if (difference <= 0) {
          startVerificationProcess(contactData?.phoneNumber?.number as string);
        } else {
          setTimerConfig({
            isInitialized: true,
            timerTillDate: new Date(otpSendDate),
          });
        }
      }
    }
  }, []);

  const onSignOutClick = () => {
    dispatch(signOut());
  };

  const resendOTP = async () => {
    await startVerificationProcess(contactData?.phoneNumber?.number as string);
    const newAmountOfResendingAttempts = amountOfResendAttempts + 1;
    setAmountOfResendAttempts(newAmountOfResendingAttempts);
    setAttemptsOfResendingSmsOTP(newAmountOfResendingAttempts);
  };

  const handlePhoneChange = async (newPhoneNumber: string) => {
    const response = await startVerificationProcess(newPhoneNumber);
    setAmountOfResendAttempts(0);
    setAttemptsOfResendingSmsOTP(0);
    dispatch(setPrimaryPhoneNumber(response.number, response.verification));
    dispatch(setActiveApplicationPhoneNumber(response.number));
  };

  return (
    <LoadingWrapper loading={!timerConfig.isInitialized}>
      <DescriptionWrapper>
        <Text>{t('open_an_account_new.verify_phone.description')}</Text>
        <DivAlignCenter>
          <Text variant="span">{contactData?.phoneNumber?.number}</Text>
          <ChangePhoneNumber
            startVerificationProcessForNewPhone={handlePhoneChange}
          />
        </DivAlignCenter>
      </DescriptionWrapper>
      <OTPSection>
        <FormField
          label={t('open_an_account_new.verify_phone.form_fields.otp')}
          name="otp"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
        <ResendOtp
          alignTimerTextCenter={false}
          timeout={0}
          startTimerFromDate={timerConfig?.timerTillDate ?? undefined}
          successMessage={t('phone_verification.resend_success', {
            ns: 'auth',
          })}
          resendOtpCallback={resendOTP}
          renderResendButton={
            amountOfResendAttempts < 2
              ? (resendCallback) => (
                  <StyledResendOTPButton
                    type="bordered"
                    size="large"
                    onClick={resendCallback}
                    fullWidth={false}
                  >
                    {t('otp.resend_otp_button', { ns: 'common' })}
                  </StyledResendOTPButton>
                )
              : () => (
                  <StyledResendOTPButton
                    type="bordered"
                    size="large"
                    onClick={skipPhoneVerification}
                  >
                    {t('phone_verification.skip_button', { ns: 'auth' })}
                  </StyledResendOTPButton>
                )
          }
        />
        <StyledText gutterTop color={darkTheme.colorLightD1}>
          {t('phone_verification.verification_can_be_skip_after_two_attempts')}
        </StyledText>
      </OTPSection>
      <Row gutter={[16, 16]}>
        <Col flex="150px">
          <StyledButton
            size="large"
            fullWidth
            type="bordered"
            onClick={onSignOutClick}
          >
            {t('exit', { ns: 'common' })}
          </StyledButton>
        </Col>
        <Col flex="150px">
          <StyledSubmitButton>
            {t('verify', { ns: 'common' })}
          </StyledSubmitButton>
        </Col>
      </Row>
    </LoadingWrapper>
  );
};

const OTPSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const StyledButton = styled(Button)`
  min-width: 0px;
`;

const StyledResendOTPButton = styled(AsyncButton)`
  width: 260px;
  min-width: 0px;
`;

const StyledSubmitButton = styled(SubmitButton)`
  min-width: 0px;
`;

const StyledText = styled(Text)`
  font-style: italic;
`;

const DescriptionWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
