import React from 'react';

// helpers
import styled from 'styled-components';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { ReactComponent as PrimaryLogo } from 'resources/app-logos/primary-logo.svg';
import {
  USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL,
  USER_TAG_IS_SKIPPED_VERIFICATION_SMS,
} from 'constants/userTags';

// components
import FooterBar from 'components/Layout/FooterBar';
import ProfileActions from '../MainLayout/HeaderBar/ProfileActions';
import { Row, Col } from '@ui';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

const SignUpLayoutNew = () => {
  const navigate = useNavigate();
  const { isAuthorized, signUpFlow, verificationStatuses, profileData } =
    useSelector<StateModel, AuthStateModel>((store) => store.auth);

  const shouldVerifyEmailORPhone =
    (!verificationStatuses.isEmailVerified &&
      !profileData?.tags?.includes(USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL)) ||
    (!verificationStatuses.isPhoneVerified &&
      !profileData?.tags?.includes(USER_TAG_IS_SKIPPED_VERIFICATION_SMS));
  const shouldNavigateToRoot =
    (!isAuthorized && !signUpFlow) ||
    (isAuthorized && !shouldVerifyEmailORPhone) ||
    !signUpFlow?.useNewSignupFlow;

  if (shouldNavigateToRoot) {
    return <Navigate to={RoutePaths.Root} />;
  }

  return (
    <>
      <HeaderBar>
        <HeaderRow align="middle" justify="space-between">
          <HeaderLogoCol flex="1 1">
            <StyledLogo onClick={() => navigate(RoutePaths.Root)} />
          </HeaderLogoCol>
          <Col>
            <ProfileActions />
          </Col>
        </HeaderRow>
      </HeaderBar>
      <MainContentWrapper>
        <Outlet />
      </MainContentWrapper>
      <FooterBar transparent />
    </>
  );
};

const HeaderBar = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  min-height: ${({ theme }) => theme.headerHeight};
  max-height: ${({ theme }) => theme.headerHeight};
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.containerPadding};
  background-color: ${({ theme }) => theme.colorDarkD1};
  .ant-menu {
    font-size: ${({ theme }) => theme.headerMenuFontSize};
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    height: ${({ theme }) => theme.headerHeight};
    min-height: ${({ theme }) => theme.headerHeightMobile};
    max-height: ${({ theme }) => theme.headerHeightMobile};
  }
`;

const HeaderRow = styled(Row)`
  width: 100%;
`;

const HeaderLogoCol = styled(Col)`
  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    display: flex;
    justify-content: center;
  }
`;

const MainContentWrapper = styled.div`
  min-height: ${({ theme }) =>
    `calc(100vh - ${theme.footerHeight} - ${theme.footerMargin})`};
  padding-top: ${({ theme }) => `calc(${theme.headerHeight} + 64px)`};
`;

const StyledLogo = styled(PrimaryLogo)`
  width: ${({ theme }) => theme.headerLogoWidth};
  cursor: pointer;
`;

export default SignUpLayoutNew;
