import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormikHelpers } from 'formik';
import { useSignUpContext } from '../../SignUpContext';
import { userInfoValidationSchema } from 'validations/intake-form/sign-up';

// components
import InnerForm from './InnerForm';
import { Form } from '@ui';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';

export interface FormValuesModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  isPEP: boolean | null;
  pepInformation: string;
}

const UserInfoStep = () => {
  const { t } = useTranslation(['server_errors', 'common']);
  const { stepsData, submitUserInfoStep } = useSignUpContext();

  const initialFormValues = useMemo<FormValuesModel>(() => {
    if (stepsData.userInfo.data) {
      return stepsData.userInfo.data;
    } else {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        isPEP: null,
        pepInformation: '',
      };
    }
  }, [stepsData.userInfo]);

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
    switch (errorCode) {
      case '1001007': {
        formikHelpers.setFieldError('email', t('1001007'));
        break;
      }

      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form
      onSubmit={submitUserInfoStep}
      renderForm={<InnerForm />}
      initialValues={initialFormValues}
      onSubmitError={handleOnSubmitError}
      validationSchema={userInfoValidationSchema}
    />
  );
};

export default UserInfoStep;
