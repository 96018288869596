import React from 'react';

// helpers
import { styled } from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { IconButton, IconSVG } from '@ui';
import { ReactComponent as ArrowLeft } from 'resources/icons/remix-icons/arrow-left-line.svg';

interface IProps {
  title?: React.ReactNode;
  extra?: React.ReactNode;
  content?: React.ReactNode;
  children: React.ReactNode;
  subTitle?: React.ReactNode;
  onBack?: () => void;
}

const PageHeader = ({
  title,
  extra,
  subTitle,
  content,
  children,
  onBack,
}: IProps) => {
  return (
    <MainWrapper>
      {title && (
        <TitleWrapper>
          <TitleContainer>
            {onBack ? (
              <StyledIconButton
                icon={
                  <IconSVG
                    component={ArrowLeft}
                    size="middle"
                    color={colorsTheme.colorWhite}
                  />
                }
                onClick={onBack}
              />
            ) : null}
            <Title>{title}</Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </TitleContainer>
          {extra && <ExtraContent>{extra}</ExtraContent>}
        </TitleWrapper>
      )}
      <Content>
        {content}
        {children}
      </Content>
    </MainWrapper>
  );
};

const Content = styled.div`
  padding-top: 12px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  &::after {
    background-color: ${({ theme }) => theme.colorLightL1};
    bottom: -2px;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 22px;
  }
`;
const StyledIconButton = styled(IconButton)`
  background: transparent;
  border: unset;
  cursor: pointer;

  svg {
    cursor: pointer;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:hover {
    background: transparent !important;

    svg {
      fill: #22a35c !important;
    }
  }
`;

const SubTitle = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colorLightL1};
  font-size: 34px;
  font-weight: 400;
  line-height: 38px;
  margin: 0;
  padding: 5px;
`;

const ExtraContent = styled.div`
  margin: 4px 0;
  white-space: nowrap;
`;

const MainWrapper = styled.div`
  padding: 12px 0 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #dfe3e540;
  padding: 12px 0;
  position: relative;
  text-transform: uppercase;
`;

export default PageHeader;
