import React, { useEffect } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import SubmitButton from 'components/Buttons/SubmitButton';
import TrueFalseSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseSelect';
import SetupPasswordField from 'components/Forms/FormComponents/SetupPasswordField';
import {
  FormField,
  FormInput,
  FormInputPassword,
  FormInputPhoneNumber,
  FormInputTextArea,
  SectionIntro,
} from '@ui';

const InnerForm = () => {
  const { t } = useTranslation(['auth', 'common']);
  const form = useFormikContext<FormValuesModel>();

  useEffect(() => {
    if (!form.values.isPEP && form.values.pepInformation) {
      form.setFieldValue('pepInformation', '');
    }
  }, [form.values.isPEP]);

  return (
    <>
      <SectionIntro
        hideDivider
        titleColor={colorsTheme.colorWhite}
        title={t('open_an_account_new.basic_info.title')}
        description={t('open_an_account_new.basic_info.description')}
      />
      <FormField
        label={t('open_an_account_new.basic_info.form_fields.first_name')}
        name="firstName"
        component={FormInput}
      />
      <FormField
        label={t('open_an_account_new.basic_info.form_fields.last_name')}
        name="lastName"
        component={FormInput}
      />
      <FormField
        label={t('open_an_account_new.basic_info.form_fields.email')}
        name="email"
        component={FormInput}
      />
      <FormField
        label={t('open_an_account_new.basic_info.form_fields.phone')}
        name="phone"
        component={FormInputPhoneNumber}
      />
      <FormField
        label={t('open_an_account_new.basic_info.form_fields.password')}
        name="password"
        component={SetupPasswordField}
      />
      <FormField
        label={t('open_an_account_new.basic_info.form_fields.confirm_password')}
        name="passwordConfirm"
        component={FormInputPassword}
      />
      <FormField
        label={t('open_an_account_new.basic_info.form_fields.isPEP')}
        name="isPEP"
        component={TrueFalseSelect}
      />
      {form.values.isPEP ? (
        <FormField
          label={t('open_an_account_new.basic_info.form_fields.pepInfo')}
          name="pepInformation"
          component={FormInputTextArea}
          disabled={!form.values.isPEP}
        />
      ) : null}
      <SubmitButton>{t('continue', { ns: 'common' })}</SubmitButton>
    </>
  );
};

export default InnerForm;
