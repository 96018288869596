import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Badge } from '@ui';

interface IProps {
  pendingCount: number;
  position?: 'center' | 'top';
}

const PendingBadge = ({ pendingCount, position = 'center' }: IProps) => {
  return <StyledBadge position={position} count={pendingCount} />;
};

const StyledBadge = styled(Badge)<{ position: 'center' | 'top' }>`
  margin-left: ${({ theme }) => theme.marginXXs};

  .ant-badge-count {
    border: none;
    border-radius: 4px;
    box-shadow: none;
    display: flex !important;
    top: ${({ position }) => (position === 'top' ? '-19px' : '-1px')}!important;
    right: ${({ position }) => (position === 'top' ? '19px' : '')};
  }
`;
export default PendingBadge;
