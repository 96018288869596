import React, { useCallback, useContext, useMemo, useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService from 'services/error-handler/service';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { Step, StepStatus } from 'components/Additional/StepsCard';
import {
  intakeFormAPI,
  IntakeFormFromQuery,
  IntakeFormStepStatus,
} from 'api/crm/intakeFormAPI';

interface EntityIntakeFormContextData {
  intakeForm: IntakeFormFromQuery | null;
  setIntakeForm: (intakeForm: IntakeFormFromQuery) => void;
  steps: Step[];
  activeStepKey: string | null;
  selectStep: (key: string) => void;
  loadingIntakeForm: boolean;
  fetchAndStoreIntakeForm: () => Promise<void>;
}

// * Context
const EntityIntakeFormContext =
  React.createContext<EntityIntakeFormContextData>(
    {} as EntityIntakeFormContextData,
  );

// * Provider
export const EntityIntakeFormContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation('onboarding');
  const intakeFormId = useSelector<StateModel, string | undefined>(
    (state) => state.intakeForm.intakeForm?._id,
  );
  const [activeStepKey, setActiveStepKey] = useState<string | null>(
    'entityType',
  );
  const [loadingIntakeForm, setLoadingIntakeForm] = useState(false);
  const [intakeForm, setIntakeForm] = useState<IntakeFormFromQuery | null>(
    null,
  );

  const steps = useMemo<Step[]>(() => {
    if (!intakeForm) {
      return [];
    }

    function mapStepStatus(status: IntakeFormStepStatus): StepStatus {
      switch (status) {
        case 'incomplete':
          return 'new';
        case 'complete':
          return 'completed';
        default:
          return 'new';
      }
    }

    return [
      {
        key: 'entityType',
        title: t('intake_form.entity_type.title'),
        status: mapStepStatus(intakeForm.completionStatuses.entityType),
        disabled: false,
      },
    ];
  }, [intakeForm]);

  const fetchAndStoreIntakeForm = useCallback(async () => {
    if (!intakeFormId) return;

    setLoadingIntakeForm(true);
    try {
      const response = await intakeFormAPI.fetchIntakeFormById(intakeFormId);
      setIntakeForm(response.clientIntakeSubmission);
    } catch (err) {
      ErrorHandlerService.handleError(err as any);
    } finally {
      setLoadingIntakeForm(false);
    }
  }, [intakeFormId]);

  return (
    <EntityIntakeFormContext.Provider
      value={{
        steps,
        activeStepKey,
        selectStep: setActiveStepKey,
        intakeForm,
        setIntakeForm,
        loadingIntakeForm,
        fetchAndStoreIntakeForm,
      }}
    >
      {children}
    </EntityIntakeFormContext.Provider>
  );
};

// * Hook
export function useEntityIntakeFormContext(): EntityIntakeFormContextData {
  const context = useContext(EntityIntakeFormContext);
  return context;
}
