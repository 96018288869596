import { ActionType } from '../actions/settings';
import { IReduxAction } from '../../typings/common';

export type LayoutVariants =
  | 'ApprovedClientGroup'
  | 'OnboardingClientGroup'
  | 'NoClientGroup'
  | 'IntakeForm';

export interface StateModel {
  language: string;
  layoutVariant: LayoutVariants | null;
}

const initialState: StateModel = {
  language: 'en',
  layoutVariant: null,
};

const settings = (state = initialState, action: IReduxAction) => {
  const { type, payload, key } = action;

  switch (type) {
    case ActionType.SET_LANGUAGE:
      return {
        ...state,
        [key]: payload,
      };

    case ActionType.SET_LAYOUT_VARIANT:
      return {
        ...state,
        layoutVariant: payload.variant,
      };

    case ActionType.CLEAN_UP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default settings;
