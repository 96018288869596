import * as yup from 'yup';

// constants
import { CHECK_COUNTRY } from '../../constants/regularExpressions';
import { TransferTemplateTypes } from '../../enums/accounting/transfers';
import { OTHER_BANK_ACCOUNT_OPTION } from '../../components/Forms/FormComponents/Autocompletes/Accounting/RecipientBankAccountAutocomplete';
import {
  CONFIRMATION_REASON_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  TRANSFER_REFERENCE_MAX_LENGTH,
} from '../../constants/global';
import {
  NULLABLE_NUMBER_VALIDATION_RULE,
  NULLABLE_OBJECT_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  SWIFT_RELATED_TEXT_VALIDATION_RULE,
} from '../common';

const bankDetailsSchema = yup.object().shape({
  bankCodeType: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  bankCode: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 256,
    },
  }).required('form:validation_messages.required'),
  bankName: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 256,
    },
  }).required('form:validation_messages.required'),
  country: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  address: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 256,
    },
  }),
  city: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 256,
    },
  }),
  postCode: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(32, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 32,
    },
  }),
  district: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 50,
    },
  }),
});

export const newTransferTemplateSchema = yup.object().shape({
  eventDate: NULLABLE_STRING_VALIDATION_RULE,
  templateName: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  templateType: yup.mixed().required('form:validation_messages.required'),
  transferFields: yup
    .object()
    .when('templateType', {
      is: TransferTemplateTypes.Internal,
      then: (fieldSchema: any) =>
        fieldSchema.shape({
          accountName: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          accountNumber: STRING_VALIDATION_RULE.max(23, {
            key: 'form:validation_messages.max_characters',
            values: { number: 23 },
          }).required('form:validation_messages.required'),
          recipientReference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
            TRANSFER_REFERENCE_MAX_LENGTH,
            {
              key: 'form:validation_messages.max_characters',
              values: {
                number: TRANSFER_REFERENCE_MAX_LENGTH,
              },
            },
          ),
        }),
    })
    .when('templateType', {
      is: TransferTemplateTypes.Wire,
      then: (fieldSchema: any) =>
        fieldSchema.shape({
          accountName: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(35, {
            key: 'form:validation_messages.max_characters',
            values: { number: 35 },
          }).required('form:validation_messages.required'),
          accountNumber: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(34, {
            key: 'form:validation_messages.max_characters',
            values: { number: 34 },
          }).required('form:validation_messages.required'),
          country: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          beneficiaryCity: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(20, {
            key: 'form:validation_messages.max_characters',
            values: {
              number: 20,
            },
          }),
          beneficiaryDistrict: STRING_VALIDATION_RULE.when('country', {
            is: (country: string) => CHECK_COUNTRY.test(country),
            then: (schema) =>
              schema.required('form:validation_messages.required'),
          }).max(20, {
            key: 'form:validation_messages.max_characters',
            values: {
              number: 20,
            },
          }),
          beneficiaryPostCode: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(10, {
            key: 'form:validation_messages.max_characters',
            values: {
              number: 10,
            },
          }),
          address: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ).max(35, {
            key: 'form:validation_messages.max_characters',
            values: {
              number: 35,
            },
          }),
          beneficiaryBank: bankDetailsSchema,
          intermediaryBank: yup.object().when('useIntermediary', {
            is: true,
            then: () => bankDetailsSchema,
          }),
          reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
            TRANSFER_REFERENCE_MAX_LENGTH,
            {
              key: 'form:validation_messages.max_characters',
              values: {
                number: TRANSFER_REFERENCE_MAX_LENGTH,
              },
            },
          ),
        }),
    })
    .when('templateType', {
      is: TransferTemplateTypes.Blockchain,
      then: (fieldSchema: any) =>
        fieldSchema.shape({
          networkChain: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          beneficiaryAddress: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          beneficiaryTag: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          processingType: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
        }),
    }),
});

export const internalTransferValidationSchema = yup.object().shape({
  amount: NULLABLE_NUMBER_VALIDATION_RULE.min(0).required({
    key: 'form:validation_messages.min_number_value',
    values: { number: 0 },
  }),

  transactionCurrencyId: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  valueDate: yup.date().required('form:validation_messages.required'),

  transactionPurpose: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
    DESCRIPTION_MAX_LENGTH,
    {
      key: 'form:validation_messages.max_characters',
      values: {
        number: DESCRIPTION_MAX_LENGTH,
      },
    },
  ).required('form:validation_messages.required'),

  sender: yup
    .object()
    .shape({
      accountNumber: NULLABLE_STRING_VALIDATION_RULE.required(
        'form:validation_messages.required',
      ),
      reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
        TRANSFER_REFERENCE_MAX_LENGTH,
        {
          key: 'form:validation_messages.max_characters',
          values: {
            number: TRANSFER_REFERENCE_MAX_LENGTH,
          },
        },
      ),
    })
    .test(
      'same_accounts',
      'server_errors:custom_errors.same_sender_accounts_error',
      function (value, context) {
        return context.parent.recipient.accountNumber && value.accountNumber
          ? context.parent.recipient.accountNumber !== value.accountNumber
          : true;
      },
    ),

  recipient: yup
    .object()
    .shape({
      accountNumber: NULLABLE_STRING_VALIDATION_RULE.required(
        'form:validation_messages.required',
      ),

      reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
        TRANSFER_REFERENCE_MAX_LENGTH,
        {
          key: 'form:validation_messages.max_characters',
          values: {
            number: TRANSFER_REFERENCE_MAX_LENGTH,
          },
        },
      ),

      templateId: NULLABLE_NUMBER_VALIDATION_RULE.when('accountNumber', {
        is: (accountNumber: any) => accountNumber === OTHER_BANK_ACCOUNT_OPTION,
        then: (schema) => schema.required('form:validation_messages.required'),
      }),
    })
    .test(
      'same_accounts',
      'server_errors:custom_errors.same_recipient_accounts_error',
      function (value, context) {
        return context.parent.sender.accountNumber && value.accountNumber
          ? context.parent.sender.accountNumber !== value.accountNumber
          : true;
      },
    ),
});

export const wireTransferValidationSchema = yup.object().shape({
  amount: NULLABLE_NUMBER_VALIDATION_RULE.min(0).required(
    'form:validation_messages.required',
  ),

  transactionCurrencyId: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  valueDate: yup.date().required('form:validation_messages.required'),

  transactionPurpose: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
    DESCRIPTION_MAX_LENGTH,
    {
      key: 'form:validation_messages.max_characters',
      values: {
        number: DESCRIPTION_MAX_LENGTH,
      },
    },
  ).required('form:validation_messages.required'),

  sender: yup.object().shape({
    accountNumber: NULLABLE_STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
      TRANSFER_REFERENCE_MAX_LENGTH,
      {
        key: 'form:validation_messages.max_characters',
        values: {
          number: TRANSFER_REFERENCE_MAX_LENGTH,
        },
      },
    ),
  }),

  recipient: yup.object().shape({
    reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
      TRANSFER_REFERENCE_MAX_LENGTH,
      {
        key: 'form:validation_messages.max_characters',
        values: {
          number: TRANSFER_REFERENCE_MAX_LENGTH,
        },
      },
    ),

    templateId: NULLABLE_NUMBER_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
  }),
});

export const blockchainTransferValidationSchema = yup.object().shape({
  amount: NULLABLE_NUMBER_VALIDATION_RULE.min(
    0,
    'form:validation_messages.min_number_value',
  ).required('form:validation_messages.required'),

  transactionCurrencyId: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  valueDate: yup.date().required('form:validation_messages.required'),

  transactionPurpose: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
    DESCRIPTION_MAX_LENGTH,
    {
      key: 'form:validation_messages.max_characters',
      values: {
        number: DESCRIPTION_MAX_LENGTH,
      },
    },
  ).required('form:validation_messages.required'),

  sender: yup
    .object()
    .shape({
      accountNumber: NULLABLE_STRING_VALIDATION_RULE.required(
        'form:validation_messages.required',
      ),
      reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
        DESCRIPTION_MAX_LENGTH,
        {
          key: 'form:validation_messages.max_characters',
          values: {
            number: DESCRIPTION_MAX_LENGTH,
          },
        },
      ),
    })
    .test(
      'same_accounts',
      'The from account must be different than the recipient account',
      function (value, context) {
        return context.parent.recipient.accountNumber && value.accountNumber
          ? context.parent.recipient.accountNumber !== value.accountNumber
          : true;
      },
    ),
});

export const reviewMultipleTransactionsValidationSchema = yup.object().shape({
  reason: STRING_VALIDATION_RULE.max(CONFIRMATION_REASON_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: { number: CONFIRMATION_REASON_MAX_LENGTH },
  }).required('form:validation_messages.required'),
});

export const submitMultipleWireTransfersValidationSchema = yup.object().shape({
  transactions: yup
    .array()
    .min(1, 'custom_errors:transfers.min_transactions_required')
    .of(
      yup.object().shape({
        from: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),

        purpose: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
          DESCRIPTION_MAX_LENGTH,
          {
            key: 'form:validation_messages.max_characters',
            values: {
              number: DESCRIPTION_MAX_LENGTH,
            },
          },
        ).required('form:validation_messages.required'),

        amount: NULLABLE_NUMBER_VALIDATION_RULE.min(0.1, {
          key: 'form:validation_messages.min_number_value',
          values: { number: 0.1 },
        })
          .required('form:validation_messages.required')
          .test(
            'balance_is_too_low',
            'server_errors:custom_errors.balance_too_low',
            function (value, context) {
              if (!context.parent.balance) {
                return true;
              }

              return value <= context.parent.balance.available;
            },
          ),

        valueDate: NULLABLE_OBJECT_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),

        fromReference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
          DESCRIPTION_MAX_LENGTH,
          {
            key: 'form:validation_messages.max_characters',
            values: {
              number: DESCRIPTION_MAX_LENGTH,
            },
          },
        ),

        to: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),

        toReference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
          TRANSFER_REFERENCE_MAX_LENGTH,
          {
            key: 'form:validation_messages.max_characters',
            values: {
              number: TRANSFER_REFERENCE_MAX_LENGTH,
            },
          },
        ),
      }),
    ),
});
