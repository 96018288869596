import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';
import { contactsAPI } from 'api/crm/contactsAPI';

// components
import { Message, IconSVG } from '@ui';
import { ReactComponent as EditIcon } from 'resources/icons/remix-icons/edit-line.svg';
import {
  default as TemplateChangePhoneNumberModal,
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Auth/EditPhoneModal';

interface IProps {
  startVerificationProcessForNewPhone: (newPhone: string) => Promise<void>;
}

const ChangePhoneNumber = ({ startVerificationProcessForNewPhone }: IProps) => {
  const { t } = useTranslation('auth');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return { phone: '' };
  }, []);

  const onSubmit = async (values: FormValuesModel) => {
    await contactsAPI.updateUserPhoneNumber(values.phone);
    await startVerificationProcessForNewPhone(values.phone);
    Message.success(
      t('phone_verification.edit.success', { phone: values.phone }),
    );
    setIsVisible(false);
  };

  return (
    <>
      <StyledIconSVG
        component={EditIcon}
        color={colorsTheme.colorWhite}
        onClick={() => setIsVisible(true)}
      />
      <TemplateChangePhoneNumberModal
        isVisible={isVisible}
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        closeCallback={() => setIsVisible(false)}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default ChangePhoneNumber;
