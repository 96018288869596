import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { darkTheme } from 'resources/theme/styled/index';
import { StateModel } from 'redux/reducers';
import { changeView } from 'redux/actions/view';
import { colorsTheme } from 'resources/theme/styled/colors';
import { getDefaultOpenedKeys } from 'helpers/commonHelpers';
import { LocalizationNamespaces } from 'constants/localization';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route, RoutePaths, routes } from 'routes/routes';
import { filterRoutes, getMainRoutes } from 'helpers/routesHelpers';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';
import { StateModel as UserAccessStateModel } from 'redux/reducers/userAccess';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';
import { ReactComponent as CloseIcon } from 'resources/icons/remix-icons/close-line.svg';
import { ReactComponent as ArrowLeft } from 'resources/icons/remix-icons/arrow-left-line.svg';
import { IconSVG, Divider, Drawer, Link, Menu, SubMenu, MenuItem } from '@ui';

interface IProps {
  isOpen: boolean;
  closeCallback: () => void;
}

const SidebarForMobile = ({ isOpen, closeCallback }: IProps) => {
  const { t } = useTranslation(LocalizationNamespaces);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const historyPush = (path: RoutePaths, state?: any) =>
    dispatch(changeView(() => navigate(path, state)));
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );

  const activeRoutes = useMemo(() => {
    function isAllowed(route: Route): boolean {
      const { metadata, access } = route;

      const addToNavigation = metadata?.addToNavigation;
      const hasValidLayout =
        !access?.layout || access?.layout === 'ApprovedClientGroup';
      const hasValidPermission =
        !access?.permission || permissions[access.permission].isAllowed;

      return !!(addToNavigation && hasValidLayout && hasValidPermission);
    }

    function isAllowedForProfile(route: Route): boolean {
      const { metadata, access } = route;

      const addToNavigation =
        metadata?.addToProfileMenu ||
        (layoutVariant &&
          ['OnboardingClientGroup', 'NoClientGroup'].includes(layoutVariant) &&
          metadata?.addToOnboardingProfileMenu);
      const hasValidLayout =
        !access?.layout || access?.layout === layoutVariant;
      const hasValidPermission =
        !access?.permission || permissions[access.permission].isAllowed;

      return !!(addToNavigation && hasValidLayout && hasValidPermission);
    }

    if (
      layoutVariant &&
      ['OnboardingClientGroup', 'NoClientGroup'].includes(layoutVariant)
    ) {
      const allowedRoutes = filterRoutes(
        getMainRoutes(routes),
        isAllowedForProfile,
      );

      return allowedRoutes.reduce<Route[]>((acc, route) => {
        if (route.children) {
          acc.push(...route.children);
        } else {
          acc.push(route);
        }

        return acc;
      }, []);
    } else {
      return filterRoutes(getMainRoutes(routes), isAllowed);
    }
  }, [permissions, location.pathname, layoutVariant]);

  // navigate to derived path and close drawer
  const navigateToPath = (path: RoutePaths) => {
    closeCallback();
    historyPush(path);
  };

  const renderNavItem = (route: Route) => {
    const title = (
      <DivAlignCenter>
        {t(route.title.key, { ns: route.title.ns })}
        {route.metadata?.appendToSidebarTitle || null}
        <RenderBadgeByRoutePath path={route.path} />
      </DivAlignCenter>
    );

    if (route.children?.length) {
      const menuClassName =
        location.pathname == route.path ? 'menu-submenu-selected' : '';

      return (
        <SubMenu
          key={route.path}
          itemKey={route.path}
          className={menuClassName}
          title={
            <div
              className={`${route.path}-submenu`}
              onClick={() => navigateToPath(route.path)}
            >
              {title}
            </div>
          }
        >
          {route.children.map(renderNavItem)}
        </SubMenu>
      );
    }

    return (
      <MenuItem key={route.path} itemKey={route.path}>
        {title}
      </MenuItem>
    );
  };

  return (
    <StyledDrawer
      open={isOpen}
      placement="left"
      closable={false}
      getContainer={false}
      onClose={closeCallback}
      width={darkTheme.leftDrawerWidth}
      style={{ position: 'fixed' }}
    >
      <IconSVG
        component={CloseIcon}
        color={colorsTheme.colorWhite}
        size="middle"
        onClick={closeCallback}
      />
      {layoutVariant == 'OnboardingClientGroup' ? (
        <>
          <LinkWrapper>
            <Link
              to={RoutePaths.OnboardingPage}
              onClick={() => closeCallback()}
            >
              <DivAlignCenter>
                <StyledIconSVG component={ArrowLeft} />
                {t('go_back_to_onboarding_btn')}
              </DivAlignCenter>
            </Link>
          </LinkWrapper>
          <StyledDivider />
        </>
      ) : null}
      <Menu
        mode="inline"
        selectedKeys={getDefaultOpenedKeys(location.pathname)}
        defaultOpenKeys={getDefaultOpenedKeys(location.pathname)}
        onSelect={({ key }: any) => navigateToPath(key)}
      >
        {activeRoutes.map(renderNavItem)}
      </Menu>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    padding: 0px;
  }
`;

const LinkWrapper = styled.div`
  padding: 0px 24px;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 0px;
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default SidebarForMobile;
