import React from 'react';

// components
import InnerContent from './InnerContent';
import { EntityIntakeFormContextProvider } from './EntityIntakeFormContext';

const EntityIntakeForm = () => {
  return (
    <EntityIntakeFormContextProvider>
      <InnerContent />
    </EntityIntakeFormContextProvider>
  );
};

export default EntityIntakeForm;
