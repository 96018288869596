import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { GraphNode } from '../../../../../../../Charts/GraphChart';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ContactModel } from 'typings/application/contact';

// components
import ManageKYC from 'components/Additional/CRM/ManageKYC';
import Identification from './Tabs/Identification';
import SharedIconWithText from 'components/Additional/SharedIconWithText';
import ApplicantInformation from './Tabs/ApplicantInformation';
import { IconSVG, TabModel, Tabs } from '@ui';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

type TabKeys = 'applicantInformation' | 'identification';

interface IProps {
  graphNode: GraphNode;
  onAddKYC: () => void;
  onAction?: (actionType: ActionKeys | null) => void;
}

const IndividualWithoutKeyRelationship = ({
  onAction,
  onAddKYC,
  graphNode,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>(
    'applicantInformation',
  );

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        key: 'applicantInformation',
        label: (
          <>
            {t('applicant_information.section_title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        children: (
          <ApplicantInformation
            isViewOnly={graphNode.sharedData}
            onAction={onAction}
            contact={graphNode.model as ContactModel}
          />
        ),
      },

      {
        key: 'identification',
        label: (
          <>
            {t('identification.title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        children: (
          <Identification
            onAction={onAction}
            contact={graphNode.model as ContactModel}
          />
        ),
      },
    ];
  }, [graphNode, onAction, t]);

  return (
    <>
      {graphNode.sharedData && (
        <SharedIconWithText text={t('application_documentation.shared_data')} />
      )}

      <ManageKYC
        onUpdate={onAddKYC}
        data={{
          nodeId: graphNode.id,
          onboardingProcessId: null,
          isOnboardingProcessRemovable:
            graphNode.metadata.isOnboardingProcessRemovable,
          isOnboardingProcessCreatable:
            graphNode.metadata.isOnboardingProcessCreatable,
        }}
      />
      <Tabs
        useChangeView
        items={tabs}
        activeKey={currentStep}
        defaultActiveKey={currentStep}
        onChange={setCurrentStep}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default IndividualWithoutKeyRelationship;
