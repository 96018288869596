import APIConfig from 'config/api';
import { AuditData } from 'typings/common';
import { APIService } from '../axiosInstance';

export type IntakeFormStepStatus = 'incomplete' | 'complete';
export type IntakeFormStatus = 'draft';
export type IntakeFormTypes = 'onboarding-organization';

export type IntakeFormFromQuery = {
  _id: string;
  hrid: string;
  state: IntakeFormStatus;
  type: IntakeFormTypes;
  completionStatuses: {
    accountUse: IntakeFormStepStatus;
    associatedIndividuals: IntakeFormStepStatus;
    basicInfo: IntakeFormStepStatus;
    complianceManagement: IntakeFormStepStatus;
    customerAndProducts: IntakeFormStepStatus;
    entityProfile: IntakeFormStepStatus;
    entityType: IntakeFormStepStatus;
    initialFunds: IntakeFormStepStatus;
    serviceProviders: IntakeFormStepStatus;
  };
  fields: {
    entityCountry?: string;
    entityTypeCategoryId?: string;
  };
  metadata: {
    countries: Record<string, string>;
    keyRelationshipTemplateInstance: { id: string; name: string };
  };
  _audit: AuditData;
};

export type CountryFromQuery = {
  id: string;
  name: string;
  alpha2Code: string;
};

export type IntakeFormFields = IntakeFormFromQuery['fields'];

const intakeFormAPI = {
  fetchIntakeFormById: (intakeFormId: string) => {
    return APIService.get<{ clientIntakeSubmission: IntakeFormFromQuery }>(
      `${APIConfig.crmApi}/client-intake-submission/${intakeFormId}`,
    ).then(({ data }) => data);
  },

  setIntakeFormFields: (intakeFormId: string, fields: IntakeFormFields) => {
    return APIService.post<IntakeFormFromQuery>(
      `${APIConfig.crmApi}/client-intake-submission/${intakeFormId}/set-fields`,
      {
        fields,
      },
    ).then(({ data }) => data);
  },
};

export { intakeFormAPI };
