import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormikHelpers } from 'formik';
import { useSignUpContext } from '../../SignUpContext';
import { VerifyEmailValidationSchema } from 'validations/profile/auth';

// components
import InnerForm from './InnerForm';
import { Form, SectionIntro } from '@ui';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';

export interface FormValuesModel {
  otp: string;
}

const EmailVerificationStep = () => {
  const { submitEmailVerificationStep } = useSignUpContext();
  const { t } = useTranslation(['auth', 'server_errors']);

  const initialValues = useMemo<FormValuesModel | null>(() => {
    return {
      otp: '',
    };
  }, []);

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
    switch (errorCode) {
      case '1201018':
        formikHelpers.setFieldError(
          'otp',
          t('1201018', { ns: 'server_errors' }),
        );
        break;

      case '1201020':
        formikHelpers.setFieldError(
          'otp',
          t('1201020', { ns: 'server_errors' }),
        );
        break;

      default:
        ErrorHandlerService.handleError(error);
        break;
    }
  };

  return (
    <>
      <SectionIntro
        hideDivider
        titleColor={colorsTheme.colorWhite}
        title={t('open_an_account_new.verify_email.title')}
      />
      <Form<FormValuesModel>
        onSubmit={submitEmailVerificationStep}
        renderForm={<InnerForm />}
        initialValues={initialValues}
        validationSchema={VerifyEmailValidationSchema}
        onSubmitError={handleOnSubmitError}
      />
    </>
  );
};

export default EmailVerificationStep;
