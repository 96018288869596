import React from 'react';

// helpers
import { Step } from '..';
import { styled } from 'styled-components';

// components
import StatusIcon from './StatusIcon';

interface IProps {
  steps: Step[];
  activeStep: string | null;
  onSelect: (key: string) => void;
}

const renderSteps = (
  steps: Step[],
  activeStep: string | null,
  onSelect: (key: string) => void,
  level = 0,
) => {
  return steps.map((step) => {
    const hasChildren = step.children && step.children.length > 0;

    return (
      <React.Fragment key={step.key}>
        <StepItem
          onClick={() => !step.disabled && onSelect(step.key)}
          $isActive={activeStep === step.key}
          $isDisabled={step.disabled}
          $indentLevel={level}
          $removePointerAffect={!!hasChildren}
        >
          <IconWrapper>
            <StatusIcon status={step.status} />
          </IconWrapper>
          {step.title}
        </StepItem>
        {hasChildren &&
          renderSteps(step.children!, activeStep, onSelect, level + 1)}
      </React.Fragment>
    );
  });
};

const Steps = ({ steps, activeStep, onSelect }: IProps) => {
  return <>{renderSteps(steps, activeStep, onSelect)}</>;
};

export default Steps;

const StepItem = styled.div<{
  $isActive: boolean;
  $isDisabled: boolean;
  $indentLevel: number;
  $removePointerAffect: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.paddingSm};
  transition: ${({ theme }) => theme.defaultTransition};
  margin-left: ${({ $indentLevel }) => `${$indentLevel * 20}px`};

  ${({ $removePointerAffect, theme }) =>
    !$removePointerAffect
      ? `cursor: pointer; &:hover { color: ${theme.colorWhite};}`
      : ''}

  ${({ $isActive, theme }) => ($isActive ? `color: ${theme.colorWhite};` : '')}

  ${({ $isDisabled }) =>
    $isDisabled
      ? `
      cursor: not-allowed;
      pointer-events: all !important;
      opacity: 0.6;
    `
      : ''}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.marginXs};
`;
