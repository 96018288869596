import React from 'react';

// helpers
import styled from 'styled-components';
import { SectionIntroProps } from './types';

// components
import { Divider, Text, Col, Row } from '@ui';

const SectionIntro: React.FC<SectionIntroProps> = ({
  title,
  titleVariant,
  description,
  hideDivider,
  appendToTitle,
  gutterTop,
  titleColor,
}) => {
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Text
            gutterBottom
            variant={titleVariant || 'h3'}
            weight="semi-bold"
            gutterTop={gutterTop}
            color={titleColor}
          >
            {title}
          </Text>
        </Col>
        <Col>{appendToTitle}</Col>
      </Row>

      {!hideDivider && <StyledDivider />}

      {description && (
        <StyledDescription gutterBottom variant="body1">
          {description}
        </StyledDescription>
      )}
    </>
  );
};

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `0 0 ${theme.marginXs} 0`};
`;

const StyledDescription = styled(Text)`
  text-transform: none;
`;

export default SectionIntro;
