import React from 'react';

// helpers
import { styled } from 'styled-components';

// components
import Steps from './Steps';
import { Card, Col, Row } from '@ui';

export type StepStatus = 'new' | 'completed';

export type Step = {
  key: string;
  title: string;
  status: StepStatus;
  disabled: boolean;
  children?: Step[];
};

interface IProps {
  steps: Step[];
  activeStep: string | null;
  children: React.ReactNode;
  onSelect: (key: string) => void;
}

const StepsCard = ({ children, steps, activeStep, onSelect }: IProps) => {
  return (
    <StyledCard>
      <StyledRow>
        <NavigationCol xl={6} lg={7} md={8} sm={6} xs={6}>
          <Steps steps={steps} activeStep={activeStep} onSelect={onSelect} />
        </NavigationCol>
        <ContentCol xl={18} lg={17} md={16} sm={18} xs={18}>
          {children}
        </ContentCol>
      </StyledRow>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  background-color: ${({ theme }) => theme.colorDarkD1};
  border-color: ${({ theme }) => theme.colorDarkL3};
  overflow: auto;

  .ant-card-body {
    padding: 0px;
    min-height: 65vh;
    width: 100%;
  }
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const NavigationCol = styled(Col)`
  height: 100%;
  background-color: ${({ theme }) => theme.colorDarkL1};
  padding: ${({ theme }) => theme.paddingXXl};
`;

const ContentCol = styled(Col)`
  height: 100%;
  border-left: ${({ theme }) => `1px solid ${theme.colorDarkL3}`};
  padding: ${({ theme }) => theme.paddingXXl};
`;

export default StepsCard;
