import { NULLABLE_STRING_VALIDATION_RULE } from 'validations/common';
import * as Yup from 'yup';

export const entityTypeStepValidationSchema = Yup.object().shape({
  countryOfIncorporation: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  entityTypeCategory: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});
