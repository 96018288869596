import React, { useEffect, useState } from 'react';

// helpers
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { StateModel } from 'redux/reducers';
import { securityAPI } from 'api/profile/securityAPI';
import { useSignUpContext } from 'modules/Auth/SignUpFormNew/SignUpContext';
import { useDispatch, useSelector } from 'react-redux';
import { setPrimaryEmail, signOut } from 'redux/actions/auth';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import {
  getAttemptsOfResendingEmailOTP,
  setAttemptsOfResendingEmailOTP,
} from 'helpers/localStorageHelpers';

// components
import OTPField from 'components/Forms/FormComponents/OTPField';
import ResendOtp from 'components/Additional/ResendOtp';
import ChangeEmail from './ChangeEmail';
import SubmitButton from 'components/Buttons/SubmitButton';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { AsyncButton, Button, Col, FormField, Row, Text } from '@ui';

const InnerForm = () => {
  const dispatch = useDispatch();
  const { skipEmailVerification } = useSignUpContext();
  const { t } = useTranslation(['common', 'auth', 'server_errors']);
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (store) => store.auth,
  );
  const [amountOfResendAttempts, setAmountOfResendAttempts] = useState(
    Number(getAttemptsOfResendingEmailOTP()),
  );
  const [timerConfig, setTimerConfig] = useState<{
    isInitialized: boolean;
    timerTillDate: Date | null;
  }>({
    isInitialized: false,
    timerTillDate: null,
  });

  const startVerificationProcess = async () => {
    const response = await securityAPI.resendOTP();
    setTimerConfig({
      isInitialized: true,
      timerTillDate: new Date(response.tempToken.aliveTo),
    });
  };

  const resendOTP = async () => {
    await startVerificationProcess();
    const newAmountOfResendingAttempts = amountOfResendAttempts + 1;
    setAmountOfResendAttempts(newAmountOfResendingAttempts);
    setAttemptsOfResendingEmailOTP(newAmountOfResendingAttempts);
  };

  useEffect(() => {
    // When user made 2 resend attempts then we don't need to show the timer
    // Because user will be able to only skip verification process
    if (amountOfResendAttempts >= 2) {
      setTimerConfig({ isInitialized: true, timerTillDate: null });
    } else {
      const emailSendDate = profileData?.expiredOtp;
      // Case when the email was not sent yet (basically it's right after sign up)
      if (!emailSendDate) {
        startVerificationProcess();
      } else {
        const difference = moment(emailSendDate).diff(moment(), 'seconds');
        // OTP already expired, resend email and start the timer
        if (difference <= 0) {
          startVerificationProcess();
        } else {
          // OTP code is still alive, just continue timer
          setTimerConfig({
            isInitialized: true,
            timerTillDate: new Date(emailSendDate),
          });
        }
      }
    }
  }, []);

  const onSignOutClick = () => {
    dispatch(signOut());
  };

  const handleEmailChange = async (newEmail: string) => {
    await startVerificationProcess();
    setAmountOfResendAttempts(0);
    setAttemptsOfResendingEmailOTP(0);
    dispatch(setPrimaryEmail(newEmail));
  };

  return (
    <LoadingWrapper loading={!timerConfig.isInitialized}>
      <DescriptionWrapper>
        <Text>{t('open_an_account_new.verify_email.description')}</Text>
        <DivAlignCenter>
          <Text variant="span">{profileData?.email}</Text>
          <ChangeEmail
            startVerificationProcessForNewEmail={handleEmailChange}
          />
        </DivAlignCenter>
      </DescriptionWrapper>

      <OTPSection>
        <FormField
          label={t('open_an_account_new.verify_email.form_fields.otp')}
          name="otp"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
        <ResendOtp
          alignTimerTextCenter={false}
          timeout={0}
          startTimerFromDate={timerConfig?.timerTillDate ?? undefined}
          successMessage={t('email_verification.resend_success_message', {
            ns: 'auth',
          })}
          resendOtpCallback={resendOTP}
          renderResendButton={
            amountOfResendAttempts < 2
              ? (resendCallback) => (
                  <StyledResendOTPButton
                    type="bordered"
                    size="large"
                    onClick={resendCallback}
                    fullWidth={false}
                  >
                    {t('otp.resend_otp_button', { ns: 'common' })}
                  </StyledResendOTPButton>
                )
              : () => (
                  <StyledResendOTPButton
                    type="bordered"
                    size="large"
                    onClick={skipEmailVerification}
                  >
                    {t('email_verification.skip_button', { ns: 'auth' })}
                  </StyledResendOTPButton>
                )
          }
        />
      </OTPSection>
      <Row gutter={[16, 16]}>
        <Col flex="150px">
          <StyledButton
            size="large"
            fullWidth
            type="bordered"
            onClick={onSignOutClick}
          >
            {t('exit', { ns: 'common' })}
          </StyledButton>
        </Col>
        <Col flex="150px">
          <StyledSubmitButton>
            {t('verify', { ns: 'common' })}
          </StyledSubmitButton>
        </Col>
      </Row>
    </LoadingWrapper>
  );
};

const StyledButton = styled(Button)`
  min-width: 0px;
`;

const StyledResendOTPButton = styled(AsyncButton)`
  width: 260px;
  min-width: 0px;
`;

const StyledSubmitButton = styled(SubmitButton)`
  min-width: 0px;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const OTPSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const DescriptionWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
