import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useNavigate } from 'react-router-dom';
import { setSignUpFlowData } from 'redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { StepKeys, useSignUpContext } from '../SignUpContext';

// components
import StepsCard from 'components/Additional/StepsCard';
import UserInfoStep from './UserInfoStep';
import AccountTypeStep from './AccountTypeStep';
import EmailVerificationStep from './EmailVerificationStep';
import PhoneVerificationStep from './PhoneVerificationStep';
import { Trans } from 'react-i18next';
import { Link, Text } from '@ui';

const SignUpFormContent = () => {
  const { t } = useTranslation('auth');
  const { steps, activeStep, setActiveStep } = useSignUpContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthorized = useSelector<StateModel>(
    (state) => state.auth.isAuthorized,
  );

  const navigateToSignin = () => {
    dispatch(setSignUpFlowData(null));
    navigate(RoutePaths.Auth_Root);
  };

  const contentNode = useMemo(() => {
    if (!activeStep) {
      return null;
    }

    switch (activeStep) {
      case 'accountType':
        return <AccountTypeStep />;

      case 'userInfo':
        return <UserInfoStep />;

      case 'emailVerification':
        return <EmailVerificationStep />;

      case 'phoneVerification':
        return <PhoneVerificationStep />;
    }
  }, [activeStep]);

  return (
    <>
      <StepsCard
        steps={steps}
        onSelect={(key) => setActiveStep(key as StepKeys)}
        activeStep={activeStep}
      >
        {contentNode}
      </StepsCard>
      {!isAuthorized ? (
        <FooterTextWrapper>
          <Text variant="body1">
            <Trans
              t={t}
              i18nKey="open_an_account.go_to_signin_link"
              components={[
                <Link key="sign-in-link" onClick={navigateToSignin} />,
              ]}
            />
          </Text>
        </FooterTextWrapper>
      ) : null}
    </>
  );
};

const FooterTextWrapper = styled.div`
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colorWhite};
  padding: ${({ theme }) => `${theme.paddingMd} 0`};
`;

export default SignUpFormContent;
