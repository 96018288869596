import * as yup from 'yup';
import { CRM_CONTACT_NAME_MAX_LENGTH, NAME_MIN_LENGTH } from 'constants/global';
import {
  EMAIL_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  PASSWORD_VALIDATION_RULE,
  PHONE_ASYNC_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
} from 'validations/common';

export const accountTypeValidationSchema = yup.object().shape({
  accountType: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:warnings.account_type_required',
  ),
});

export const userInfoValidationSchema = yup.object().shape({
  firstName: CRM_NAME_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: {
      number: NAME_MIN_LENGTH,
    },
  })
    .max(CRM_CONTACT_NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: CRM_CONTACT_NAME_MAX_LENGTH,
      },
    })
    .required('form:validation_messages.required'),

  lastName: CRM_NAME_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: {
      number: NAME_MIN_LENGTH,
    },
  })
    .max(CRM_CONTACT_NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters',
      values: {
        number: CRM_CONTACT_NAME_MAX_LENGTH,
      },
    })
    .required('form:validation_messages.required'),

  email: EMAIL_VALIDATION_RULE.required('form:validation_messages.required'),

  phone: PHONE_ASYNC_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  password: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  passwordConfirm: STRING_VALIDATION_RULE.oneOf(
    [yup.ref('password'), ''],
    'form:warnings.passwords_should_match',
  ).required('form:validation_messages.required'),

  isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  pepInformation: STRING_VALIDATION_RULE.max(1000, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: 1000,
    },
  }).when('isPEP', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
});
