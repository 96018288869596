import { fontsTheme } from './fonts';
import { colorsTheme } from './colors';

// TODO: Find some way to move all variables to one place for scss + styled components.
// IMPORTANT: Keep Styled Theme values the same as in antd theme "src/resources/theme/antd/dark-theme.scss"
export const darkTheme = {
  ...fontsTheme,
  ...colorsTheme,

  tableCardShadow:
    '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
  tableCardBackground:
    'linear-gradient(180deg,rgba(36, 57, 67, 0.95),rgba(33, 43, 55, 0.95))',

  // Sizes
  heightLg: '80px',
  heightMd: '60px',

  // Paddings
  paddingXXl: '38px',
  paddingXl: '20px',
  paddingMd: '12px',
  paddingSm: '8px',
  paddingXs: '6px',
  containerPadding: '20px',

  // Margins
  marginXXl: '60px',
  marginXl: '40px',
  marginMd: '24px',
  marginSm: '15px',
  marginXs: '10px',
  marginXXs: '6px',

  // ** Components **
  // Card
  cardBorderColor: '#42515b',

  // Drag And Drop
  dragAndDropBackground: 'rgba(0, 0, 0, 0.2)',

  // Header
  headerHeight: '80px',
  headerHeightMobile: '60px',
  headerPadding: '12px',
  headerLogoWidth: '180px',
  headerMenuFontSize: '12px',

  // Footer
  footerHeight: '64px',
  footerMargin: '0px',

  // Modal
  modalPadding: '38px',

  // Sidebar
  leftDrawerWidth: '350px',

  // Screen sizes
  /* Screen Sizes */
  hdScreen: '1280px',
  mobileScreen: '576px',
  tabletScreen: '768px',

  // Transition
  defaultTransition: '0.3s all',

  // Radius
  borderRadiusRegular: '5px',
  borderRadiusCircle: '50px',

  // Text
  letterSpacingForCodes: '1.5px',
};
