import React, { useEffect } from 'react';

// helpers
import useAppTracking from 'hooks/useAppTracking';
import useTranslation from './hooks/useTranslation';
import useTokenRefresh from 'hooks/useTokenRefresh';
import useBrowserTabTitle from './hooks/useBrowserTabTitle';
import withJWTTokenCompare from 'hoc/withJWTTokenCompare';
import { styled } from 'styled-components';
import { StateModel } from './redux/reducers';
import { initializeApp } from './redux/actions/app';
import { LocalizationNamespaces } from './constants/localization';
import { useDispatch, useSelector } from 'react-redux';

// components
import LoadingPage from './pages/AdditionalPages/LoadingPage';
import RouteRenderer from './routes/RouteRenderer';

const App = () => {
  const isAuthorized = useSelector<StateModel>(
    (state) => state.auth.isAuthorized,
  );
  const isAppInitialized = useSelector<StateModel>(
    (state) => state.app.isInitialized,
  );
  const dispatch = useDispatch();
  const { t, ready } = useTranslation(LocalizationNamespaces);

  useAppTracking();
  useTokenRefresh(!!isAppInitialized, !!isAuthorized);
  useBrowserTabTitle();

  const isApplicationLoading = useSelector<StateModel>(
    (state) =>
      !state.applications.activeApplication &&
      (state.applications.applicationsLoading ||
        state.applications.activeApplicationLoading),
  );

  useEffect(() => {
    dispatch(initializeApp());
  }, []);

  if (!isAppInitialized || isApplicationLoading || !(!!ready && !!t)) {
    return <LoadingPage />;
  }

  return (
    <StyledWrapper className="app">
      <RouteRenderer />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  overflow: hidden;
`;

export default withJWTTokenCompare()(App);
