import React, { useMemo, memo } from 'react';

// helpers
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { IAccountModel } from 'typings/onboarding/account';
import { AccountProcessingTypes } from 'enums/accounting/transfers';
import { FormikErrors, useField, useFormikContext } from 'formik';
import { ITransferTemplateShortModel } from 'typings/accounting/transfer';
import { FormValuesModel, TransactionModel } from '..';
import {
  convertAmountFromBigIntToInt,
  convertAmountFromIntToBigInt,
} from 'helpers/accountsHelpers';
import {
  transfersAPI,
  FetchTransactionBalancesPayload,
} from 'api/accounting/transfersAPI';

// components
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import AmountField from './AmountField';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import AvailableBalance from './AvailableBalance';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import BankAccountAutocomplete, {
  BankAccountAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/Accounting/BankAccountAutocomplete';
import WireTransferTemplateAutocomplete, {
  WireTransferTemplateAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/Accounting/WireTransferTemplateAutocomplete';
import {
  AutocompleteEventOption,
  DeleteButton,
  FormDatePicker,
  FormField,
  FormInput,
  Table,
  TableColumnModel,
  Text,
  Tooltip,
} from '@ui';

// -------- Memoized column values -------
const IndexCell = memo(function IndexCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  return (
    <IndexValueWrapper>
      <Text weight="semi-bold">{itemIndex + 1}</Text>
    </IndexValueWrapper>
  );
});

const FromCell = memo(function FromCell({ itemIndex }: { itemIndex: number }) {
  const [field, , helpers] = useField('transactions');
  const { setSubmitting } = useFormikContext<FormValuesModel>();

  const handleBankAccountSelect = async (
    value: string | undefined,
    option?: AutocompleteEventOption<IAccountModel>,
  ) => {
    const transactionsCopy = [...field.value];

    if (value && option?.model) {
      transactionsCopy[itemIndex] = {
        ...transactionsCopy[itemIndex],
        from: value || null,
        fromFieldLabel: option.children || '',
        currencyId: option.model.currencyId,
        currency: option.model.currencyCode,
        balance: {
          actual: option.model.balance
            ? convertAmountFromBigIntToInt(option.model.balance)
            : 0,
          // This value will be set by the value from the response
          available: null,
        },
      };
    } else {
      transactionsCopy[itemIndex] = {
        ...transactionsCopy[itemIndex],
        from: null,
        fromFieldLabel: '',
        currency: null,
        balance: null,
      };
    }

    helpers.setValue(transactionsCopy.slice());

    const formattedTransactions =
      transactionsCopy.reduce<FetchTransactionBalancesPayload>((acc, curr) => {
        if (curr.from) {
          acc.push({
            sequenceNumber: curr.customId,
            fromAccountNumber: curr.from,
            currencyId: curr.currencyId as number,
            amount: curr.amount ? convertAmountFromIntToBigInt(curr.amount) : 0,
          });
        }

        return acc;
      }, []);

    setSubmitting(true);
    const response = await transfersAPI.fetchBalancesForWireTransfers(
      formattedTransactions,
    );

    const updatedTransactions = transactionsCopy.map((transaction) => {
      const updatedTransaction = response.find(
        (responseTransaction) =>
          responseTransaction.sequenceNumber === transaction.customId,
      );

      if (updatedTransaction) {
        return {
          ...transaction,
          balance: transaction.balance
            ? {
                ...transaction.balance,
                available: convertAmountFromBigIntToInt(
                  updatedTransaction.availableBalance,
                ),
              }
            : null,
        };
      }

      return transaction;
    });

    helpers.setValue(updatedTransactions);
    setSubmitting(false);
  };

  return (
    <FormField<BankAccountAutocompleteProps>
      name={`transactions.${itemIndex}.from`}
      shouldShowErrorMessage={false}
      component={BankAccountAutocomplete}
      additionalProps={{
        filterAccountsByProcessingType: AccountProcessingTypes.Native,
        onSelect: (newValue: any, option) =>
          handleBankAccountSelect(newValue, option),
        onClear: () => handleBankAccountSelect(undefined),
      }}
    />
  );
});

const BalanceCell = memo(function BalanceCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  return <AvailableBalance fieldName={`transactions.${itemIndex}.balance`} />;
});

const PurposeCell = memo(function PurposeCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  return (
    <FormField
      name={`transactions.${itemIndex}.purpose`}
      component={FormInput}
      shouldShowErrorMessage={false}
    />
  );
});

const AmountCell = memo(function AmountCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  const { t } = useTranslation('server_errors');

  const [_, meta] = useField(`transactions.${itemIndex}.amount`);

  const hasBalanceError =
    meta.error === 'server_errors:custom_errors.balance_too_low';

  return (
    <Tooltip
      title={hasBalanceError ? t('custom_errors.balance_too_low') : null}
    >
      <div>
        <AmountField
          fieldName={`transactions.${itemIndex}.amount`}
          rowIndex={itemIndex}
        />
      </div>
    </Tooltip>
  );
});

const CurrencyCell = memo(function CurrencyCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  const [field] = useField<TransactionModel>(`transactions.${itemIndex}`);
  return <>{field.value?.currency}</>;
});

const ValueDateCell = memo(function ValueDateCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  return (
    <FormField
      shouldShowErrorMessage={false}
      name={`transactions.${itemIndex}.valueDate`}
      component={StyledDatePicker}
      additionalProps={{
        disabledDate: (current: any) =>
          current && current < moment().startOf('day'),
      }}
    />
  );
});

const FromReferenceCell = memo(function FromReferenceCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  return (
    <FormField
      name={`transactions.${itemIndex}.fromReference`}
      component={FormInput}
      shouldShowErrorMessage={false}
    />
  );
});

const ToCell = memo(function ToCell({ itemIndex }: { itemIndex: number }) {
  const [field, , helpers] = useField<TransactionModel[]>('transactions');

  const handleTransferTemplateSelect = async (
    index: number,
    value: string | undefined,
    option?: AutocompleteEventOption<ITransferTemplateShortModel>,
  ) => {
    const transactionsCopy = [...field.value];
    if (value && option?.model) {
      transactionsCopy[index] = {
        ...transactionsCopy[index],
        to: value || null,
        toFieldLabel: option.children as string,
        toReference: option.model.additionalData.recipientReference || '',
      };
    } else {
      transactionsCopy[index] = {
        ...transactionsCopy[index],
        to: null,
        toReference: '',
      };
    }

    helpers.setValue(transactionsCopy.slice());
  };

  return (
    <FormField<WireTransferTemplateAutocompleteProps>
      name={`transactions.${itemIndex}.to`}
      shouldShowErrorMessage={false}
      component={WireTransferTemplateAutocomplete}
      additionalProps={{
        onSelect: (newValue: any, option) =>
          handleTransferTemplateSelect(itemIndex, newValue, option),
      }}
    />
  );
});

const ToReferenceCell = memo(function ToReferenceCell({
  itemIndex,
}: {
  itemIndex: number;
}) {
  return (
    <FormField
      name={`transactions.${itemIndex}.toReference`}
      component={FormInput}
      shouldShowErrorMessage={false}
    />
  );
});

interface IProps {
  page: number;
  pageSize: number;
  onDelete: (index: number) => void;
  onPaginationPage: (page: number) => void;
}

const WireTransfersTable = ({
  page,
  pageSize,
  onDelete,
  onPaginationPage,
}: IProps) => {
  const { t } = useTranslation('transfers');
  const [field] = useField<TransactionModel[]>('transactions');

  const columns = useMemo<TableColumnModel[]>(() => {
    const getIndexOfItemInArray = (page: number, indexInPage: number) => {
      return (page - 1) * pageSize + indexInPage;
    };

    return [
      {
        width: 50,
        key: 'index',
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <IndexCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 285,
        key: 'from',
        title: t('international_transfer.submit_multiple_grid.from'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <FromCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 200,
        key: 'available_balance',
        title: (
          <StyledDivAlignCenter>
            {t('international_transfer.submit_multiple_grid.balance')}
            <InfoTooltip
              tooltipContent={t(
                'international_transfer.submit_multiple_grid.available_balance_info',
              )}
            />
          </StyledDivAlignCenter>
        ),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <BalanceCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 285,
        key: 'purpose',
        title: t('international_transfer.submit_multiple_grid.purpose'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <PurposeCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 200,
        key: 'amount',
        title: t('international_transfer.submit_multiple_grid.amount'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <AmountCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 200,
        key: 'currency',
        title: t('international_transfer.submit_multiple_grid.currency'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <CurrencyCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 200,
        key: 'valueDate',
        title: t('international_transfer.submit_multiple_grid.value_date'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <ValueDateCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 285,
        key: 'fromReference',
        title: t('international_transfer.submit_multiple_grid.from_reference'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <FromReferenceCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 285,
        key: 'to',
        title: t('international_transfer.submit_multiple_grid.to'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <ToCell itemIndex={itemIndex} />;
        },
      },

      {
        width: 285,
        key: 'toReference',
        title: t('international_transfer.submit_multiple_grid.to_reference'),
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return <ToReferenceCell itemIndex={itemIndex} />;
        },
      },

      {
        key: 'delete',
        render: (record: TransactionModel, options: any, index: number) => {
          const indexOfItemInArray = getIndexOfItemInArray(page, index);
          return (
            <HideIfDisabledForm>
              <DeleteButton onClick={() => onDelete(indexOfItemInArray)} />
            </HideIfDisabledForm>
          );
        },
      },
    ];
  }, [page, onDelete]);

  return (
    <Table
      bordered
      columns={columns}
      data={field.value}
      current={page}
      onPaginationChange={onPaginationPage}
      pageSize={pageSize}
    />
  );
};

const StyledDivAlignCenter = styled(DivAlignCenter)`
  gap: ${({ theme }) => theme.marginXXs};
`;

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const IndexValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default WireTransfersTable;
