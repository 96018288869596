import useTranslation from './useTranslation';
import { routes } from 'routes/routes';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { findRouteByPath } from 'helpers/routesHelpers';
import { LocalizationNamespaces } from 'constants/localization';

const useBrowserTabTitle = () => {
  const location = useLocation();
  const { t, ready } = useTranslation(LocalizationNamespaces);

  useEffect(() => {
    if (!ready) {
      document.title = 'Loading...';
    } else {
      const route = findRouteByPath(routes, location.pathname);
      if (!route) {
        document.title = `${t('not_found_page_title', { ns: 'common' })} | Tenet`;
      } else {
        document.title = `${t(route.title.key, { ns: route.title.ns })} | Tenet`;
      }
    }
  }, [ready, location.pathname]);
};

export default useBrowserTabTitle;
