import React, { memo } from 'react';

// helpers
import { isMessageNew } from 'helpers/messagesHelpers';
import { MessageModel } from 'typings/messaging/messages';

// components
import Comment from 'components/Additional/Comment';

interface IProps {
  message: MessageModel;
  currentUserId: string;
}

const ThreadMessage = memo(({ message, currentUserId }: IProps) => {
  const isUnread = isMessageNew(message, currentUserId);

  return <Comment isUnread={isUnread} message={message} />;
});

export default ThreadMessage;
