import React, { useCallback, useEffect, useRef, useState } from 'react';

// helpers
import i18n from 'i18n';
import styled from 'styled-components';
import G6, { Graph, IGroup, IShape, Util } from '@antv/g6';
import { IEntity } from 'typings/application/entity';
import { Relationship } from 'typings/relationshipsGraph';
import { ContactModel } from '../../../typings/application/contact';
import { createDOM, modifyCSS } from '@antv/util';
import { RelationshipTemplate } from '../../../typings/application/relationship-template';
import { OnboardingStatusModel } from '../../../typings/onboarding/onboarding';

// constants
import { darkTheme } from 'resources/theme/styled';
import { colorsTheme } from 'resources/theme/styled/colors';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
  OnboardingEntryTypes,
} from 'enums/onboarding/crm';

// components
import { IconSVG, IconButton, Col, Row } from '@ui';
import {
  default as NodeActionsComponent,
  EllipsisOptionModel,
} from './NodeActions';

// icons
import PersonIcon from 'resources/icons/structure-chart-icons/user.svg';
import BuildingIcon from 'resources/icons/structure-chart-icons/building.svg';
import EllipsisIcon from 'resources/icons/structure-chart-icons/more.svg';
import SharedDataIcon from 'resources/icons/shared-data.svg';
import ApplicantIcon from 'resources/icons/structure-chart-icons/user-star-line.svg';
import DocumentReviewIcon from 'resources/icons/structure-chart-icons/time.svg';
import RejectedDocumentIcon from 'resources/icons/rejected-document.svg';
import SubmittedDocumentIcon from 'resources/icons/submitted-document.svg';
import NotSubmittedDocumentIcon from 'resources/icons/not-submitted-document.svg';
import { ReactComponent as PlusIcon } from 'resources/icons/remix-icons/add-line.svg';
import { ReactComponent as MinusIcon } from 'resources/icons/remix-icons/subtract-line.svg';

// --- Sizes ---
const ICON_SIZE = 27;
const NODE_WIDTH = 230;
const NODE_HEIGHT = 60;

// --- Names ---
const CUSTOM_RECT_NODE = 'graph_node';
const CUSTOM_SINGLE_EDGE = 'custom-single-line-edge';
const CUSTOM_QUADRATIC_EDGE = 'custom-quadratic-edge';

type GraphConfig = {
  fontColor: string;
  fontFamily: string;
  fontColorGray: string;
  borderColor: string;
  activeBorderColor: string;
  bgColor: string;

  nodeFontSize: number;
  nodeFontWeight: number;
  nodeLabelColor: string;
  nodeBackgroundColor: string;
};

export enum NodeReviewStatuses {
  Submitted = 1,
  Rejected,
  NotSubmitted,
  InReview,
}

export enum NodeStatuses {
  NoChanges = 1,
  New,
  Updated,
  Deleted,
}

export enum EdgeStatuses {
  New = 1,
  Deleted,
  Updated,
  InReview,
}

enum NodeActions {
  AddNode = 'add_node_button',
  EllipsisButton = 'ellipsis_menu',
}

function fittingString(str: string, maxWidth: number, fontSize: number) {
  const ellipsis = '...';
  const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
  let currentWidth = 0;
  let res = str;
  const pattern = new RegExp('[\u4E00-\u9FA5]+'); // distinguish the Chinese characters and letters
  str.split('').forEach((letter, i) => {
    if (currentWidth > maxWidth - ellipsisLength) return;
    if (pattern.test(letter)) {
      // Chinese characters
      currentWidth += fontSize;
    } else {
      // get the width of single letter according to the fontSize
      currentWidth += G6.Util.getLetterWidth(letter, fontSize);
    }
    if (currentWidth > maxWidth - ellipsisLength) {
      res = `${str.substr(0, i)}${ellipsis}`;
    }
  });
  return res;
}

export type GraphData = {
  nodes: GraphNode[];
  edges: GraphEdge[];
};

export type GraphNode = {
  id: string;
  label: string;
  model: ContactModel | IEntity;
  entryType: OnboardingEntryTypes;
  onboardingProcess: OnboardingStatusModel | null;
  typeLabel: string;

  allInformationFilled?: boolean;
  sharedData?: boolean;
  isApplicantContact: boolean;
  isApplicantOrganization: boolean;
  hasOwnershipRelationships: boolean;
  metadata: {
    isOnboardingProcessCreatable: boolean;
    isOnboardingProcessRemovable: boolean;
    handoffCanBeSent: boolean;
  };
  layer?: number;
  status?: NodeStatuses;
  reviewStatus?: NodeReviewStatuses;

  ellipsisMenuOptions?: EllipsisOptionModel[];
  onlineAccess?: {
    clientGroupUserId: string;
    adminPermissionType: AdministrationPermissionTypes;
    permissionType: AccountUserPermissionTypes;
  };

  actions?: {
    onAdd?: () => void;
    onClick?: () => void;
  };
};

export type GraphEdge = {
  source: string;
  target: string;
  label: string;
  relationship: Relationship;
  relationshipTemplate: RelationshipTemplate;

  status?: EdgeStatuses;

  actions?: {
    onClick?: () => void;
  };
};

interface IProps {
  data: GraphData;
  displaySharedDataStatusIcon?: boolean;
  displayApplicantIcon?: boolean;
  onEllipsisOptionClick?: (key: string, node: GraphNode) => void;
}

function getNodeBorderColorByStatus(status: NodeStatuses): string {
  let result = '';

  switch (status) {
    case NodeStatuses.New:
      result = darkTheme.colorPrimary;
      break;

    case NodeStatuses.NoChanges:
      result = darkTheme.colorLightD1;
      break;

    case NodeStatuses.Updated:
      result = darkTheme.colorWarning;
      break;

    case NodeStatuses.Deleted:
      result = darkTheme.colorError;
      break;
  }

  return result;
}

function getNodeConfig(node?: GraphNode): GraphConfig {
  const borderColor =
    node && node.status
      ? getNodeBorderColorByStatus(node.status)
      : darkTheme.colorLightD1;

  const config: GraphConfig = {
    borderColor,
    fontColor: darkTheme.colorWhite,
    fontColorGray: darkTheme.colorInfo,
    activeBorderColor: darkTheme.colorInfo,
    bgColor: darkTheme.colorDarkL2,
    fontFamily: 'EurostileLT',
    nodeFontSize: 14,
    nodeFontWeight: 500,
    nodeLabelColor: darkTheme.colorWhite,
    nodeBackgroundColor: darkTheme.colorDark,
  };

  return config;
}

function getNodeIcon(node?: any): string {
  switch (node.entryType) {
    case OnboardingEntryTypes.Organization:
      return BuildingIcon;

    case OnboardingEntryTypes.Contact:
      return PersonIcon;

    default:
      return '';
  }
}

function getNodeColor(node?: any): string {
  switch (node.entryType) {
    case OnboardingEntryTypes.Organization:
      return darkTheme.colorInfo;

    case OnboardingEntryTypes.Contact:
      return darkTheme.colorWarning;

    default:
      return '';
  }
}

function getNodeStatusIcon(status: NodeReviewStatuses): React.ReactNode | null {
  switch (status) {
    case NodeReviewStatuses.Rejected:
      return RejectedDocumentIcon;

    case NodeReviewStatuses.Submitted:
      return SubmittedDocumentIcon;

    case NodeReviewStatuses.NotSubmitted:
      return NotSubmittedDocumentIcon;

    case NodeReviewStatuses.InReview:
      return DocumentReviewIcon;

    default:
      return null;
  }
}

function getEdgeStrokeColor(status?: EdgeStatuses): string {
  switch (status) {
    case EdgeStatuses.New:
      return darkTheme.colorPrimary;

    case EdgeStatuses.Deleted:
      return darkTheme.colorError;

    case EdgeStatuses.InReview:
    case EdgeStatuses.Updated:
      return darkTheme.colorWarning;

    default:
      return darkTheme.colorLightD1;
  }
}

function createGraphNode(
  group: any,
  config: GraphConfig,
  w: number,
  h: number,
) {
  const container = group.addShape('rect', {
    attrs: {
      x: 0,
      y: 0,
      width: w,
      height: h,
      fill: config.bgColor,
      stroke: config.borderColor,
      cursor: 'pointer',
      draggable: true,
    },
    name: 'rect-shape',
  });

  return container;
}

G6.registerEdge(
  CUSTOM_SINGLE_EDGE,
  {
    setState: (name, value, item) => {
      if (item) {
        const config = getNodeConfig();

        switch (name) {
          case 'focus':
            {
              const model = item.getModel() as GraphEdge;
              const group = item.get('group');
              const shape = group.get('children')[0];

              if (value) {
                shape.attr('stroke', config.activeBorderColor);
                const length = shape.getTotalLength();
                shape.animate(
                  (ratio: any) => {
                    const startLen = ratio * length;
                    const cfg = {
                      lineDash: [startLen, length - startLen],
                    };
                    return cfg;
                  },
                  {
                    repeat: true,
                    duration: 2000,
                  },
                );
              } else {
                const lineColor = getEdgeStrokeColor(model.status);

                shape.stopAnimate();
                shape.attr('stroke', lineColor);
              }
            }
            break;

          case 'hover':
            {
              const group = item.get('group');
              const shape = group.get('children')[0];

              if (value) {
                shape.attr('shadowColor', config.activeBorderColor);
              } else {
                shape.attr('shadowColor', '');
              }
            }
            break;
        }
      }
    },
  },
  'single-edge',
);

G6.registerEdge(
  CUSTOM_QUADRATIC_EDGE,
  {
    setState: (name, value, item) => {
      if (item) {
        const config = getNodeConfig();

        switch (name) {
          case 'focus':
            {
              const model = item.getModel() as GraphEdge;
              const group = item.get('group');
              const shape = group.get('children')[0];

              if (value) {
                shape.attr('stroke', config.activeBorderColor);
                const length = shape.getTotalLength();
                shape.animate(
                  (ratio: any) => {
                    const startLen = ratio * length;
                    const cfg = {
                      lineDash: [startLen, length - startLen],
                    };
                    return cfg;
                  },
                  {
                    repeat: true,
                    duration: 2000,
                  },
                );
              } else {
                const lineColor = getEdgeStrokeColor(model.status);

                shape.stopAnimate();
                shape.attr('stroke', lineColor);
              }
            }
            break;

          case 'hover':
            {
              const group = item.get('group');
              const shape = group.get('children')[0];

              if (value) {
                shape.attr('shadowColor', config.activeBorderColor);
              } else {
                shape.attr('shadowColor', '');
              }
            }
            break;
        }
      }
    },
  },
  'quadratic',
);

G6.registerNode(
  CUSTOM_RECT_NODE,
  {
    draw: (cfg?: any, group?: IGroup): IShape => {
      if (!cfg || !group) {
        return {} as IShape;
      }

      const config = getNodeConfig(cfg);
      const container = createGraphNode(group, config, NODE_WIDTH, NODE_HEIGHT);
      const sectionColor = getNodeColor(cfg);

      group.addShape('rect', {
        attrs: {
          x: 0,
          y: 0,
          width: NODE_HEIGHT,
          height: NODE_HEIGHT,
          fill: sectionColor,
          stroke: sectionColor,
        },
        name: 'icon-rect',
      });

      /* Actions button */
      if (cfg.ellipsisMenuOptions && cfg.ellipsisMenuOptions.length)
        group.addShape('image', {
          attrs: {
            img: EllipsisIcon,
            width: 15,
            height: 15,
            x: 205,
            y: 5,
            cursor: 'pointer',
          },
          name: NodeActions.EllipsisButton,
        });

      /* Title */
      group.addShape('text', {
        attrs: {
          text: cfg.label,
          x: NODE_HEIGHT + 10,
          y: 20,
          fontSize: config.nodeFontSize,
          fontWeight: config.nodeFontWeight,
          textAlign: 'left',
          textBaseline: 'middle',
          fill: config.fontColor,
          cursor: 'pointer',
        },
        name: 'name-text-shape',
      });

      /* Node type label */
      group.addShape('text', {
        attrs: {
          text: cfg.typeLabel,
          x: NODE_HEIGHT + 10,
          y: 40,
          fontSize: config.nodeFontSize,
          textAlign: 'left',
          textBaseline: 'middle',
          fill: config.fontColorGray,
          cursor: 'pointer',
        },
        name: 'name-text-shape',
      });

      /* Icon */
      const img = getNodeIcon(cfg);
      group.addShape('image', {
        attrs: {
          img,
          width: ICON_SIZE,
          height: ICON_SIZE,
          x: NODE_HEIGHT / 2 - ICON_SIZE / 2,
          y: NODE_HEIGHT / 2 - ICON_SIZE / 2,
        },
        name: 'image-shape',
      });

      /* State Icon */
      if (cfg.reviewStatus) {
        const statusIcon = getNodeStatusIcon(cfg.reviewStatus);

        group.addShape('image', {
          attrs: {
            img: statusIcon,
            width: 13,
            height: 13,
            x: 210,
            y: 40,
          },
          name: 'status-image-shape',
        });
      }

      /* Shared data icon */
      if (cfg.sharedData) {
        group.addShape('image', {
          attrs: {
            img: SharedDataIcon,
            width: 13,
            height: 13,
            x: 210,
            y: 6,
          },
          name: 'shared-data-icon-shape',
        });
      }

      /* Shared data icon */
      if (cfg.isApplicantContact) {
        if (cfg.sharedData) {
          group.addShape('image', {
            attrs: {
              img: ApplicantIcon,
              width: 13,
              height: 13,
              x: 195,
              y: 6,
            },
            name: 'applicant-icon-shape',
          });
        } else {
          group.addShape('image', {
            attrs: {
              img: ApplicantIcon,
              width: 13,
              height: 13,
              x: 210,
              y: 6,
            },
            name: 'applicant-icon-shape',
          });
        }
      }

      return container;
    },

    setState: (name, value, item) => {
      if (item) {
        const model = item.getModel() as GraphNode;
        const config = getNodeConfig(model);
        const keyShape = item.getKeyShape();
        const currentStates = item.getStates();

        switch (name) {
          case 'hover':
            {
              if (currentStates.includes('focus')) {
                return;
              }

              if (value) {
                keyShape.attr('stroke', config.activeBorderColor);
              } else {
                keyShape.attr('stroke', config.borderColor);
              }
            }
            break;

          case 'focus':
            {
              if (value) {
                keyShape.attr('stroke', config.activeBorderColor);
              } else {
                keyShape.attr('stroke', config.borderColor);
              }
            }
            break;
        }
      }
    },

    getAnchorPoints() {
      return [
        [0, 0.125],
        [0, 0.25],
        [0, 0.375],
        [0, 0.5],
        [0, 0.625],
        [0, 0.75],
        [0, 0.875],
        [1, 0.125],
        [1, 0.25],
        [1, 0.375],
        [1, 0.5],
        [1, 0.625],
        [1, 0.75],
        [1, 0.875],
        [0.125, 0],
        [0.25, 0],
        [0.375, 0],
        [0.5, 0],
        [0.625, 0],
        [0.75, 0],
        [0.875, 0],
        [0.125, 1],
        [0.25, 1],
        [0.375, 1],
        [0.5, 1],
        [0.625, 1],
        [0.75, 1],
        [0.875, 1],
      ];
    },
  },
  'rect',
);

G6.registerBehavior('tooltip-on-icon', {
  getEvents() {
    return {
      'shared-data-icon-shape:mouseenter': 'onMouseEnter',
      'shared-data-icon-shape:mousemove': 'onMouseMove',
      'shared-data-icon-shape:mouseleave': 'onMouseLeave',
      'applicant-icon-shape:mouseenter': 'onMouseEnter',
      'applicant-icon-shape:mousemove': 'onMouseMove',
      'applicant-icon-shape:mouseleave': 'onMouseLeave',
    };
  },
  onMouseEnter(e: any) {
    const that = this as any;

    if (!that.shouldBegin(e)) {
      return;
    }

    that.showTooltip(e, 'icon');
    that.currentTarget = e.item;
  },

  onMouseMove(e: any) {
    const that = this as any;

    if (!that.shouldUpdate(e)) {
      that.hideTooltip();
      return;
    }

    if (!that.currentTarget || e.item !== that.currentTarget) {
      return;
    }

    that.updatePosition(e);
  },

  onMouseLeave(e: any) {
    const that = this as any;

    if (!that.shouldEnd(e)) {
      return;
    }

    that.hideTooltip();
    that.currentTarget = null;
  },

  showTooltip(e: any) {
    const that = this as any;

    if (!e.item) {
      return;
    }

    let container = that.container;

    if (!container) {
      container = that._createTooltip(that.graph.get('canvas'));
      that.container = container;
    }

    container.innerHTML = that._getText(e);
    that.updatePosition(e);
    modifyCSS(that.container, { visibility: 'visible' });
  },

  hideTooltip() {
    const that = this as any;
    modifyCSS(that.container, {
      visibility: 'hidden',
    });
  },

  updatePosition(e: any) {
    const that = this as any;
    let left = '';
    let top = '';
    const { x, y } = that.graph.getCanvasByPoint(e.x, e.y);
    left = `${x + 20}px`;
    top = `${y + 10}px`;
    modifyCSS(that.container, { left, top, visibility: 'visible' });
  },

  _createTooltip(canvas: any) {
    const that = this as any;
    const el = canvas.get('el');
    el.style.position = 'relative';
    const container = createDOM(
      '<div class="g6-tooltip g6-icon-tooltip"></div>',
    );
    el.parentNode.appendChild(container);
    modifyCSS(container, {
      position: 'absolute',
      visibility: 'visible',
    });
    that.width = canvas.get('width');
    that.height = canvas.get('height');
    that.container = container;
    return container;
  },

  _getText(e: any) {
    if (e.name.includes('shared-data-icon-shape')) {
      return i18n.t('structure.chart_tooltips.add', { ns: 'onboarding' });
    }

    if (e.name.includes('applicant-icon-shape')) {
      return i18n.t('structure.chart_tooltips.applicant', { ns: 'onboarding' });
    }
  },
});

const GraphChart = ({
  data,
  displaySharedDataStatusIcon,
  displayApplicantIcon,
  onEllipsisOptionClick,
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const graph = useRef<any>(null);
  const [actionsMenuProps, setActionsMenuProps] = useState<{
    node: GraphNode;
    x: number;
    y: number;
  } | null>(null);

  const clearFocusItemState = (graph: Graph | null) => {
    if (!graph) {
      return;
    }

    clearFocusNodeState(graph);
    clearFocusEdgeState(graph);
  };

  const clearFocusNodeState = (graph: Graph) => {
    const focusNodes = graph.findAllByState('node', 'focus');
    focusNodes.forEach((node) => {
      graph.setItemState(node, 'focus', false);
    });
  };

  const clearFocusEdgeState = (graph: Graph) => {
    const focusEdges = graph.findAllByState('edge', 'focus');
    focusEdges.forEach((edge) => {
      graph.setItemState(edge, 'focus', false);
    });
  };

  const formatGraphData = (data: GraphData): GraphData => {
    const result = { ...data };
    const maxNodeLabelWidth = NODE_WIDTH - NODE_HEIGHT - 40;

    // Modify the label in the data
    result.nodes.forEach(function (node) {
      node.label = fittingString(node.label, maxNodeLabelWidth, 14);
      node.typeLabel = fittingString(
        node.typeLabel || '',
        maxNodeLabelWidth,
        14,
      );
      node.sharedData = displaySharedDataStatusIcon && node.sharedData;
      node.isApplicantContact = !!(
        displayApplicantIcon && node.isApplicantContact
      );
    });

    result.edges.forEach(function (edge: any) {
      const edgeColor = getEdgeStrokeColor(edge.status);

      edge.label = fittingString(edge.label, 170, 12);
      edge.style = {
        ...edge.style,
        fontFamily: 'EurostileLT',
        stroke: edgeColor,
        endArrow: {
          fill: edgeColor,
        },
      };
    });

    return result;
  };

  const handleActionsOpen = useCallback(
    (node: GraphNode, x: number, y: number) => {
      setActionsMenuProps({ node, x, y });
    },
    [],
  );

  const handleActionsClose = useCallback(() => {
    setActionsMenuProps(null);
  }, []);

  useEffect(() => {
    function initGraph(data: GraphData) {
      const height = ref.current?.scrollHeight || 0;
      const width = ref.current?.scrollWidth || 0;
      const config = getNodeConfig();

      const newGraph = new G6.Graph({
        container: ref.current as HTMLElement,
        width,
        // remove height of the top-bar + margin that we have in graph component
        height: height - (32 + 15),

        fitCenter: true,
        fitView: true,
        fitViewPadding: [20, 20, 20, 20],

        layout: {
          type: 'dagre',
          rankdir: 'TB',
          nodesep: 70,
          ranksep: 70,
          align: 'UL',

          nodesepFunc: (node: GraphNode) => {
            if (node.isApplicantOrganization) {
              return 140;
            }

            return 70;
          },

          ranksepFunc: (node: GraphNode) => {
            if (node.isApplicantOrganization) {
              return 140;
            }

            return 70;
          },
        },

        modes: {
          default: ['drag-canvas', 'drag-node', 'tooltip-on-icon'],
        },

        defaultNode: {
          type: CUSTOM_RECT_NODE,
        },

        defaultEdge: {
          labelCfg: {
            position: 'center',
            autoRotate: true,
            style: {
              cursor: 'pointer',
              fill: config.fontColor,
              fontSize: 12,
              background: {
                fill: darkTheme.colorDark,
                padding: [5, 15, 5, 15],
                radius: 2,
              },
            },
          },

          style: {
            cursor: 'pointer',
            stroke: config.borderColor,
            lineWidth: 1.5,
            lineAppendWidth: 7,
            shadowBlur: 10,
            endArrow: {
              fill: config.borderColor,
              path: G6.Arrow.triangle(),
            },
          },
        },
      });

      const graphData = formatGraphData(data);

      Util.processParallelEdges(
        graphData.edges,
        23,
        CUSTOM_QUADRATIC_EDGE,
        CUSTOM_SINGLE_EDGE,
      );

      newGraph.data({
        nodes: [...graphData.nodes],
        edges: [...graphData.edges],
      } as any);

      newGraph.render();

      newGraph.on('node:mouseenter', (ev: any) => {
        const node = ev.item;
        newGraph?.setItemState(node, 'hover', true);
      });

      newGraph.on('node:mouseleave', (ev: any) => {
        const node = ev.item;
        newGraph?.setItemState(node, 'hover', false);
      });

      newGraph.on('node:click', (event: any) => {
        const { item, target } = event;
        const targetName = target.get('name');
        const model = item.getModel();

        switch (targetName) {
          case NodeActions.AddNode:
            {
              if (model?.actions?.onAdd) {
                model?.actions?.onAdd();
              }
            }
            break;

          case NodeActions.EllipsisButton:
            {
              handleActionsOpen(model, event.x || 0, event.y || 0);
            }
            break;

          default: {
            if (model?.actions?.onClick) {
              model?.actions?.onClick();
            }

            clearFocusItemState(newGraph);

            newGraph?.setItemState(item, 'focus', true);
            const relatedEdges = item.getEdges();
            relatedEdges.forEach((edge: any) => {
              newGraph?.setItemState(edge, 'focus', true);
            });
          }
        }
      });

      newGraph.on('edge:click', (evt: any) => {
        const edge = evt.item;
        newGraph?.setItemState(edge, 'focus', true);

        const edgeModel = evt.item.getModel();

        if (edgeModel?.actions?.onClick) {
          edgeModel.actions.onClick();
        }
      });

      newGraph.on('edge:mouseenter', (ev: any) => {
        const edge = ev.item;
        newGraph?.setItemState(edge, 'hover', true);
      });

      newGraph.on('edge:mouseleave', (ev: any) => {
        const edge = ev.item;
        newGraph?.setItemState(edge, 'hover', false);
      });

      newGraph.on('canvas:click', () => {
        clearFocusItemState(newGraph);
      });

      return newGraph;
    }

    if (!graph.current) {
      const newGraph = initGraph(data);
      graph.current = newGraph;
    }
  }, []);

  // Update graph data
  useEffect(() => {
    if (graph.current) {
      graph.current.save();
      const graphData = formatGraphData(data);
      Util.processParallelEdges(
        graphData.edges,
        23,
        CUSTOM_QUADRATIC_EDGE,
        CUSTOM_SINGLE_EDGE,
      );
      graph.current.read(graphData);
    }
  }, [data]);

  const handleZoomOut = () => {
    if (!graph || graph.current.destroyed) {
      return;
    }

    const current = graph.current.getZoom();
    const canvas = graph.current.get('canvas');
    const point = canvas.getPointByClient(
      canvas.get('width') / 2,
      canvas.get('height') / 2,
    );
    const pixelRatio = canvas.get('pixelRatio') || 1;
    const ratio = 1 - 0.05 * 5;

    if (ratio * current < 0.3) {
      return;
    }
    graph.current.zoom(ratio, {
      x: point.x / pixelRatio,
      y: point.y / pixelRatio,
    });
  };

  const handleZoomIn = () => {
    if (!graph || graph.current.destroyed) {
      return;
    }

    const current = graph.current.getZoom();
    const canvas = graph.current.get('canvas');
    const point = canvas.getPointByClient(
      canvas.get('width') / 2,
      canvas.get('height') / 2,
    );
    const pixelRatio = canvas.get('pixelRatio') || 1;
    const ratio = 1 + 0.05 * 5;
    if (ratio * current > 5) {
      return;
    }
    graph.current.zoom(ratio, {
      x: point.x / pixelRatio,
      y: point.y / pixelRatio,
    });
  };

  return (
    <StyledGraphWrapper>
      <StyledRow gutter={[8, 8]} justify="end">
        <Col>
          <IconButton
            type="bordered"
            icon={
              <IconSVG component={PlusIcon} color={colorsTheme.colorWhite} />
            }
            onClick={handleZoomIn}
          />
        </Col>
        <Col>
          <IconButton
            type="bordered"
            icon={
              <IconSVG component={MinusIcon} color={colorsTheme.colorWhite} />
            }
            onClick={handleZoomOut}
          />
        </Col>
      </StyledRow>

      <GraphContainer ref={ref} />

      {actionsMenuProps && (
        <NodeActionsComponent
          x={actionsMenuProps.x}
          y={actionsMenuProps.y}
          node={actionsMenuProps.node}
          graph={graph.current}
          onClose={handleActionsClose}
          onAction={(key) =>
            onEllipsisOptionClick &&
            onEllipsisOptionClick(key, actionsMenuProps.node)
          }
        />
      )}
    </StyledGraphWrapper>
  );
};

const StyledGraphWrapper = styled.div`
  width: 100%;
  height: 60vh;
  min-height: 600px;
  position: relative;
  background: ${({ theme }) => theme.colorDarkD1};
  font-family: var(--antd-font-family) !important;

  .g6-tooltip {
    color: ${({ theme }) => theme.colorWhite};
    padding: 6px 8px;
    min-height: 32px;
    border: 1px solid rgba(198, 204, 207, 0.5);
    background: linear-gradient(
      180deg,
      rgba(36, 57, 67, 0.95) 0%,
      rgba(33, 43, 55, 0.95) 100%
    );
    box-shadow:
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
`;

const StyledRow = styled(Row)`
  padding: ${({ theme }) => theme.paddingSm};
`;

const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export default GraphChart;
