import React from 'react';

// components
import SignUpFormNew from 'modules/Auth/SignUpFormNew';

const SignUpPageNew = () => {
  return <SignUpFormNew />;
};

export default SignUpPageNew;
