import { combineReducers } from 'redux';
import { BrowserHistory, State } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';

import app from './app';
import auth from './auth';
import view from './view';
import upload from './upload';
import settings from './settings';
import documents from './documents';
import userAccess from './userAccess';
import intakeForm from './intakeForm';
import applications from './applications';
import notifications from './notifications';
import personalDetails from './personalDetails';

// models
import { StateModel as AppStateModel } from './app';
import { StateModel as AuthStateModel } from './auth';
import { StateModel as SettingsStateModel } from './settings';
import { StateModel as UploadStateModel } from './upload';
import { StateModel as DocumentsStateModel } from './documents';
import { StateModel as IntakeFormStateModel } from './intakeForm';
import { StateModel as ViewStateModel } from './view';
import { StateModel as ApplicationsStateModel } from './applications';
import { StateModel as NotificationsStateModel } from './notifications';
import { StateModel as UserAccessStateModel } from './userAccess';
import { StateModel as PersonalDetailsStateModel } from './personalDetails';

export interface StateModel {
  router: RouterState<State>;
  app: AppStateModel;
  auth: AuthStateModel;
  settings: SettingsStateModel;
  upload: UploadStateModel;
  documents: DocumentsStateModel;
  view: ViewStateModel;
  intakeForm: IntakeFormStateModel;
  applications: ApplicationsStateModel;
  notifications: NotificationsStateModel;
  userAccess: UserAccessStateModel;
  personalDetails: PersonalDetailsStateModel;
}

export default (history: BrowserHistory) => {
  return combineReducers({
    router: connectRouter(history),
    app,
    auth,
    settings,
    upload,
    documents,
    intakeForm,
    view,
    applications,
    notifications,
    userAccess,
    personalDetails,
  });
};
