import React from 'react';

// helpers
import styled from 'styled-components';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';

// components
import { IconSVG, Col, Row } from '@ui';
import ProfileActions from './ProfileActions';
import NavigationMenu from './NavigationMenu';
import { ReactComponent as MenuIcon } from 'resources/icons/remix-icons/menu-line.svg';
import { ReactComponent as PrimaryLogo } from 'resources/app-logos/primary-logo.svg';

interface IProps {
  isMobileResolution: boolean;
  openDrawerCallback: () => void;
}

const HeaderBar = ({ isMobileResolution, openDrawerCallback }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );
  const canRenderNavigationMenu =
    layoutVariant === 'ApprovedClientGroup' &&
    !isMobileResolution &&
    location.pathname !== RoutePaths.VerificationPage;
  const canRenderMenuIcon =
    isMobileResolution &&
    ![
      RoutePaths.VerificationPage,
      RoutePaths.SelectApplicationPage,
      RoutePaths.OnboardingPage,
      RoutePaths.IntakeFormPage,
    ].includes(location.pathname as RoutePaths);

  return (
    <StyledContentWrapper>
      <StyledRow align="middle" justify="space-between">
        {canRenderMenuIcon && (
          <StyledSidebarSwitchCol>
            <IconSVG
              component={MenuIcon}
              color={colorsTheme.colorWhite}
              size="middle"
              onClick={openDrawerCallback}
            />
          </StyledSidebarSwitchCol>
        )}
        <HeaderLogoCol flex="1 1">
          <StyledLogo onClick={() => navigate(RoutePaths.Root)} />
        </HeaderLogoCol>
        {canRenderNavigationMenu ? (
          <Col>
            <NavigationMenu />
          </Col>
        ) : null}
        <Col>
          <ProfileActions />
        </Col>
      </StyledRow>
    </StyledContentWrapper>
  );
};

const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  height: ${({ theme }) => theme.headerHeight};
  min-height: ${({ theme }) => theme.headerHeight};
  max-height: ${({ theme }) => theme.headerHeight};

  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    height: ${({ theme }) => theme.headerHeight};
    min-height: ${({ theme }) => theme.headerHeightMobile};
    max-height: ${({ theme }) => theme.headerHeightMobile};
  }

  position: fixed;
  top: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.colorDarkD1};
  padding: 0 ${({ theme }) => theme.containerPadding};

  .ant-menu {
    font-size: ${({ theme }) => theme.headerMenuFontSize};
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledLogo = styled(PrimaryLogo)`
  width: ${({ theme }) => theme.headerLogoWidth};
  cursor: pointer;
`;

const HeaderLogoCol = styled(Col)`
  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    display: flex;
    justify-content: center;
  }
`;

const StyledSidebarSwitchCol = styled(Col)`
  width: 45px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default HeaderBar;
