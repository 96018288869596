import { ClientIntakeForm } from 'typings/profile/profile';

// Actions
export enum ActionType {
  SET_INTAKE_FORM = 'intakeForm/SET_INTAKE_FORM',
}

// Action creators
export const setIntakeForm = (intakeForm: ClientIntakeForm | null) => ({
  type: ActionType.SET_INTAKE_FORM,
  payload: intakeForm,
});
