import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import Identification from './Tabs/Identification';
import ApplicantInformation from './Tabs/ApplicantInformation';
import { TabModel, Tabs } from '@ui';

type TabKeys = 'applicantInformation' | 'identification';

interface IProps {
  isEditable: boolean;
  onDiscard: () => void;
}

const ViewContact = ({ onDiscard, isEditable }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>(
    'applicantInformation',
  );

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        key: 'applicantInformation',
        label: t('applicant_information.general.title'),
        children: (
          <ApplicantInformation onDiscard={onDiscard} isEditable={isEditable} />
        ),
      },

      {
        key: 'identification',
        label: t('identification.title'),
        children: <Identification />,
      },
    ];
  }, [onDiscard, isEditable]);

  return (
    <Tabs
      useChangeView
      items={tabs}
      activeKey={currentStep}
      defaultActiveKey={currentStep}
      onChange={setCurrentStep}
    />
  );
};

export default ViewContact;
