import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { changeView } from 'redux/actions/view';
import { routes, Route } from 'routes/routes';
import { LocalizationNamespaces } from 'constants/localization';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterRoutes, getMainRoutes } from 'helpers/routesHelpers';
import { StateModel as UserAccessStateModel } from 'redux/reducers/userAccess';

// components
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';
import { Menu } from '@ui';

const NavigationMenu = () => {
  const { t } = useTranslation(LocalizationNamespaces);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );

  const activeRoutes = useMemo(() => {
    function isAllowed(route: Route): boolean {
      const { metadata, access } = route;

      const addToNavigation = metadata?.addToNavigation;
      const hasValidLayout =
        !access?.layout || access?.layout === 'ApprovedClientGroup';
      const hasValidPermission =
        !access?.permission || permissions[access.permission].isAllowed;

      return !!(addToNavigation && hasValidLayout && hasValidPermission);
    }

    return filterRoutes(getMainRoutes(routes), isAllowed);
  }, [permissions]);

  const generateMenuItems = (routes: Route[]): any => {
    return routes.map(({ path, title, children }) => {
      const label = (
        <MenuLink>
          {t(title.key, { ns: title.ns })}
          <RenderBadgeByRoutePath path={path} />
        </MenuLink>
      );
      return children?.length
        ? {
            key: path,
            label,
            children: generateMenuItems(children),
            className:
              location.pathname === path || location.pathname.includes(path)
                ? 'menu-submenu-selected'
                : '',
            onTitleClick: () => navigate(path),
          }
        : {
            key: path,
            label,
          };
    });
  };

  return (
    <StyledMenu
      mode="horizontal"
      selectedKeys={[location.pathname]}
      items={generateMenuItems(activeRoutes)}
      onClick={({ key }) => dispatch(changeView(() => navigate(key)))}
    />
  );
};

const StyledMenu = styled(Menu)`
  display: flex;
  align-items: center;

  &.ant-menu-horizontal {
    .ant-menu-submenu {
      margin: 0px;
      &:hover {
        color: ${({ theme }) => theme.colorPrimary} !important;
      }
      .ant-menu-title-content {
        &:hover {
          color: ${({ theme }) => theme.colorPrimary} !important;
        }
      }

      .ant-menu-item {
        .ant-menu-title-content {
          &:hover {
            color: ${({ theme }) => theme.colorPrimary} !important;
          }
        }
      }
    }

    .ant-menu-item-only-child {
      margin: 0;
      display: flex;

      .ant-menu-title-content {
        &:hover {
          color: ${({ theme }) => theme.colorPrimary} !important;
        }
      }
    }

    .menu-submenu-selected {
      .ant-menu-item-selected .ant-menu-title-content {
        color: ${({ theme }) => theme.colorPrimary} !important;
      }

      .ant-menu-title-content {
        color: ${({ theme }) => theme.colorPrimary} !important;
      }
    }
  }
`;

const MenuLink = styled.div<{ addPadding?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme, addPadding }) =>
    addPadding ? `0 ${theme.marginSm}` : ''};
`;

export default NavigationMenu;
