import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useTranslation from 'hooks/useTranslation';
import { Trans } from 'react-i18next';
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { profileAPI } from 'api/profile/profileAPI';
import { securityAPI } from 'api/profile/securityAPI';
import { colorsTheme } from 'resources/theme/styled/colors';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { Step, StepStatus } from 'components/Additional/StepsCard';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { Message, Modal, Text } from '@ui';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { initializeApp, setInitializedStatus } from 'redux/actions/app';
import { FormValuesModel as UserInfoFormValuesModel } from './SignUpFormContent/UserInfoStep';
import { FormValuesModel as AccountTypeFormValuesModel } from './SignUpFormContent/AccountTypeStep';
import { FormValuesModel as EmailVerificationFormValuesModel } from './SignUpFormContent/EmailVerificationStep';
import { FormValuesModel as PhoneVerificationFormValuesModel } from './SignUpFormContent/PhoneVerificationStep';
import {
  USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL,
  USER_TAG_IS_SKIPPED_VERIFICATION_SMS,
} from 'constants/userTags';
import {
  setSignUpFlowData,
  setIsEmailVerifiedStatus,
  setIsPhoneVerifiedStatus,
  setUserTags,
} from 'redux/actions/auth';
import {
  setAttemptsOfResendingEmailOTP,
  setAttemptsOfResendingSmsOTP,
  setAuthenticationTokens,
} from 'helpers/localStorageHelpers';

interface SignUpContextData {
  steps: Step[];
  stepsData: StepsData;
  activeStep: StepKeys | null;
  setActiveStep: (key: StepKeys) => void;
  submitAccountTypeStep: (formValues: AccountTypeFormValuesModel) => void;
  submitUserInfoStep: (formValues: UserInfoFormValuesModel) => void;
  submitEmailVerificationStep: (
    formValues: EmailVerificationFormValuesModel,
  ) => Promise<void>;
  skipEmailVerification: () => Promise<void>;
  submitPhoneVerificationStep: (
    formValues: PhoneVerificationFormValuesModel,
  ) => Promise<void>;
  skipPhoneVerification: () => Promise<void>;
}

type StepsData = {
  accountType: {
    title: string;
    status: StepStatus;
    disabled: boolean;
    data: AccountTypeFormValuesModel | null;
  };
  userInfo: {
    title: string;
    status: StepStatus;
    disabled: boolean;
    data: UserInfoFormValuesModel | null;
  };
  emailVerification: {
    title: string;
    status: StepStatus;
    disabled: boolean;
    data: { isVerified: boolean } | null;
  };
  phoneVerification: {
    title: string;
    status: StepStatus;
    disabled: boolean;
    data: { isVerified: boolean } | null;
  };
};

export type StepKeys = keyof StepsData;

// * Context
const SignUpContext = React.createContext<SignUpContextData>(
  {} as SignUpContextData,
);

// * Provider
export const SignUpContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation(['auth', 'common']);
  const dispatch = useDispatch();
  const [activeStepKey, setActiveStepKey] = useState<StepKeys | null>(null);
  const invitationCode = useSelector<StateModel, string | null>(
    (state) => state.auth.signUpFlow?.invitationCode || null,
  );
  const { isAuthorized, profileData, verificationStatuses, contactData } =
    useSelector<StateModel, AuthStateModel>((store) => store.auth);

  const [stepsData, setStepsData] = useState<StepsData>({
    accountType: {
      title: t('open_an_account_new.nav_options.account_type'),
      status: 'new',
      disabled: false,
      data: null,
    },

    userInfo: {
      title: t('open_an_account_new.nav_options.your_details'),
      status: 'new',
      disabled: true,
      data: null,
    },

    emailVerification: {
      title: t('open_an_account_new.nav_options.email'),
      status: 'new',
      disabled: true,
      data: null,
    },

    phoneVerification: {
      title: t('open_an_account_new.nav_options.phone'),
      status: 'new',
      disabled: true,
      data: null,
    },
  });

  const steps = useMemo<Step[]>(() => {
    return [
      {
        key: 'accountType',
        ...stepsData.accountType,
      },

      {
        key: 'userInfo',
        ...stepsData.userInfo,
      },

      {
        key: 'verification',
        title: t('open_an_account_new.nav_options.verification'),
        status: 'new',
        data: null,
        disabled: stepsData.userInfo.status === 'completed' ? false : true,
        children: [
          {
            key: 'emailVerification',
            ...stepsData.emailVerification,
          },

          {
            key: 'phoneVerification',
            ...stepsData.phoneVerification,
          },
        ],
      },
    ];
  }, [stepsData, activeStepKey]);

  // Init initial state
  useEffect(() => {
    if (isAuthorized) {
      const isEmailVerifiedOrSkipped = !!(
        verificationStatuses.isEmailVerified ||
        profileData?.tags.includes(USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL)
      );
      const isPhoneNumberVerifiedOrSkipped = !!(
        verificationStatuses.isPhoneVerified ||
        profileData?.tags.includes(USER_TAG_IS_SKIPPED_VERIFICATION_SMS)
      );

      setStepsData((prev) => ({
        accountType: {
          ...prev.accountType,
          status: 'completed',
          disabled: true,
        },

        userInfo: {
          ...prev.userInfo,
          status: 'completed',
          disabled: true,
        },

        emailVerification: {
          ...prev.emailVerification,
          status: isEmailVerifiedOrSkipped ? 'completed' : 'new',
          disabled: isEmailVerifiedOrSkipped,
        },

        phoneVerification: {
          ...prev.phoneVerification,
          status: isPhoneNumberVerifiedOrSkipped ? 'completed' : 'new',
          disabled: !isEmailVerifiedOrSkipped,
        },
      }));

      setActiveStepKey(
        isEmailVerifiedOrSkipped ? 'phoneVerification' : 'emailVerification',
      );
    } else {
      setStepsData((prev) => ({
        accountType: {
          ...prev.accountType,
          disabled: false,
          status: 'new',
          data: null,
        },

        userInfo: {
          ...prev.userInfo,
          disabled: true,
          status: 'new',
          data: null,
        },

        emailVerification: {
          ...prev.emailVerification,
          status: 'new',
          disabled: true,
        },

        phoneVerification: {
          ...prev.phoneVerification,
          status: 'new',
          disabled: true,
        },
      }));
      setActiveStepKey('accountType');
    }
  }, [isAuthorized, verificationStatuses]);

  /**
   * ------- Account type step -----------
   */
  const submitAccountTypeStep = useCallback(
    (formValues: AccountTypeFormValuesModel) => {
      setStepsData((prev) => ({
        accountType: {
          ...prev.accountType,
          status: 'completed',
          data: formValues,
        },

        userInfo: {
          ...prev.userInfo,
          disabled: false,
        },

        emailVerification: {
          ...prev.emailVerification,
        },

        phoneVerification: {
          ...prev.phoneVerification,
        },
      }));
      setActiveStepKey('userInfo');
    },
    [stepsData],
  );

  /**
   * ------- User info step -----------
   */
  const submitUserInfoStep = useCallback(
    async (formValues: UserInfoFormValuesModel) => {
      const response = await onboardingAPI.signUpUser({
        firstName: formValues.firstName.trim(),
        lastName: formValues.lastName.trim(),
        email: formValues.email.trim(),
        phone: formValues.phone,
        isPEP: formValues.isPEP as boolean,
        password: formValues.password,
        invitationCode: invitationCode as string,
        type: stepsData.accountType.data?.accountType as OnboardingEntryTypes,
        pepInformation: formValues.isPEP
          ? formValues.pepInformation
          : undefined,
      });

      if (response && response.token) {
        Modal.confirm({
          title: t('open_an_account_new.basic_info.username_modal.title'),
          width: 600,
          icon: null,
          closable: false,
          maskClosable: false,
          cancelButtonProps: {
            hidden: true,
          },
          content: (
            <>
              <Text gutterBottom>
                <Trans
                  t={t}
                  i18nKey="open_an_account_new.basic_info.username_modal.username_line"
                  values={{
                    username: response.username,
                  }}
                  components={[
                    <Text
                      variant="span"
                      weight="semi-bold"
                      color={colorsTheme.colorWhite}
                    />,
                  ]}
                />
              </Text>
              <Text gutterBottom>
                {t(
                  'open_an_account_new.basic_info.username_modal.description_line_1',
                )}
              </Text>
              <Text>
                {t(
                  'open_an_account_new.basic_info.username_modal.description_line_2',
                )}
              </Text>
            </>
          ),
          okText: t('continue', { ns: 'common' }),
          async onOk() {
            dispatch(setInitializedStatus(false));
            setAuthenticationTokens(response.token, response.refreshToken);
            dispatch(setSignUpFlowData(null));
            dispatch(initializeApp());
          },
        });
      }
    },
    [stepsData, invitationCode],
  );

  /**
   * ------- Email verification -----------
   */
  const submitEmailVerificationStep = useCallback(
    async (formValues: EmailVerificationFormValuesModel) => {
      const data = { email: profileData?.email as string, otp: formValues.otp };
      await securityAPI.verifyEmail(data);
      Message.success(
        t('email_verification.success_submit_message', { ns: 'auth' }),
      );
      dispatch(setIsEmailVerifiedStatus(true));
    },
    [profileData?.email],
  );

  const skipEmailVerification = useCallback(async () => {
    await userAPI.setUserTags([USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL]);
    dispatch(
      setUserTags([
        ...(profileData?.tags as string[]),
        USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL,
      ]),
    );
    setAttemptsOfResendingEmailOTP(0);
    setStepsData((prev) => ({
      ...prev,
      emailVerification: {
        ...prev.emailVerification,
        status: 'completed',
        disabled: true,
      },
      phoneVerification: {
        ...prev.phoneVerification,
        disabled: false,
      },
    }));
    setActiveStepKey('phoneVerification');
  }, [profileData]);

  /**
   * ------- Phone verification -----------
   */
  const submitPhoneVerificationStep = useCallback(
    async (formValues: PhoneVerificationFormValuesModel) => {
      if (!contactData?.phoneNumber?.number) {
        return;
      }
      await profileAPI.verifyPhoneNumber(
        formValues.otp,
        contactData.phoneNumber.number as string,
      );
      dispatch(setIsPhoneVerifiedStatus(true));
      Message.success(
        t('phone_verification.success_submit_message', { ns: 'auth' }),
      );
    },
    [contactData?.phoneNumber],
  );

  const skipPhoneVerification = async () => {
    await userAPI.setUserTags([USER_TAG_IS_SKIPPED_VERIFICATION_SMS]);
    dispatch(
      setUserTags([
        ...(profileData?.tags as string[]),
        USER_TAG_IS_SKIPPED_VERIFICATION_SMS,
      ]),
    );
    setAttemptsOfResendingSmsOTP(0);
  };

  const handleStepSelect = useCallback(
    (key: string) => {
      if (key === 'verification') {
        if (
          activeStepKey &&
          !['emailVerification', 'phoneVerification'].includes(activeStepKey)
        ) {
          setActiveStepKey('emailVerification');
        }
      } else {
        setActiveStepKey(key as StepKeys);
      }
    },
    [activeStepKey],
  );

  return (
    <SignUpContext.Provider
      value={{
        steps,
        stepsData,
        activeStep: activeStepKey,
        setActiveStep: handleStepSelect,
        submitAccountTypeStep,
        submitUserInfoStep,
        submitEmailVerificationStep,
        skipEmailVerification,
        submitPhoneVerificationStep,
        skipPhoneVerification,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

// * Hook
export function useSignUpContext(): SignUpContextData {
  const context = useContext(SignUpContext);
  return context;
}
