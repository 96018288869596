import useTranslation from './useTranslation';
import { userAPI } from 'api/profile/userAPI';
import { signOut } from 'redux/actions/auth';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import {
  checkAppVersionAndRefreshIfNeeded,
  decodeJWTToken,
} from 'helpers/commonHelpers';
import {
  getAccessToken,
  getRefreshToken,
  getTimeOffset,
  setAuthenticationTokens,
  setTimeOffset,
} from 'helpers/localStorageHelpers';

const REFRESH_TOKEN_BY_MS = 1000 * 60;

const useTokenRefresh = (isAppInitialized: boolean, isAuthorized: boolean) => {
  const dispatch = useDispatch();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation('common');

  const getDifference = (token: string) => {
    const timeOffset = getTimeOffset();
    const numberTimeOffset = timeOffset ? Number(timeOffset) : 0;
    const adjustedCurrentTime = Date.now() + numberTimeOffset;
    const decodedAccessToken = decodeJWTToken(token);
    return decodedAccessToken
      ? decodedAccessToken.exp * 1000 - adjustedCurrentTime
      : 0;
  };

  const refreshAccessToken = async () => {
    try {
      const { jwt, refreshToken, swaggerVersion, serverTime } =
        await userAPI.refreshTokens(getAccessToken(), getRefreshToken());
      setAuthenticationTokens(jwt, refreshToken);
      checkAppVersionAndRefreshIfNeeded(
        swaggerVersion,
        t('new_version_page_refresh'),
      );
      setTimeOffset(serverTime);
      return jwt;
    } catch {
      dispatch(signOut(true));
    }
  };

  const refreshAccessTokenIfNeeded = async () => {
    const accessToken = getAccessToken();
    const difference = getDifference(accessToken);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (difference < REFRESH_TOKEN_BY_MS) {
      const newToken = await refreshAccessToken();
      if (newToken) {
        const differenceNewToken = getDifference(newToken);
        timerRef.current = setTimeout(
          refreshAccessTokenIfNeeded,
          differenceNewToken - REFRESH_TOKEN_BY_MS,
        );
      }
    } else {
      timerRef.current = setTimeout(
        refreshAccessTokenIfNeeded,
        difference - REFRESH_TOKEN_BY_MS,
      );
    }
  };

  useEffect(() => {
    if (isAppInitialized && isAuthorized) {
      refreshAccessTokenIfNeeded();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isAppInitialized, isAuthorized, timerRef]);
};

export default useTokenRefresh;
