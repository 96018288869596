import React, { useEffect, useMemo } from 'react';

// helpers
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import ApprovalRulesTab from 'modules/AccountManagement/TransactionRules/ApprovalRulesTab';
import ApprovalGroupsTab from 'modules/AccountManagement/TransactionRules/ApprovalGroupsTab';
import { Text, Tabs, TabModel } from '@ui';

const TransactionRules = () => {
  const { t } = useTranslation('account_management');
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      content: <Text>{t('transaction_rules.description')}</Text>,
    }));

    return () => clearContext();
  }, [setPageLayoutOptions, clearContext]);

  const [hasApprovalGroupsViewAccess, hasApprovalRulesViewAccess] =
    useUserAccess([
      'AccountManagement_TransactionRules_ApprovalGroups_View',
      'AccountManagement_TransactionRules_ApprovalRules_View',
    ]);

  const tabs = useMemo<TabModel[]>(
    () => [
      {
        key: 'approval-groups',
        label: t('approval_groups.title'),
        children: <ApprovalGroupsTab />,
        hidden: !hasApprovalGroupsViewAccess,
      },
      {
        key: 'approval-rules',
        label: t('approval_rules.title'),
        children: <ApprovalRulesTab />,
        hidden: !hasApprovalRulesViewAccess,
      },
    ],
    [hasApprovalGroupsViewAccess, hasApprovalRulesViewAccess],
  );

  return <Tabs items={tabs} />;
};

export default TransactionRules;
