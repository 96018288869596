import React from 'react';
import useTranslation from './useTranslation';
import { StateModel } from '../redux/reducers';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { LocalizationNamespaces } from '../constants/localization';
import { Route, RoutePaths, routes } from 'routes/routes';
import { filterRoutes, getMainRoutes } from 'helpers/routesHelpers';
import { StateModel as SettingsStateModel } from '../redux/reducers/settings';
import { StateModel as UserAccessStateModel } from '../redux/reducers/userAccess';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';

export type MenuItemModel = {
  title: React.ReactNode;
  path: RoutePaths;
  subItems: MenuItemModel[];
  disabled?: boolean;
};

// TODO: refactor
const useSidebarMenu = (skipGeneration?: boolean): MenuItemModel[] => {
  const location = useLocation();
  const { t } = useTranslation(LocalizationNamespaces);
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );
  const [menuItems, setMenuItems] = useState<MenuItemModel[]>([]);

  useEffect(() => {
    if (skipGeneration) {
      menuItems.length && setMenuItems([]);
    } else {
      if (
        layoutVariant &&
        ['OnboardingClientGroup', 'NoClientGroup', 'IntakeForm'].includes(
          layoutVariant,
        )
      ) {
        const newMenuItems = getProfileMenuItems();
        setMenuItems(newMenuItems);
      } else {
        const newMenuItems = getNewMenuItems(location.pathname);
        setMenuItems(newMenuItems);
      }
    }
  }, [location.pathname, layoutVariant, skipGeneration]);

  const getProfileMenuItems = () => {
    function isAllowed(route: Route): boolean {
      const { metadata, access } = route;

      const addToNavigation =
        metadata?.addToProfileMenu ||
        (layoutVariant &&
          ['OnboardingClientGroup', 'NoClientGroup', 'IntakeForm'].includes(
            layoutVariant,
          ) &&
          metadata?.addToOnboardingProfileMenu);
      const hasValidLayout =
        !access?.layout || access?.layout === layoutVariant;
      const hasValidPermission =
        !access?.permission || permissions[access.permission].isAllowed;

      return !!(addToNavigation && hasValidLayout && hasValidPermission);
    }

    let filteredRoutes = filterRoutes(getMainRoutes(routes), isAllowed);

    filteredRoutes = filteredRoutes.reduce<Route[]>((acc, route) => {
      if (route.children) {
        acc.push(...route.children);
      } else {
        acc.push(route);
      }

      return acc;
    }, []);

    return generateNewMenuItems(filteredRoutes);
  };

  function isAllowed(route: Route): boolean {
    const { metadata, access } = route;

    const addToNavigation =
      metadata?.addToNavigation || metadata?.addToProfileMenu;
    const hasValidLayout = !access?.layout || access?.layout === layoutVariant;
    const hasValidPermission =
      !access?.permission || permissions[access.permission].isAllowed;

    return !!(addToNavigation && hasValidLayout && hasValidPermission);
  }

  const getNewMenuItems = (path: string): MenuItemModel[] => {
    const splitPath = path
      .split('/')
      .filter((e) => e)
      .map((e) => `/${e}`);

    if (!splitPath.length || splitPath[0] === '/') {
      return [];
    }

    const mainRoutes = getMainRoutes(routes);
    const filteredRoutes = filterRoutes(mainRoutes, isAllowed);
    const selectedRoute = filteredRoutes.find((e) => e.path === splitPath[0]);

    if (!selectedRoute?.children?.length) {
      return [];
    }

    return generateNewMenuItems(selectedRoute.children || []);
  };

  const generateNewMenuItems = (routes: Route[]): MenuItemModel[] => {
    return (
      routes.map((e) => {
        return {
          title: (
            <DivAlignCenter>
              {t(e.title.key, { ns: e.title.ns })}
              {e.metadata?.appendToSidebarTitle}
            </DivAlignCenter>
          ),
          path: e.path,
          subItems: e.children?.length ? generateSubMenuItems(e.children) : [],
        };
      }) || []
    );
  };

  const generateSubMenuItems = (routes: Route[]): MenuItemModel[] => {
    return routes.map((e) => ({
      title: (
        <DivAlignCenter>
          {t(e.title.key, { ns: e.title.ns })}
          {e.metadata?.appendToSidebarTitle}
        </DivAlignCenter>
      ),
      path: e.path,
      subItems: e.children?.length ? generateSubMenuItems(e.children) : [],
    }));
  };

  return menuItems;
};

export default useSidebarMenu;
