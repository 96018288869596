import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { AccountTypes } from 'enums/onboarding/crm';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';

// components
import Services from './Tabs/Services';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import AccountInformationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/AccountInformationForm';
import { Message, Tabs, TabModel } from '@ui';

interface IProps {
  onAction: (key: ActionKeys | null) => void;
  onboardingData: OnboardingStatusModel;
  isViewOnly: boolean;
}

const AccountInformation = ({
  onAction,
  onboardingData,
  isViewOnly,
}: IProps) => {
  const { t } = useTranslation(['common', 'onboarding']);

  const [hasExternalServicesViewAccess] = useUserAccess([
    'AccountManagement_ExternalServices_View',
  ]);

  const { response, loading } = useFetch(
    () =>
      onboardingData.applicationId
        ? onboardingAPI.fetchAccountInformationStepData(
            onboardingData.applicationId,
          )
        : null,
    [onboardingData],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response) {
      return null;
    }

    const accounts = response.length
      ? response
      : [
          {
            type: AccountTypes.current,
            purpose: '',
            currencyId: null,
            initialDeposit: null,
            initialDepositOrigin: null,
            jurisdictions: [],
            incomingWiresNumber: null,
            estimatedIncomingFunds: null,
            outgoingWiresNumber: null,
            estimatedOutgoingFunds: null,
          },
        ];

    return {
      isSave: false,
      submitActionType: null,
      accounts,
    };
  }, [response]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitAccountInformationStep(values);
    await onboardingAPI.submitAccountInformationStep(
      formattedBody,
      onboardingData.applicationId,
    );
    Message.success(t('success_save'));

    await onAction(values.submitActionType);
  };

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        key: 'bank-accounts',
        label: t('account_information.tabs.bank_accounts', {
          ns: 'onboarding',
        }),
        children: initialFormValues && (
          <AccountInformationForm
            initialFormValues={initialFormValues}
            onSubmit={onSubmit}
            disabled={isViewOnly}
          />
        ),
      },
      {
        key: 'external-services',
        hidden: !hasExternalServicesViewAccess,
        label: t('account_information.tabs.external_services', {
          ns: 'onboarding',
        }),
        children: <Services disabled={isViewOnly} />,
      },
    ];
  }, [initialFormValues, onSubmit, isViewOnly, hasExternalServicesViewAccess]);

  return (
    <LoadingWrapper loading={loading}>
      <Tabs items={tabs} />
    </LoadingWrapper>
  );
};

export default AccountInformation;
