import React from 'react';

// helpers
import { countriesAPI } from 'api/crm/countriesAPI';
import { AutocompleteProps } from '@ui';

// components
import { FormAutocomplete } from '@ui';

export type IProps = AutocompleteProps;

const CountriesAutocomplete = (props: IProps) => {
  const fetchOptions = async (
    searchText: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } = await countriesAPI.fetchCountries({
      search: searchText.trim(),
      page,
      limit,
    });

    return {
      total,
      options: data.map((e) => ({
        id: e.alpha2Code,
        label: e.name,
        model: e,
      })),
    };
  };

  return <FormAutocomplete {...props} fetchData={fetchOptions} />;
};

export default CountriesAutocomplete;
