import { ActionType } from 'redux/actions/intakeForm';
import { IReduxAction } from 'typings/common';
import { ClientIntakeForm } from 'typings/profile/profile';

export interface StateModel {
  intakeForm: ClientIntakeForm | null;
}

const initialState: StateModel = {
  intakeForm: null,
};

const intakeForm = (state = initialState, action: IReduxAction) => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SET_INTAKE_FORM:
      return {
        ...state,
        intakeForm: payload,
      };

    default:
      return state;
  }
};

export default intakeForm;
