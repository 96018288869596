import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { MessageModel } from 'typings/messaging/messages';

// components
import MessageBody from './MessageBody';
import MessageAuthor from './MessageAuthor';
import MessageTimeBar from './MessageTimeBar';
import { Button } from '@ui';

interface IProps {
  isUnread: boolean;
  message: MessageModel;
}

const Comment = ({ isUnread, message }: IProps) => {
  const { t } = useTranslation('profile');
  const [isActive, setActive] = useState(false);

  return (
    <CommentWrapper isHighlighted={isUnread || isActive}>
      <Header isActive={isActive}>
        <MessageAuthor
          isUnread={isUnread}
          isActive={isActive}
          message={message}
        />
        <MessageTimeBar message={message} />
      </Header>
      <MessageBody message={message} isActive={isActive} />
      <ActionButton
        type="text"
        key="show_hide_actions"
        onClick={() => setActive((prev) => !prev)}
      >
        {isActive
          ? t('messages.view_message.hide_conversation')
          : t('messages.view_message.show_conversation')}
      </ActionButton>
    </CommentWrapper>
  );
};

const Header = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.marginXXs};
`;

const ActionButton = styled(Button)`
  padding: 0;
  text-transform: unset;
  font-weight: 300;

  span {
    font-size: 12px;
    margin-top: ${({ theme }) => theme.marginXXs};
    color: ${({ theme }) => theme.colorAdditionalL1};
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.colorPrimary};
    }
  }
`;

const CommentWrapper = styled.div<{ isHighlighted?: boolean }>`
  padding: 8px 8px;
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colorDarkL2 : 'transparent'};
`;

export default Comment;
