import React from 'react';

// helpers
import { useSignUpContext } from '../../SignUpContext';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { accountTypeValidationSchema } from 'validations/intake-form/sign-up';

// components
import InnerForm from './InnerForm';
import { Form } from '@ui';

export interface FormValuesModel {
  accountType: OnboardingEntryTypes | null;
}

const AccountTypeStep = () => {
  const { stepsData, submitAccountTypeStep } = useSignUpContext();

  return (
    <Form
      onSubmit={submitAccountTypeStep}
      renderForm={<InnerForm />}
      initialValues={stepsData.accountType.data}
      validationSchema={accountTypeValidationSchema}
    />
  );
};

export default AccountTypeStep;
