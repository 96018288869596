import React from 'react';

// helpers
import { styled } from 'styled-components';

// components
import EntityIntakeForm from 'modules/IntakeForm/EntityIntakeForm';

const IntakeFormPage = () => {
  return (
    <ContentWrapper>
      <EntityIntakeForm />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  padding-top: ${({ theme }) => `calc(${theme.headerHeight} + 64px)`};
  width: 75%;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.hdScreen}) {
    width: 80%;
    max-width: 80%;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    width: 100%;
    max-width: 100%;
  }
`;

export default IntakeFormPage;
