import React, { memo, useMemo } from 'react';

// helpers
import { StepStatus } from '..';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import { IconSVG, IconSVGProps } from '@ui';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/checkbox-circle-fill.svg';
import { ReactComponent as CircleIcon } from 'resources/icons/remix-icons/circle-line.svg';

interface IProps {
  status: StepStatus;
}

const StatusIcon = memo(({ status }: IProps) => {
  const iconProps = useMemo<IconSVGProps>(() => {
    switch (status) {
      case 'new':
        return { component: CircleIcon };

      case 'completed':
        return { component: CheckIcon, color: colorsTheme.colorPrimary };
    }
  }, [status]);

  return <IconSVG {...iconProps} />;
});

export default StatusIcon;
