import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { setSignUpFlowData } from 'redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';

// components
import { Text, Link, Layout } from '@ui';
import FooterBar from 'components/Layout/FooterBar';
import BackgroundImage from 'resources/images/background-images/building-3.jpg';
import HeaderBarWithLogo from 'components/Layout/HeaderBarWithLogo';
import { Trans } from 'react-i18next';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

const SignUpLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('auth');
  const { isAuthorized, signUpFlow } = useSelector<StateModel, AuthStateModel>(
    (store) => store.auth,
  );

  const navigateToSignin = () => {
    dispatch(setSignUpFlowData(null));
    navigate(RoutePaths.Auth_Root);
  };

  if (isAuthorized || !signUpFlow) {
    return <Navigate to={RoutePaths.Root} />;
  }

  return (
    <Root>
      <HeaderBarWithLogo />
      <Outlet />
      <StyledWrapper>
        <Text variant="body1">
          <Trans
            t={t}
            i18nKey="open_an_account.go_to_signin_link"
            components={[
              <Link key="sign-in-link" onClick={navigateToSignin} />,
            ]}
          />
        </Text>
      </StyledWrapper>
      <FooterWrapper>
        <FooterBar transparent />
      </FooterWrapper>
    </Root>
  );
};

const Root = styled(Layout)`
  max-height: 100vh;
  min-height: 100vh;
  overflow: auto;
  position: relative;

  background: url(${BackgroundImage}) rgba(28, 32, 37, 0.65);
  background-size: cover;
  background-position: center;

  background-blend-mode: multiply;
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const StyledWrapper = styled.div`
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colorWhite};
  padding: ${({ theme }) => `${theme.paddingMd} 0`};
`;

export default SignUpLayout;
