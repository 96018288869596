import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import Identification from './Tabs/Identification';
import GeneralInformation from './Tabs/GeneralInformation';
import { IconSVG, TabModel, Tabs } from '@ui';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

type TabKeys = 'generalInformation' | 'identification';

interface IProps {
  organization: IEntity;
}

const OrganizationWithoutKeyRelationship = ({ organization }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>('generalInformation');

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        key: 'generalInformation',
        label: (
          <>
            {t('applicant_information.general.title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        children: <GeneralInformation organization={organization} />,
      },

      {
        key: 'identification',
        label: (
          <>
            {t('identification.title')}{' '}
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          </>
        ),
        children: <Identification organization={organization} />,
      },
    ];
  }, [organization]);

  return (
    <Tabs
      useChangeView
      items={tabs}
      activeKey={currentStep}
      defaultActiveKey={currentStep}
      onChange={setCurrentStep}
    />
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default OrganizationWithoutKeyRelationship;
