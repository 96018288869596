import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { reactPlugin } from 'services/azure-app-insights/service';

const useAppTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (reactPlugin) {
      // Assign the location object to track route changes
      reactPlugin.trackPageView({
        name: location.pathname, // Use pathname for the page name
        uri: location.pathname, // URI of the current page
      });
    }
  }, [location.pathname]);
};

export default useAppTracking;
